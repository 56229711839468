.c-showcase{
   margin: 40px 0;
}
.c-showcase-title{
   padding-top: 50px;
   margin-bottom: 24px;
   display: flex;
   justify-content: space-between;
}
.h1s{
   color: black;
   font-size: 55px;
   font-style: 'Montserrat'; 
   padding-left: 20px;
   width:100%;
}

.project{
   display: flex;
   align-items: center;
   justify-content:right;
   text-align: right;
   width: 300px;
}

.subtitle{
   text-align: right;
   color:#101BF5;
}

.h1s, .subtitle{
    display: inline-block;
    width: 100%;
}
@media screen and (max-width: 575px) {
   .c-showcase{
      display: flex;
      flex-direction: column;
      margin: 20px 0;
   }
   
   .c-showcase-title{
      flex-direction: column;
      padding: 0;
      margin: 0;

   }
   .h1s{
      font-size: 40px;
      margin-top: 10px;
      margin-bottom: 5px;
      padding-left: initial;
   }
   .subtitle{
      font-size: 20px;
      text-align: left;
      width: initial;
   }
   .project{
      justify-content: start;
      width: auto;
   }

}


.container{
   
    width: 100%;
    display: inline-block;
  
}
.h1c{
   
    width:100%;
    /* padding-top: 20px; */
    font-size: 40px;
    font-style: 'Montserrat'; 
    font-weight: 700;
    text-align: left;
   
}
.imgslider{
    width: 815px;    
   
 
}
.theme{
    width: 300px;
    

}   
.work{
    color: #101BF5;
}

.twi .h1{
    display: inline-block;
    width: 100%;
}

.c-brand{
    margin: 80px 0;
}

.c-slider{
    margin: auto 0;
}

.c-slider img{
    width: 120px;
    margin: auto;
}

@media screen and (max-width: 767px) {
    .c-brand{
        margin: 40px 0;
    }
    .h1c{
        text-align: center;
    }
    .c-slider{
        margin: 0 0 30px;
    }
}

.brands_slider ._item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
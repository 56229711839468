body{
    background: radial-gradient(#ffff, #ffff, #ffff);
}

.container-fluid{
    width: 1440px;
}

.text-center{
    width: 20rem;
    /* margin: 4rem; */
}

.card{
    width: 18rem;
    
}
.blue{
    color: #101BF5;
}
.c-cards{
    padding: 0 80px;
}
.header-title{
    width:100%;
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    margin: 90px 0 60px;
}
.card:hover{
    box-shadow:5px 10px 20px 1px rgba(0,0,0,0.253)!important;
}
.card-body{
    padding: 2rem 0!important;
}

.card-text{
    font-size: 0.9rem;  
}
.container-fluid .row{
    padding-top: 3rem;
}

.card-title{
    color: #101BF5;
    font-style: 'Montserrat'; 
    font-weight: bold;
}
.c-services{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: space-between;
}
.c-card{
    display: flex;
    justify-content: center;
    max-width: calc(100% / 3);
    padding: 0 20px;
}
.overflow{
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.services-img{
    max-width: 80%;
}
.c-software-specialize{
    display: inline-flex;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 80px;
    
}

.header-title-specialize{
    width:100%;
    font-size: 40px;
    text-align: left;
    font-weight: bold;
    margin-right: 130px;
}
.c-specialize{
    display: inline-flex;
    flex-wrap: wrap;
    
}
.specialize{
    /* max-width: 400px; */
    max-width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    border-radius: 15px;
    border: 2px solid #d0d2d3;
    margin: 20px;
    padding: 20px;
}
@media screen and (min-width: 1440px) {
    .c-specialize {
        justify-content: space-evenly;
    }
    .specialize{
        max-width: calc(50% - 80px);
        margin: 40px;
        margin-bottom: 40px;
    }
}
.c-specialize-image{
    padding: 10px;
}
.c-specialize-image img{
    max-width: 70px;
    max-height: 70px;
}
.c-specialize-detail{
    padding: 10px;
}
.c-specialize-title *{
    font-size: 24px;
    color:#101BF5;
}
.c-specialize-description{
    line-height: 18px;
}
@media screen and (max-width: 1200px){
    .c-cards{
        padding: 0;
    }
    .c-software-specialize{
        align-items: flex-start;
    }
    .header-title-specialize{
        margin-top: 40px;
    }
    .c-specialize{
        flex-direction: column;
    }
}
@media screen and (max-width: 767px) {
    .header-title{
        font-size: 40px;
        margin: 30px 0 10px;
    }
    .c-services{
        flex-direction: column;
        align-items: center;
    }
    .overflow{
        height: 100px;
    }
    .services-img{
        max-width: 60%;
    }
    .c-card{
        max-width: 100%;
    }
    .c-software-specialize{
        flex-direction: column;
        align-items: center;
    }
    .header-title-specialize{
        text-align: center;
        font-size: 34px;
    }
    .c-specialize-image img{
        max-width: 45px;
    }
    .specialize{
        margin: 10px 0;
        padding: 10px;
        font-size: 14px;
        align-items: center;
        display: flex;
    }
    .c-specialize-title *{
        font-size: 20px;
        margin: 0;
    }
}


@import url(https://fonts.googleapis.com/css?family=Raleway:900&display=swap);
.c-nav{
    font-size: 14px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}

.c-contact-button{
    border-radius: 10px !important;
    font-size: 14px !important;
}
.c-home{
  background-image: url(/static/media/Mockup.7c197794.png);
  height: 100vh;
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: center bottom;
  margin-bottom: 30px !important;
}

.textwrapper *{
  /* position: absolute; */
  /* top: 50%; */
  /* left: 18%; */
  /* transform: translate(-20%, -50%); */
  font-size: 40px;
  line-height: 40px;
  max-width: 100%;
}

@media only screen and (min-width: 576px) {
  .c-home{
    height: 100vh;
    background-size: 70%;
    background-position: calc(100% + 100px);
    margin-bottom:50px !important;
  }
  .textwrapper *{
    font-size: 70px;
    line-height: 70px;
    max-width: 62vw;
  }
}

.title-top{
  color: #101BF5;
}
.title-bottom{
  color: black;
}
@media only screen and (min-width:768px){
  .textwrapper *{
    font-size: 80px;
    line-height: 80px;
  }
  .c-home{
    background-size: 61%;
  }
}

@media only screen and (min-width:992px){
  .textwrapper *{
    font-size: 90px;
    line-height: 90px;
  }
  .c-home{
    background-size: 62%;
  }
}
@media only screen and (min-width: 1200px) {
  .textwrapper *{
    font-size: 110px;
    line-height: 110px;
  }
  .c-home{
    background-size: 55%;
  }
}

body{
    background: radial-gradient(#ffff, #ffff, #ffff);
}

.container-fluid{
    width: 1440px;
}

.text-center{
    width: 20rem;
    /* margin: 4rem; */
}

.card{
    width: 18rem;
    
}
.blue{
    color: #101BF5;
}
.c-cards{
    padding: 0 80px;
}
.header-title{
    width:100%;
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    margin: 90px 0 60px;
}
.card:hover{
    box-shadow:5px 10px 20px 1px rgba(0,0,0,0.253)!important;
}
.card-body{
    padding: 2rem 0!important;
}

.card-text{
    font-size: 0.9rem;  
}
.container-fluid .row{
    padding-top: 3rem;
}

.card-title{
    color: #101BF5;
    font-style: 'Montserrat'; 
    font-weight: bold;
}
.c-services{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.c-card{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: calc(100% / 3);
    padding: 0 20px;
}
.overflow{
    height: 150px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}
.services-img{
    max-width: 80%;
}
.c-software-specialize{
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 80px;
    margin-bottom: 80px;
    
}

.header-title-specialize{
    width:100%;
    font-size: 40px;
    text-align: left;
    font-weight: bold;
    margin-right: 130px;
}
.c-specialize{
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    
}
.specialize{
    /* max-width: 400px; */
    max-width: 100%;
    box-sizing: border-box;
    display: -webkit-inline-flex;
    display: inline-flex;
    border-radius: 15px;
    border: 2px solid #d0d2d3;
    margin: 20px;
    padding: 20px;
}
@media screen and (min-width: 1440px) {
    .c-specialize {
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
    }
    .specialize{
        max-width: calc(50% - 80px);
        margin: 40px;
        margin-bottom: 40px;
    }
}
.c-specialize-image{
    padding: 10px;
}
.c-specialize-image img{
    max-width: 70px;
    max-height: 70px;
}
.c-specialize-detail{
    padding: 10px;
}
.c-specialize-title *{
    font-size: 24px;
    color:#101BF5;
}
.c-specialize-description{
    line-height: 18px;
}
@media screen and (max-width: 1200px){
    .c-cards{
        padding: 0;
    }
    .c-software-specialize{
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    .header-title-specialize{
        margin-top: 40px;
    }
    .c-specialize{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
@media screen and (max-width: 767px) {
    .header-title{
        font-size: 40px;
        margin: 30px 0 10px;
    }
    .c-services{
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .overflow{
        height: 100px;
    }
    .services-img{
        max-width: 60%;
    }
    .c-card{
        max-width: 100%;
    }
    .c-software-specialize{
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .header-title-specialize{
        text-align: center;
        font-size: 34px;
    }
    .c-specialize-image img{
        max-width: 45px;
    }
    .specialize{
        margin: 10px 0;
        padding: 10px;
        font-size: 14px;
        -webkit-align-items: center;
                align-items: center;
        display: -webkit-flex;
        display: flex;
    }
    .c-specialize-title *{
        font-size: 20px;
        margin: 0;
    }
}


.startblue{
    color: #101BF5;
}
.pheading{
    color: black;
    font-size: 48px;
    margin-bottom: 24px;
    text-align:left;
    -webkit-align-items:left;
            align-items:left;
    font-style: 'Montserrat'; 
    width:100%;
    font-weight: bold;
}
.pheading2{
    color: #939393;
    font-size: 20px;
    text-align:left;
    -webkit-align-items:left;
            align-items:left;
    font-style: 'Montserrat'; 
    font-family: 'Montserrat-Italic';
    width:100%;
}
.itext{
    padding-top: 10px;
    margin-top: 20px !important;
    margin-bottom: 16px;
    font-size: 36px !important;
    font-style: 'Montserrat';
    text-align: left;
}
.project{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:right;
            justify-content:right;
    text-align: right;
    width: 300px;
 }
.sub{
    text-align: right;
    color:#101BF5;
 }
.itext, .sub {
     display: inline-block;
     width: 100%;
     
 }

 @media screen and (max-width: 575px) {
    .sub{
        font-size: 20px;
        text-align: left;
        width: auto;
        width: initial;
     }
     .project{
        -webkit-justify-content: start;
                justify-content: start;
        width: auto;
     }
}


.c-footer{
    background: #101BF5;
}
.footer-top, .footer-bottom{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color: #fff;
}
.footer-top{
    padding-top:50px;
    padding-bottom:25px;
}
.footer-bottom{
    padding-top: 25px;
    padding-bottom: 30px;
}
.footer-bottom *{
    font-size: 14px !important;
}

.c-social p{
    font-size: 14px !important;
}
.c-contact{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 30vw;
}
.c-contact h2{
    font-size: 16px !important;
    margin-bottom: 5px !important;
}
.c-contact a{
    font-size: 14px !important;
    margin-bottom: 0 !important;
}
.c-policy{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;    
    width: 100%;
    max-width: 30vw;
}

.policy{
    margin-left: 50px;
}

@media screen and (max-width: 575px) {
    .footer-top{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .footer-bottom{
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }
    .c-social{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .c-contact{
        margin: 50px 0 0;
        max-width: none;
        max-width: initial;
    }
    .c-policy{
        -webkit-flex-direction: column;
                flex-direction: column;    
        width: 100%;
        max-width: none;
        max-width: initial;
        margin-bottom: 30px;
    }    
    .policy{
        margin-left: 0;
        margin-left: initial;
    }
}

@media screen and (max-width: 767px) {
    
}

@media screen and (max-width: 991px) {
    
}

@media screen and (max-width: 1999px) {
    
}


/* Explanation in JS tab */

/* Cool font from Google Fonts! */

body {
    margin: 0px;

}

#container {
    /* Center the text in the viewport. */
    position: absolute;
    margin: auto;
    width: 100%;
    height: 80pt;
    ;
    top: 0;
    bottom: 0;

    /* This filter is a lot of the magic, try commenting it out to see how the morphing works! */
    -webkit-filter: url(#threshold) blur(0.6px);
            filter: url(#threshold) blur(0.6px);
}

/* Your average text styling */
#text1,
#text2 {
    position: absolute;
    width: 100%;
    display: inline-block;

    font-family: 'Raleway', sans-serif;
    font-size: 80pt;
    text-align: center;
    color: white;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@media screen and (max-width: 1024px) {

    #text1,
    #text2 {
        font-size: 40pt;
    }
}

.loader_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    z-index: 9999;
}

.loader_overlay ._bar_container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}

.loader_overlay ._bar_container ._bar {
    height: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    background-color: transparent;
}

._bar_container.end ._bar:nth-of-type(5) {
    background-color: white;
    transition: all ease-in 0.4s;
}

._bar_container.end ._bar:nth-of-type(4) {
    background-color: white;
    transition: all ease-in 0.4s 0.2s;
}

._bar_container.end ._bar:nth-of-type(6) {
    background-color: white;
    transition: all ease-in 0.4s 0.2s;
}

._bar_container.end ._bar:nth-of-type(3) {
    background-color: white;
    transition: all ease-in 0.4s 0.4s;
}

._bar_container.end ._bar:nth-of-type(7) {
    background-color: white;
    transition: all ease-in 0.4s 0.4s;
}

._bar_container.end ._bar:nth-of-type(2) {
    background-color: white;
    transition: all ease-in 0.4s 0.6s;
}

._bar_container.end ._bar:nth-of-type(8) {
    background-color: white;
    transition: all ease-in 0.4s 0.6s;
}

._bar_container.end ._bar:nth-of-type(1) {
    background-color: white;
    transition: all ease-in 0.4s 0.8s;
}

._bar_container.end ._bar:nth-of-type(9) {
    background-color: white;
    transition: all ease-in 0.4s 0.8s;
}

.home_content {
    opacity: 0;
    transition: all ease-in 0.4s;
    height: 100vh;
    overflow: hidden;
}

.home_content.active {
    opacity: 1;
    height: auto;
    overflow: scroll;
}
.container{
    /* width: 100vw; */
    text-align: center;
    padding-left: 30px;
    padding-right:30px;

}
.d-flex{
    padding: 40px 0 20px ;
    /* width: 100vw; */
}
.c-row{
    -webkit-align-items: center;
            align-items: center;
}
.Special-title{
    width:100%;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-left: 20px;
}

.c-team{
    display: -webkit-flex;
    display: flex;
    /* max-width: 400px; */
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.c-team-detail{
    text-align: start;
    margin: 20px;
}
.team-number{
    color: #101BF5;
    font-size: 50px;
    font-weight: bold;
    margin: 0;
}
.team-title{
    font-size: 20px;
    color: #939393;
    font-style: italic;
    font-weight: 600;
}

.list-body{
    width: 100%;
    margin-top: 50px;
}
.list-title{
    font-size: 45px;	
    font-weight: 650;
}
p{
    margin-bottom: 0 !important;
}
.list{
    color: #939393;
    font-size: 16px;
    font-weight: 400;
}

.list-text{
    width:100%;
    max-width:400px;
    font-size: 16px;
    text-align: start;
    line-height: 26px;
}
@media screen and (max-width: 575px) {
    .c-row{
        -webkit-align-items: center;
                align-items: center;
        /* flex-direction: column; */
    }
    .Special-title{
        margin: 20px 0;
    }
    .team-number{
        font-size: 40px;
    }
    .team-title{
        font-size: 14px;
    }
}
@media screen and (max-width:767px){
    .team-number{
        text-align: center;
    }
    .list-text{
        text-align: center;
        margin:auto;
    }
}
@media screen and (max-width: 1199px){
    .Special-title{
        margin: 20px 0;
    }
}

.container{
   
    width: 100%;
    display: inline-block;
  
}
.h1c{
   
    width:100%;
    /* padding-top: 20px; */
    font-size: 40px;
    font-style: 'Montserrat'; 
    font-weight: 700;
    text-align: left;
   
}
.imgslider{
    width: 815px;    
   
 
}
.theme{
    width: 300px;
    

}   
.work{
    color: #101BF5;
}

.twi .h1{
    display: inline-block;
    width: 100%;
}

.c-brand{
    margin: 80px 0;
}

.c-slider{
    margin: auto 0;
}

.c-slider img{
    width: 120px;
    margin: auto;
}

@media screen and (max-width: 767px) {
    .c-brand{
        margin: 40px 0;
    }
    .h1c{
        text-align: center;
    }
    .c-slider{
        margin: 0 0 30px;
    }
}

.brands_slider ._item{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}
.snow {
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
}

.snow:nth-child(1) {
    opacity: 0.2414;
    -webkit-transform: translate(18.6812vw, -10px) scale(0.7334);
            transform: translate(18.6812vw, -10px) scale(0.7334);
    -webkit-animation: fall-1 22s -3s linear infinite;
            animation: fall-1 22s -3s linear infinite;
}

@-webkit-keyframes fall-1 {
    41.481% {
        -webkit-transform: translate(28.4611vw, 41.481vh) scale(0.7334);
                transform: translate(28.4611vw, 41.481vh) scale(0.7334);
    }

    to {
        -webkit-transform: translate(23.57115vw, 100vh) scale(0.7334);
                transform: translate(23.57115vw, 100vh) scale(0.7334);
    }
}

@keyframes fall-1 {
    41.481% {
        -webkit-transform: translate(28.4611vw, 41.481vh) scale(0.7334);
                transform: translate(28.4611vw, 41.481vh) scale(0.7334);
    }

    to {
        -webkit-transform: translate(23.57115vw, 100vh) scale(0.7334);
                transform: translate(23.57115vw, 100vh) scale(0.7334);
    }
}

.snow:nth-child(2) {
    opacity: 0.9659;
    -webkit-transform: translate(4.601vw, -10px) scale(0.7193);
            transform: translate(4.601vw, -10px) scale(0.7193);
    -webkit-animation: fall-2 16s -9s linear infinite;
            animation: fall-2 16s -9s linear infinite;
}

@-webkit-keyframes fall-2 {
    30.651% {
        -webkit-transform: translate(-0.2614vw, 30.651vh) scale(0.7193);
                transform: translate(-0.2614vw, 30.651vh) scale(0.7193);
    }

    to {
        -webkit-transform: translate(2.1698vw, 100vh) scale(0.7193);
                transform: translate(2.1698vw, 100vh) scale(0.7193);
    }
}

@keyframes fall-2 {
    30.651% {
        -webkit-transform: translate(-0.2614vw, 30.651vh) scale(0.7193);
                transform: translate(-0.2614vw, 30.651vh) scale(0.7193);
    }

    to {
        -webkit-transform: translate(2.1698vw, 100vh) scale(0.7193);
                transform: translate(2.1698vw, 100vh) scale(0.7193);
    }
}

.snow:nth-child(3) {
    opacity: 0.9235;
    -webkit-transform: translate(26.71vw, -10px) scale(0.463);
            transform: translate(26.71vw, -10px) scale(0.463);
    -webkit-animation: fall-3 17s -24s linear infinite;
            animation: fall-3 17s -24s linear infinite;
}

@-webkit-keyframes fall-3 {
    70.224% {
        -webkit-transform: translate(18.2019vw, 70.224vh) scale(0.463);
                transform: translate(18.2019vw, 70.224vh) scale(0.463);
    }

    to {
        -webkit-transform: translate(22.45595vw, 100vh) scale(0.463);
                transform: translate(22.45595vw, 100vh) scale(0.463);
    }
}

@keyframes fall-3 {
    70.224% {
        -webkit-transform: translate(18.2019vw, 70.224vh) scale(0.463);
                transform: translate(18.2019vw, 70.224vh) scale(0.463);
    }

    to {
        -webkit-transform: translate(22.45595vw, 100vh) scale(0.463);
                transform: translate(22.45595vw, 100vh) scale(0.463);
    }
}

.snow:nth-child(4) {
    opacity: 0.8268;
    -webkit-transform: translate(68.7146vw, -10px) scale(0.5168);
            transform: translate(68.7146vw, -10px) scale(0.5168);
    -webkit-animation: fall-4 29s -19s linear infinite;
            animation: fall-4 29s -19s linear infinite;
}

@-webkit-keyframes fall-4 {
    61.373% {
        -webkit-transform: translate(61.0494vw, 61.373vh) scale(0.5168);
                transform: translate(61.0494vw, 61.373vh) scale(0.5168);
    }

    to {
        -webkit-transform: translate(64.882vw, 100vh) scale(0.5168);
                transform: translate(64.882vw, 100vh) scale(0.5168);
    }
}

@keyframes fall-4 {
    61.373% {
        -webkit-transform: translate(61.0494vw, 61.373vh) scale(0.5168);
                transform: translate(61.0494vw, 61.373vh) scale(0.5168);
    }

    to {
        -webkit-transform: translate(64.882vw, 100vh) scale(0.5168);
                transform: translate(64.882vw, 100vh) scale(0.5168);
    }
}

.snow:nth-child(5) {
    opacity: 0.2726;
    -webkit-transform: translate(13.5034vw, -10px) scale(0.6422);
            transform: translate(13.5034vw, -10px) scale(0.6422);
    -webkit-animation: fall-5 24s -23s linear infinite;
            animation: fall-5 24s -23s linear infinite;
}

@-webkit-keyframes fall-5 {
    71.439% {
        -webkit-transform: translate(22.3713vw, 71.439vh) scale(0.6422);
                transform: translate(22.3713vw, 71.439vh) scale(0.6422);
    }

    to {
        -webkit-transform: translate(17.93735vw, 100vh) scale(0.6422);
                transform: translate(17.93735vw, 100vh) scale(0.6422);
    }
}

@keyframes fall-5 {
    71.439% {
        -webkit-transform: translate(22.3713vw, 71.439vh) scale(0.6422);
                transform: translate(22.3713vw, 71.439vh) scale(0.6422);
    }

    to {
        -webkit-transform: translate(17.93735vw, 100vh) scale(0.6422);
                transform: translate(17.93735vw, 100vh) scale(0.6422);
    }
}

.snow:nth-child(6) {
    opacity: 0.4695;
    -webkit-transform: translate(5.4045vw, -10px) scale(0.1643);
            transform: translate(5.4045vw, -10px) scale(0.1643);
    -webkit-animation: fall-6 16s -25s linear infinite;
            animation: fall-6 16s -25s linear infinite;
}

@-webkit-keyframes fall-6 {
    58.384% {
        -webkit-transform: translate(6.0201vw, 58.384vh) scale(0.1643);
                transform: translate(6.0201vw, 58.384vh) scale(0.1643);
    }

    to {
        -webkit-transform: translate(5.7123vw, 100vh) scale(0.1643);
                transform: translate(5.7123vw, 100vh) scale(0.1643);
    }
}

@keyframes fall-6 {
    58.384% {
        -webkit-transform: translate(6.0201vw, 58.384vh) scale(0.1643);
                transform: translate(6.0201vw, 58.384vh) scale(0.1643);
    }

    to {
        -webkit-transform: translate(5.7123vw, 100vh) scale(0.1643);
                transform: translate(5.7123vw, 100vh) scale(0.1643);
    }
}

.snow:nth-child(7) {
    opacity: 0.8427;
    -webkit-transform: translate(74.0594vw, -10px) scale(0.7975);
            transform: translate(74.0594vw, -10px) scale(0.7975);
    -webkit-animation: fall-7 11s -20s linear infinite;
            animation: fall-7 11s -20s linear infinite;
}

@-webkit-keyframes fall-7 {
    68.204% {
        -webkit-transform: translate(73.3855vw, 68.204vh) scale(0.7975);
                transform: translate(73.3855vw, 68.204vh) scale(0.7975);
    }

    to {
        -webkit-transform: translate(73.72245vw, 100vh) scale(0.7975);
                transform: translate(73.72245vw, 100vh) scale(0.7975);
    }
}

@keyframes fall-7 {
    68.204% {
        -webkit-transform: translate(73.3855vw, 68.204vh) scale(0.7975);
                transform: translate(73.3855vw, 68.204vh) scale(0.7975);
    }

    to {
        -webkit-transform: translate(73.72245vw, 100vh) scale(0.7975);
                transform: translate(73.72245vw, 100vh) scale(0.7975);
    }
}

.snow:nth-child(8) {
    opacity: 0.7435;
    -webkit-transform: translate(42.7237vw, -10px) scale(0.3667);
            transform: translate(42.7237vw, -10px) scale(0.3667);
    -webkit-animation: fall-8 28s -22s linear infinite;
            animation: fall-8 28s -22s linear infinite;
}

@-webkit-keyframes fall-8 {
    68.573% {
        -webkit-transform: translate(48.3138vw, 68.573vh) scale(0.3667);
                transform: translate(48.3138vw, 68.573vh) scale(0.3667);
    }

    to {
        -webkit-transform: translate(45.51875vw, 100vh) scale(0.3667);
                transform: translate(45.51875vw, 100vh) scale(0.3667);
    }
}

@keyframes fall-8 {
    68.573% {
        -webkit-transform: translate(48.3138vw, 68.573vh) scale(0.3667);
                transform: translate(48.3138vw, 68.573vh) scale(0.3667);
    }

    to {
        -webkit-transform: translate(45.51875vw, 100vh) scale(0.3667);
                transform: translate(45.51875vw, 100vh) scale(0.3667);
    }
}

.snow:nth-child(9) {
    opacity: 0.9389;
    -webkit-transform: translate(45.0993vw, -10px) scale(0.8661);
            transform: translate(45.0993vw, -10px) scale(0.8661);
    -webkit-animation: fall-9 15s -9s linear infinite;
            animation: fall-9 15s -9s linear infinite;
}

@-webkit-keyframes fall-9 {
    49.218% {
        -webkit-transform: translate(47.3843vw, 49.218vh) scale(0.8661);
                transform: translate(47.3843vw, 49.218vh) scale(0.8661);
    }

    to {
        -webkit-transform: translate(46.2418vw, 100vh) scale(0.8661);
                transform: translate(46.2418vw, 100vh) scale(0.8661);
    }
}

@keyframes fall-9 {
    49.218% {
        -webkit-transform: translate(47.3843vw, 49.218vh) scale(0.8661);
                transform: translate(47.3843vw, 49.218vh) scale(0.8661);
    }

    to {
        -webkit-transform: translate(46.2418vw, 100vh) scale(0.8661);
                transform: translate(46.2418vw, 100vh) scale(0.8661);
    }
}

.snow:nth-child(10) {
    opacity: 0.9551;
    -webkit-transform: translate(47.1682vw, -10px) scale(0.2588);
            transform: translate(47.1682vw, -10px) scale(0.2588);
    -webkit-animation: fall-10 30s -3s linear infinite;
            animation: fall-10 30s -3s linear infinite;
}

@-webkit-keyframes fall-10 {
    55.537% {
        -webkit-transform: translate(38.0844vw, 55.537vh) scale(0.2588);
                transform: translate(38.0844vw, 55.537vh) scale(0.2588);
    }

    to {
        -webkit-transform: translate(42.6263vw, 100vh) scale(0.2588);
                transform: translate(42.6263vw, 100vh) scale(0.2588);
    }
}

@keyframes fall-10 {
    55.537% {
        -webkit-transform: translate(38.0844vw, 55.537vh) scale(0.2588);
                transform: translate(38.0844vw, 55.537vh) scale(0.2588);
    }

    to {
        -webkit-transform: translate(42.6263vw, 100vh) scale(0.2588);
                transform: translate(42.6263vw, 100vh) scale(0.2588);
    }
}

.snow:nth-child(11) {
    opacity: 0.0524;
    -webkit-transform: translate(3.0464vw, -10px) scale(0.9494);
            transform: translate(3.0464vw, -10px) scale(0.9494);
    -webkit-animation: fall-11 15s -13s linear infinite;
            animation: fall-11 15s -13s linear infinite;
}

@-webkit-keyframes fall-11 {
    42.821% {
        -webkit-transform: translate(-6.7257vw, 42.821vh) scale(0.9494);
                transform: translate(-6.7257vw, 42.821vh) scale(0.9494);
    }

    to {
        -webkit-transform: translate(-1.83965vw, 100vh) scale(0.9494);
                transform: translate(-1.83965vw, 100vh) scale(0.9494);
    }
}

@keyframes fall-11 {
    42.821% {
        -webkit-transform: translate(-6.7257vw, 42.821vh) scale(0.9494);
                transform: translate(-6.7257vw, 42.821vh) scale(0.9494);
    }

    to {
        -webkit-transform: translate(-1.83965vw, 100vh) scale(0.9494);
                transform: translate(-1.83965vw, 100vh) scale(0.9494);
    }
}

.snow:nth-child(12) {
    opacity: 0.5066;
    -webkit-transform: translate(55.9152vw, -10px) scale(0.132);
            transform: translate(55.9152vw, -10px) scale(0.132);
    -webkit-animation: fall-12 27s -21s linear infinite;
            animation: fall-12 27s -21s linear infinite;
}

@-webkit-keyframes fall-12 {
    36.201% {
        -webkit-transform: translate(56.7194vw, 36.201vh) scale(0.132);
                transform: translate(56.7194vw, 36.201vh) scale(0.132);
    }

    to {
        -webkit-transform: translate(56.3173vw, 100vh) scale(0.132);
                transform: translate(56.3173vw, 100vh) scale(0.132);
    }
}

@keyframes fall-12 {
    36.201% {
        -webkit-transform: translate(56.7194vw, 36.201vh) scale(0.132);
                transform: translate(56.7194vw, 36.201vh) scale(0.132);
    }

    to {
        -webkit-transform: translate(56.3173vw, 100vh) scale(0.132);
                transform: translate(56.3173vw, 100vh) scale(0.132);
    }
}

.snow:nth-child(13) {
    opacity: 0.2191;
    -webkit-transform: translate(69.0537vw, -10px) scale(0.827);
            transform: translate(69.0537vw, -10px) scale(0.827);
    -webkit-animation: fall-13 29s -21s linear infinite;
            animation: fall-13 29s -21s linear infinite;
}

@-webkit-keyframes fall-13 {
    73.059% {
        -webkit-transform: translate(60.7775vw, 73.059vh) scale(0.827);
                transform: translate(60.7775vw, 73.059vh) scale(0.827);
    }

    to {
        -webkit-transform: translate(64.9156vw, 100vh) scale(0.827);
                transform: translate(64.9156vw, 100vh) scale(0.827);
    }
}

@keyframes fall-13 {
    73.059% {
        -webkit-transform: translate(60.7775vw, 73.059vh) scale(0.827);
                transform: translate(60.7775vw, 73.059vh) scale(0.827);
    }

    to {
        -webkit-transform: translate(64.9156vw, 100vh) scale(0.827);
                transform: translate(64.9156vw, 100vh) scale(0.827);
    }
}

.snow:nth-child(14) {
    opacity: 0.0656;
    -webkit-transform: translate(13.5579vw, -10px) scale(0.0586);
            transform: translate(13.5579vw, -10px) scale(0.0586);
    -webkit-animation: fall-14 30s -15s linear infinite;
            animation: fall-14 30s -15s linear infinite;
}

@-webkit-keyframes fall-14 {
    46.068% {
        -webkit-transform: translate(7.7555vw, 46.068vh) scale(0.0586);
                transform: translate(7.7555vw, 46.068vh) scale(0.0586);
    }

    to {
        -webkit-transform: translate(10.6567vw, 100vh) scale(0.0586);
                transform: translate(10.6567vw, 100vh) scale(0.0586);
    }
}

@keyframes fall-14 {
    46.068% {
        -webkit-transform: translate(7.7555vw, 46.068vh) scale(0.0586);
                transform: translate(7.7555vw, 46.068vh) scale(0.0586);
    }

    to {
        -webkit-transform: translate(10.6567vw, 100vh) scale(0.0586);
                transform: translate(10.6567vw, 100vh) scale(0.0586);
    }
}

.snow:nth-child(15) {
    opacity: 0.3987;
    -webkit-transform: translate(63.7839vw, -10px) scale(0.6007);
            transform: translate(63.7839vw, -10px) scale(0.6007);
    -webkit-animation: fall-15 22s -1s linear infinite;
            animation: fall-15 22s -1s linear infinite;
}

@-webkit-keyframes fall-15 {
    52.574% {
        -webkit-transform: translate(59.9441vw, 52.574vh) scale(0.6007);
                transform: translate(59.9441vw, 52.574vh) scale(0.6007);
    }

    to {
        -webkit-transform: translate(61.864vw, 100vh) scale(0.6007);
                transform: translate(61.864vw, 100vh) scale(0.6007);
    }
}

@keyframes fall-15 {
    52.574% {
        -webkit-transform: translate(59.9441vw, 52.574vh) scale(0.6007);
                transform: translate(59.9441vw, 52.574vh) scale(0.6007);
    }

    to {
        -webkit-transform: translate(61.864vw, 100vh) scale(0.6007);
                transform: translate(61.864vw, 100vh) scale(0.6007);
    }
}

.snow:nth-child(16) {
    opacity: 0.4117;
    -webkit-transform: translate(46.217vw, -10px) scale(0.3057);
            transform: translate(46.217vw, -10px) scale(0.3057);
    -webkit-animation: fall-16 12s -25s linear infinite;
            animation: fall-16 12s -25s linear infinite;
}

@-webkit-keyframes fall-16 {
    34.09% {
        -webkit-transform: translate(38.456vw, 34.09vh) scale(0.3057);
                transform: translate(38.456vw, 34.09vh) scale(0.3057);
    }

    to {
        -webkit-transform: translate(42.3365vw, 100vh) scale(0.3057);
                transform: translate(42.3365vw, 100vh) scale(0.3057);
    }
}

@keyframes fall-16 {
    34.09% {
        -webkit-transform: translate(38.456vw, 34.09vh) scale(0.3057);
                transform: translate(38.456vw, 34.09vh) scale(0.3057);
    }

    to {
        -webkit-transform: translate(42.3365vw, 100vh) scale(0.3057);
                transform: translate(42.3365vw, 100vh) scale(0.3057);
    }
}

.snow:nth-child(17) {
    opacity: 0.8366;
    -webkit-transform: translate(86.9648vw, -10px) scale(0.7315);
            transform: translate(86.9648vw, -10px) scale(0.7315);
    -webkit-animation: fall-17 10s -19s linear infinite;
            animation: fall-17 10s -19s linear infinite;
}

@-webkit-keyframes fall-17 {
    40.628% {
        -webkit-transform: translate(94.0181vw, 40.628vh) scale(0.7315);
                transform: translate(94.0181vw, 40.628vh) scale(0.7315);
    }

    to {
        -webkit-transform: translate(90.49145vw, 100vh) scale(0.7315);
                transform: translate(90.49145vw, 100vh) scale(0.7315);
    }
}

@keyframes fall-17 {
    40.628% {
        -webkit-transform: translate(94.0181vw, 40.628vh) scale(0.7315);
                transform: translate(94.0181vw, 40.628vh) scale(0.7315);
    }

    to {
        -webkit-transform: translate(90.49145vw, 100vh) scale(0.7315);
                transform: translate(90.49145vw, 100vh) scale(0.7315);
    }
}

.snow:nth-child(18) {
    opacity: 0.5224;
    -webkit-transform: translate(6.7887vw, -10px) scale(0.2267);
            transform: translate(6.7887vw, -10px) scale(0.2267);
    -webkit-animation: fall-18 17s -15s linear infinite;
            animation: fall-18 17s -15s linear infinite;
}

@-webkit-keyframes fall-18 {
    72% {
        -webkit-transform: translate(14.6419vw, 72vh) scale(0.2267);
                transform: translate(14.6419vw, 72vh) scale(0.2267);
    }

    to {
        -webkit-transform: translate(10.7153vw, 100vh) scale(0.2267);
                transform: translate(10.7153vw, 100vh) scale(0.2267);
    }
}

@keyframes fall-18 {
    72% {
        -webkit-transform: translate(14.6419vw, 72vh) scale(0.2267);
                transform: translate(14.6419vw, 72vh) scale(0.2267);
    }

    to {
        -webkit-transform: translate(10.7153vw, 100vh) scale(0.2267);
                transform: translate(10.7153vw, 100vh) scale(0.2267);
    }
}

.snow:nth-child(19) {
    opacity: 0.0579;
    -webkit-transform: translate(31.4477vw, -10px) scale(0.9591);
            transform: translate(31.4477vw, -10px) scale(0.9591);
    -webkit-animation: fall-19 23s -15s linear infinite;
            animation: fall-19 23s -15s linear infinite;
}

@-webkit-keyframes fall-19 {
    35.404% {
        -webkit-transform: translate(36.7601vw, 35.404vh) scale(0.9591);
                transform: translate(36.7601vw, 35.404vh) scale(0.9591);
    }

    to {
        -webkit-transform: translate(34.1039vw, 100vh) scale(0.9591);
                transform: translate(34.1039vw, 100vh) scale(0.9591);
    }
}

@keyframes fall-19 {
    35.404% {
        -webkit-transform: translate(36.7601vw, 35.404vh) scale(0.9591);
                transform: translate(36.7601vw, 35.404vh) scale(0.9591);
    }

    to {
        -webkit-transform: translate(34.1039vw, 100vh) scale(0.9591);
                transform: translate(34.1039vw, 100vh) scale(0.9591);
    }
}

.snow:nth-child(20) {
    opacity: 0.7139;
    -webkit-transform: translate(30.5907vw, -10px) scale(0.5031);
            transform: translate(30.5907vw, -10px) scale(0.5031);
    -webkit-animation: fall-20 11s -1s linear infinite;
            animation: fall-20 11s -1s linear infinite;
}

@-webkit-keyframes fall-20 {
    30.656% {
        -webkit-transform: translate(25.6955vw, 30.656vh) scale(0.5031);
                transform: translate(25.6955vw, 30.656vh) scale(0.5031);
    }

    to {
        -webkit-transform: translate(28.1431vw, 100vh) scale(0.5031);
                transform: translate(28.1431vw, 100vh) scale(0.5031);
    }
}

@keyframes fall-20 {
    30.656% {
        -webkit-transform: translate(25.6955vw, 30.656vh) scale(0.5031);
                transform: translate(25.6955vw, 30.656vh) scale(0.5031);
    }

    to {
        -webkit-transform: translate(28.1431vw, 100vh) scale(0.5031);
                transform: translate(28.1431vw, 100vh) scale(0.5031);
    }
}

.snow:nth-child(21) {
    opacity: 0.1145;
    -webkit-transform: translate(64.7495vw, -10px) scale(0.9803);
            transform: translate(64.7495vw, -10px) scale(0.9803);
    -webkit-animation: fall-21 25s -2s linear infinite;
            animation: fall-21 25s -2s linear infinite;
}

@-webkit-keyframes fall-21 {
    68.227% {
        -webkit-transform: translate(69.2713vw, 68.227vh) scale(0.9803);
                transform: translate(69.2713vw, 68.227vh) scale(0.9803);
    }

    to {
        -webkit-transform: translate(67.0104vw, 100vh) scale(0.9803);
                transform: translate(67.0104vw, 100vh) scale(0.9803);
    }
}

@keyframes fall-21 {
    68.227% {
        -webkit-transform: translate(69.2713vw, 68.227vh) scale(0.9803);
                transform: translate(69.2713vw, 68.227vh) scale(0.9803);
    }

    to {
        -webkit-transform: translate(67.0104vw, 100vh) scale(0.9803);
                transform: translate(67.0104vw, 100vh) scale(0.9803);
    }
}

.snow:nth-child(22) {
    opacity: 0.2444;
    -webkit-transform: translate(59.736vw, -10px) scale(0.5654);
            transform: translate(59.736vw, -10px) scale(0.5654);
    -webkit-animation: fall-22 28s -26s linear infinite;
            animation: fall-22 28s -26s linear infinite;
}

@-webkit-keyframes fall-22 {
    42.482% {
        -webkit-transform: translate(58.9206vw, 42.482vh) scale(0.5654);
                transform: translate(58.9206vw, 42.482vh) scale(0.5654);
    }

    to {
        -webkit-transform: translate(59.3283vw, 100vh) scale(0.5654);
                transform: translate(59.3283vw, 100vh) scale(0.5654);
    }
}

@keyframes fall-22 {
    42.482% {
        -webkit-transform: translate(58.9206vw, 42.482vh) scale(0.5654);
                transform: translate(58.9206vw, 42.482vh) scale(0.5654);
    }

    to {
        -webkit-transform: translate(59.3283vw, 100vh) scale(0.5654);
                transform: translate(59.3283vw, 100vh) scale(0.5654);
    }
}

.snow:nth-child(23) {
    opacity: 0.5458;
    -webkit-transform: translate(62.148vw, -10px) scale(0.5399);
            transform: translate(62.148vw, -10px) scale(0.5399);
    -webkit-animation: fall-23 11s -22s linear infinite;
            animation: fall-23 11s -22s linear infinite;
}

@-webkit-keyframes fall-23 {
    33.509% {
        -webkit-transform: translate(58.865vw, 33.509vh) scale(0.5399);
                transform: translate(58.865vw, 33.509vh) scale(0.5399);
    }

    to {
        -webkit-transform: translate(60.5065vw, 100vh) scale(0.5399);
                transform: translate(60.5065vw, 100vh) scale(0.5399);
    }
}

@keyframes fall-23 {
    33.509% {
        -webkit-transform: translate(58.865vw, 33.509vh) scale(0.5399);
                transform: translate(58.865vw, 33.509vh) scale(0.5399);
    }

    to {
        -webkit-transform: translate(60.5065vw, 100vh) scale(0.5399);
                transform: translate(60.5065vw, 100vh) scale(0.5399);
    }
}

.snow:nth-child(24) {
    opacity: 0.7767;
    -webkit-transform: translate(77.2982vw, -10px) scale(0.7956);
            transform: translate(77.2982vw, -10px) scale(0.7956);
    -webkit-animation: fall-24 18s -8s linear infinite;
            animation: fall-24 18s -8s linear infinite;
}

@-webkit-keyframes fall-24 {
    53.654% {
        -webkit-transform: translate(74.7075vw, 53.654vh) scale(0.7956);
                transform: translate(74.7075vw, 53.654vh) scale(0.7956);
    }

    to {
        -webkit-transform: translate(76.00285vw, 100vh) scale(0.7956);
                transform: translate(76.00285vw, 100vh) scale(0.7956);
    }
}

@keyframes fall-24 {
    53.654% {
        -webkit-transform: translate(74.7075vw, 53.654vh) scale(0.7956);
                transform: translate(74.7075vw, 53.654vh) scale(0.7956);
    }

    to {
        -webkit-transform: translate(76.00285vw, 100vh) scale(0.7956);
                transform: translate(76.00285vw, 100vh) scale(0.7956);
    }
}

.snow:nth-child(25) {
    opacity: 0.2351;
    -webkit-transform: translate(40.8044vw, -10px) scale(0.3409);
            transform: translate(40.8044vw, -10px) scale(0.3409);
    -webkit-animation: fall-25 17s -24s linear infinite;
            animation: fall-25 17s -24s linear infinite;
}

@-webkit-keyframes fall-25 {
    37.869% {
        -webkit-transform: translate(37.6862vw, 37.869vh) scale(0.3409);
                transform: translate(37.6862vw, 37.869vh) scale(0.3409);
    }

    to {
        -webkit-transform: translate(39.2453vw, 100vh) scale(0.3409);
                transform: translate(39.2453vw, 100vh) scale(0.3409);
    }
}

@keyframes fall-25 {
    37.869% {
        -webkit-transform: translate(37.6862vw, 37.869vh) scale(0.3409);
                transform: translate(37.6862vw, 37.869vh) scale(0.3409);
    }

    to {
        -webkit-transform: translate(39.2453vw, 100vh) scale(0.3409);
                transform: translate(39.2453vw, 100vh) scale(0.3409);
    }
}

.snow:nth-child(26) {
    opacity: 0.9414;
    -webkit-transform: translate(64.5746vw, -10px) scale(0.7607);
            transform: translate(64.5746vw, -10px) scale(0.7607);
    -webkit-animation: fall-26 15s -18s linear infinite;
            animation: fall-26 15s -18s linear infinite;
}

@-webkit-keyframes fall-26 {
    36% {
        -webkit-transform: translate(68.4015vw, 36vh) scale(0.7607);
                transform: translate(68.4015vw, 36vh) scale(0.7607);
    }

    to {
        -webkit-transform: translate(66.48805vw, 100vh) scale(0.7607);
                transform: translate(66.48805vw, 100vh) scale(0.7607);
    }
}

@keyframes fall-26 {
    36% {
        -webkit-transform: translate(68.4015vw, 36vh) scale(0.7607);
                transform: translate(68.4015vw, 36vh) scale(0.7607);
    }

    to {
        -webkit-transform: translate(66.48805vw, 100vh) scale(0.7607);
                transform: translate(66.48805vw, 100vh) scale(0.7607);
    }
}

.snow:nth-child(27) {
    opacity: 0.5454;
    -webkit-transform: translate(79.6417vw, -10px) scale(0.5151);
            transform: translate(79.6417vw, -10px) scale(0.5151);
    -webkit-animation: fall-27 10s -24s linear infinite;
            animation: fall-27 10s -24s linear infinite;
}

@-webkit-keyframes fall-27 {
    67.846% {
        -webkit-transform: translate(85.9551vw, 67.846vh) scale(0.5151);
                transform: translate(85.9551vw, 67.846vh) scale(0.5151);
    }

    to {
        -webkit-transform: translate(82.7984vw, 100vh) scale(0.5151);
                transform: translate(82.7984vw, 100vh) scale(0.5151);
    }
}

@keyframes fall-27 {
    67.846% {
        -webkit-transform: translate(85.9551vw, 67.846vh) scale(0.5151);
                transform: translate(85.9551vw, 67.846vh) scale(0.5151);
    }

    to {
        -webkit-transform: translate(82.7984vw, 100vh) scale(0.5151);
                transform: translate(82.7984vw, 100vh) scale(0.5151);
    }
}

.snow:nth-child(28) {
    opacity: 0.2554;
    -webkit-transform: translate(84.4155vw, -10px) scale(0.2795);
            transform: translate(84.4155vw, -10px) scale(0.2795);
    -webkit-animation: fall-28 24s -15s linear infinite;
            animation: fall-28 24s -15s linear infinite;
}

@-webkit-keyframes fall-28 {
    78.038% {
        -webkit-transform: translate(89.2634vw, 78.038vh) scale(0.2795);
                transform: translate(89.2634vw, 78.038vh) scale(0.2795);
    }

    to {
        -webkit-transform: translate(86.83945vw, 100vh) scale(0.2795);
                transform: translate(86.83945vw, 100vh) scale(0.2795);
    }
}

@keyframes fall-28 {
    78.038% {
        -webkit-transform: translate(89.2634vw, 78.038vh) scale(0.2795);
                transform: translate(89.2634vw, 78.038vh) scale(0.2795);
    }

    to {
        -webkit-transform: translate(86.83945vw, 100vh) scale(0.2795);
                transform: translate(86.83945vw, 100vh) scale(0.2795);
    }
}

.snow:nth-child(29) {
    opacity: 0.8124;
    -webkit-transform: translate(11.6064vw, -10px) scale(0.4258);
            transform: translate(11.6064vw, -10px) scale(0.4258);
    -webkit-animation: fall-29 18s -27s linear infinite;
            animation: fall-29 18s -27s linear infinite;
}

@-webkit-keyframes fall-29 {
    43.639% {
        -webkit-transform: translate(10.1242vw, 43.639vh) scale(0.4258);
                transform: translate(10.1242vw, 43.639vh) scale(0.4258);
    }

    to {
        -webkit-transform: translate(10.8653vw, 100vh) scale(0.4258);
                transform: translate(10.8653vw, 100vh) scale(0.4258);
    }
}

@keyframes fall-29 {
    43.639% {
        -webkit-transform: translate(10.1242vw, 43.639vh) scale(0.4258);
                transform: translate(10.1242vw, 43.639vh) scale(0.4258);
    }

    to {
        -webkit-transform: translate(10.8653vw, 100vh) scale(0.4258);
                transform: translate(10.8653vw, 100vh) scale(0.4258);
    }
}

.snow:nth-child(30) {
    opacity: 0.1927;
    -webkit-transform: translate(8.7146vw, -10px) scale(0.562);
            transform: translate(8.7146vw, -10px) scale(0.562);
    -webkit-animation: fall-30 13s -8s linear infinite;
            animation: fall-30 13s -8s linear infinite;
}

@-webkit-keyframes fall-30 {
    30.891% {
        -webkit-transform: translate(1.736vw, 30.891vh) scale(0.562);
                transform: translate(1.736vw, 30.891vh) scale(0.562);
    }

    to {
        -webkit-transform: translate(5.2253vw, 100vh) scale(0.562);
                transform: translate(5.2253vw, 100vh) scale(0.562);
    }
}

@keyframes fall-30 {
    30.891% {
        -webkit-transform: translate(1.736vw, 30.891vh) scale(0.562);
                transform: translate(1.736vw, 30.891vh) scale(0.562);
    }

    to {
        -webkit-transform: translate(5.2253vw, 100vh) scale(0.562);
                transform: translate(5.2253vw, 100vh) scale(0.562);
    }
}

.snow:nth-child(31) {
    opacity: 0.8913;
    -webkit-transform: translate(39.6558vw, -10px) scale(0.2459);
            transform: translate(39.6558vw, -10px) scale(0.2459);
    -webkit-animation: fall-31 14s -12s linear infinite;
            animation: fall-31 14s -12s linear infinite;
}

@-webkit-keyframes fall-31 {
    67.68% {
        -webkit-transform: translate(32.2791vw, 67.68vh) scale(0.2459);
                transform: translate(32.2791vw, 67.68vh) scale(0.2459);
    }

    to {
        -webkit-transform: translate(35.96745vw, 100vh) scale(0.2459);
                transform: translate(35.96745vw, 100vh) scale(0.2459);
    }
}

@keyframes fall-31 {
    67.68% {
        -webkit-transform: translate(32.2791vw, 67.68vh) scale(0.2459);
                transform: translate(32.2791vw, 67.68vh) scale(0.2459);
    }

    to {
        -webkit-transform: translate(35.96745vw, 100vh) scale(0.2459);
                transform: translate(35.96745vw, 100vh) scale(0.2459);
    }
}

.snow:nth-child(32) {
    opacity: 0.2922;
    -webkit-transform: translate(43.4211vw, -10px) scale(0.5811);
            transform: translate(43.4211vw, -10px) scale(0.5811);
    -webkit-animation: fall-32 22s -26s linear infinite;
            animation: fall-32 22s -26s linear infinite;
}

@-webkit-keyframes fall-32 {
    63.014% {
        -webkit-transform: translate(48.1466vw, 63.014vh) scale(0.5811);
                transform: translate(48.1466vw, 63.014vh) scale(0.5811);
    }

    to {
        -webkit-transform: translate(45.78385vw, 100vh) scale(0.5811);
                transform: translate(45.78385vw, 100vh) scale(0.5811);
    }
}

@keyframes fall-32 {
    63.014% {
        -webkit-transform: translate(48.1466vw, 63.014vh) scale(0.5811);
                transform: translate(48.1466vw, 63.014vh) scale(0.5811);
    }

    to {
        -webkit-transform: translate(45.78385vw, 100vh) scale(0.5811);
                transform: translate(45.78385vw, 100vh) scale(0.5811);
    }
}

.snow:nth-child(33) {
    opacity: 0.1044;
    -webkit-transform: translate(56.4039vw, -10px) scale(0.8424);
            transform: translate(56.4039vw, -10px) scale(0.8424);
    -webkit-animation: fall-33 13s -7s linear infinite;
            animation: fall-33 13s -7s linear infinite;
}

@-webkit-keyframes fall-33 {
    46.574% {
        -webkit-transform: translate(65.0193vw, 46.574vh) scale(0.8424);
                transform: translate(65.0193vw, 46.574vh) scale(0.8424);
    }

    to {
        -webkit-transform: translate(60.7116vw, 100vh) scale(0.8424);
                transform: translate(60.7116vw, 100vh) scale(0.8424);
    }
}

@keyframes fall-33 {
    46.574% {
        -webkit-transform: translate(65.0193vw, 46.574vh) scale(0.8424);
                transform: translate(65.0193vw, 46.574vh) scale(0.8424);
    }

    to {
        -webkit-transform: translate(60.7116vw, 100vh) scale(0.8424);
                transform: translate(60.7116vw, 100vh) scale(0.8424);
    }
}

.snow:nth-child(34) {
    opacity: 0.7711;
    -webkit-transform: translate(19.1071vw, -10px) scale(0.4548);
            transform: translate(19.1071vw, -10px) scale(0.4548);
    -webkit-animation: fall-34 25s -2s linear infinite;
            animation: fall-34 25s -2s linear infinite;
}

@-webkit-keyframes fall-34 {
    46.961% {
        -webkit-transform: translate(24.5992vw, 46.961vh) scale(0.4548);
                transform: translate(24.5992vw, 46.961vh) scale(0.4548);
    }

    to {
        -webkit-transform: translate(21.85315vw, 100vh) scale(0.4548);
                transform: translate(21.85315vw, 100vh) scale(0.4548);
    }
}

@keyframes fall-34 {
    46.961% {
        -webkit-transform: translate(24.5992vw, 46.961vh) scale(0.4548);
                transform: translate(24.5992vw, 46.961vh) scale(0.4548);
    }

    to {
        -webkit-transform: translate(21.85315vw, 100vh) scale(0.4548);
                transform: translate(21.85315vw, 100vh) scale(0.4548);
    }
}

.snow:nth-child(35) {
    opacity: 0.4068;
    -webkit-transform: translate(73.1962vw, -10px) scale(0.3954);
            transform: translate(73.1962vw, -10px) scale(0.3954);
    -webkit-animation: fall-35 13s -10s linear infinite;
            animation: fall-35 13s -10s linear infinite;
}

@-webkit-keyframes fall-35 {
    55.146% {
        -webkit-transform: translate(71.1506vw, 55.146vh) scale(0.3954);
                transform: translate(71.1506vw, 55.146vh) scale(0.3954);
    }

    to {
        -webkit-transform: translate(72.1734vw, 100vh) scale(0.3954);
                transform: translate(72.1734vw, 100vh) scale(0.3954);
    }
}

@keyframes fall-35 {
    55.146% {
        -webkit-transform: translate(71.1506vw, 55.146vh) scale(0.3954);
                transform: translate(71.1506vw, 55.146vh) scale(0.3954);
    }

    to {
        -webkit-transform: translate(72.1734vw, 100vh) scale(0.3954);
                transform: translate(72.1734vw, 100vh) scale(0.3954);
    }
}

.snow:nth-child(36) {
    opacity: 0.5771;
    -webkit-transform: translate(32.42vw, -10px) scale(0.7961);
            transform: translate(32.42vw, -10px) scale(0.7961);
    -webkit-animation: fall-36 29s -19s linear infinite;
            animation: fall-36 29s -19s linear infinite;
}

@-webkit-keyframes fall-36 {
    77.725% {
        -webkit-transform: translate(28.9388vw, 77.725vh) scale(0.7961);
                transform: translate(28.9388vw, 77.725vh) scale(0.7961);
    }

    to {
        -webkit-transform: translate(30.6794vw, 100vh) scale(0.7961);
                transform: translate(30.6794vw, 100vh) scale(0.7961);
    }
}

@keyframes fall-36 {
    77.725% {
        -webkit-transform: translate(28.9388vw, 77.725vh) scale(0.7961);
                transform: translate(28.9388vw, 77.725vh) scale(0.7961);
    }

    to {
        -webkit-transform: translate(30.6794vw, 100vh) scale(0.7961);
                transform: translate(30.6794vw, 100vh) scale(0.7961);
    }
}

.snow:nth-child(37) {
    opacity: 0.0604;
    -webkit-transform: translate(99.4249vw, -10px) scale(0.8945);
            transform: translate(99.4249vw, -10px) scale(0.8945);
    -webkit-animation: fall-37 21s -16s linear infinite;
            animation: fall-37 21s -16s linear infinite;
}

@-webkit-keyframes fall-37 {
    68.432% {
        -webkit-transform: translate(93.0184vw, 68.432vh) scale(0.8945);
                transform: translate(93.0184vw, 68.432vh) scale(0.8945);
    }

    to {
        -webkit-transform: translate(96.22165vw, 100vh) scale(0.8945);
                transform: translate(96.22165vw, 100vh) scale(0.8945);
    }
}

@keyframes fall-37 {
    68.432% {
        -webkit-transform: translate(93.0184vw, 68.432vh) scale(0.8945);
                transform: translate(93.0184vw, 68.432vh) scale(0.8945);
    }

    to {
        -webkit-transform: translate(96.22165vw, 100vh) scale(0.8945);
                transform: translate(96.22165vw, 100vh) scale(0.8945);
    }
}

.snow:nth-child(38) {
    opacity: 0.322;
    -webkit-transform: translate(7.3582vw, -10px) scale(0.1957);
            transform: translate(7.3582vw, -10px) scale(0.1957);
    -webkit-animation: fall-38 11s -12s linear infinite;
            animation: fall-38 11s -12s linear infinite;
}

@-webkit-keyframes fall-38 {
    74.537% {
        -webkit-transform: translate(16.1915vw, 74.537vh) scale(0.1957);
                transform: translate(16.1915vw, 74.537vh) scale(0.1957);
    }

    to {
        -webkit-transform: translate(11.77485vw, 100vh) scale(0.1957);
                transform: translate(11.77485vw, 100vh) scale(0.1957);
    }
}

@keyframes fall-38 {
    74.537% {
        -webkit-transform: translate(16.1915vw, 74.537vh) scale(0.1957);
                transform: translate(16.1915vw, 74.537vh) scale(0.1957);
    }

    to {
        -webkit-transform: translate(11.77485vw, 100vh) scale(0.1957);
                transform: translate(11.77485vw, 100vh) scale(0.1957);
    }
}

.snow:nth-child(39) {
    opacity: 0.4611;
    -webkit-transform: translate(24.8423vw, -10px) scale(0.3745);
            transform: translate(24.8423vw, -10px) scale(0.3745);
    -webkit-animation: fall-39 19s -10s linear infinite;
            animation: fall-39 19s -10s linear infinite;
}

@-webkit-keyframes fall-39 {
    56.916% {
        -webkit-transform: translate(23.5572vw, 56.916vh) scale(0.3745);
                transform: translate(23.5572vw, 56.916vh) scale(0.3745);
    }

    to {
        -webkit-transform: translate(24.19975vw, 100vh) scale(0.3745);
                transform: translate(24.19975vw, 100vh) scale(0.3745);
    }
}

@keyframes fall-39 {
    56.916% {
        -webkit-transform: translate(23.5572vw, 56.916vh) scale(0.3745);
                transform: translate(23.5572vw, 56.916vh) scale(0.3745);
    }

    to {
        -webkit-transform: translate(24.19975vw, 100vh) scale(0.3745);
                transform: translate(24.19975vw, 100vh) scale(0.3745);
    }
}

.snow:nth-child(40) {
    opacity: 0.2368;
    -webkit-transform: translate(10.9058vw, -10px) scale(0.3202);
            transform: translate(10.9058vw, -10px) scale(0.3202);
    -webkit-animation: fall-40 27s -28s linear infinite;
            animation: fall-40 27s -28s linear infinite;
}

@-webkit-keyframes fall-40 {
    52.995% {
        -webkit-transform: translate(13.3636vw, 52.995vh) scale(0.3202);
                transform: translate(13.3636vw, 52.995vh) scale(0.3202);
    }

    to {
        -webkit-transform: translate(12.1347vw, 100vh) scale(0.3202);
                transform: translate(12.1347vw, 100vh) scale(0.3202);
    }
}

@keyframes fall-40 {
    52.995% {
        -webkit-transform: translate(13.3636vw, 52.995vh) scale(0.3202);
                transform: translate(13.3636vw, 52.995vh) scale(0.3202);
    }

    to {
        -webkit-transform: translate(12.1347vw, 100vh) scale(0.3202);
                transform: translate(12.1347vw, 100vh) scale(0.3202);
    }
}

.snow:nth-child(41) {
    opacity: 0.1412;
    -webkit-transform: translate(65.8026vw, -10px) scale(0.7061);
            transform: translate(65.8026vw, -10px) scale(0.7061);
    -webkit-animation: fall-41 15s -6s linear infinite;
            animation: fall-41 15s -6s linear infinite;
}

@-webkit-keyframes fall-41 {
    56.846% {
        -webkit-transform: translate(59.944vw, 56.846vh) scale(0.7061);
                transform: translate(59.944vw, 56.846vh) scale(0.7061);
    }

    to {
        -webkit-transform: translate(62.8733vw, 100vh) scale(0.7061);
                transform: translate(62.8733vw, 100vh) scale(0.7061);
    }
}

@keyframes fall-41 {
    56.846% {
        -webkit-transform: translate(59.944vw, 56.846vh) scale(0.7061);
                transform: translate(59.944vw, 56.846vh) scale(0.7061);
    }

    to {
        -webkit-transform: translate(62.8733vw, 100vh) scale(0.7061);
                transform: translate(62.8733vw, 100vh) scale(0.7061);
    }
}

.snow:nth-child(42) {
    opacity: 0.7506;
    -webkit-transform: translate(28.4794vw, -10px) scale(0.9962);
            transform: translate(28.4794vw, -10px) scale(0.9962);
    -webkit-animation: fall-42 13s -27s linear infinite;
            animation: fall-42 13s -27s linear infinite;
}

@-webkit-keyframes fall-42 {
    51.008% {
        -webkit-transform: translate(22.4396vw, 51.008vh) scale(0.9962);
                transform: translate(22.4396vw, 51.008vh) scale(0.9962);
    }

    to {
        -webkit-transform: translate(25.4595vw, 100vh) scale(0.9962);
                transform: translate(25.4595vw, 100vh) scale(0.9962);
    }
}

@keyframes fall-42 {
    51.008% {
        -webkit-transform: translate(22.4396vw, 51.008vh) scale(0.9962);
                transform: translate(22.4396vw, 51.008vh) scale(0.9962);
    }

    to {
        -webkit-transform: translate(25.4595vw, 100vh) scale(0.9962);
                transform: translate(25.4595vw, 100vh) scale(0.9962);
    }
}

.snow:nth-child(43) {
    opacity: 0.0989;
    -webkit-transform: translate(5.6525vw, -10px) scale(0.3631);
            transform: translate(5.6525vw, -10px) scale(0.3631);
    -webkit-animation: fall-43 11s -24s linear infinite;
            animation: fall-43 11s -24s linear infinite;
}

@-webkit-keyframes fall-43 {
    51.674% {
        -webkit-transform: translate(-0.0157vw, 51.674vh) scale(0.3631);
                transform: translate(-0.0157vw, 51.674vh) scale(0.3631);
    }

    to {
        -webkit-transform: translate(2.8184vw, 100vh) scale(0.3631);
                transform: translate(2.8184vw, 100vh) scale(0.3631);
    }
}

@keyframes fall-43 {
    51.674% {
        -webkit-transform: translate(-0.0157vw, 51.674vh) scale(0.3631);
                transform: translate(-0.0157vw, 51.674vh) scale(0.3631);
    }

    to {
        -webkit-transform: translate(2.8184vw, 100vh) scale(0.3631);
                transform: translate(2.8184vw, 100vh) scale(0.3631);
    }
}

.snow:nth-child(44) {
    opacity: 0.1407;
    -webkit-transform: translate(29.6934vw, -10px) scale(0.1999);
            transform: translate(29.6934vw, -10px) scale(0.1999);
    -webkit-animation: fall-44 20s -8s linear infinite;
            animation: fall-44 20s -8s linear infinite;
}

@-webkit-keyframes fall-44 {
    43.01% {
        -webkit-transform: translate(24.5525vw, 43.01vh) scale(0.1999);
                transform: translate(24.5525vw, 43.01vh) scale(0.1999);
    }

    to {
        -webkit-transform: translate(27.12295vw, 100vh) scale(0.1999);
                transform: translate(27.12295vw, 100vh) scale(0.1999);
    }
}

@keyframes fall-44 {
    43.01% {
        -webkit-transform: translate(24.5525vw, 43.01vh) scale(0.1999);
                transform: translate(24.5525vw, 43.01vh) scale(0.1999);
    }

    to {
        -webkit-transform: translate(27.12295vw, 100vh) scale(0.1999);
                transform: translate(27.12295vw, 100vh) scale(0.1999);
    }
}

.snow:nth-child(45) {
    opacity: 0.1322;
    -webkit-transform: translate(50.8807vw, -10px) scale(0.9044);
            transform: translate(50.8807vw, -10px) scale(0.9044);
    -webkit-animation: fall-45 15s -23s linear infinite;
            animation: fall-45 15s -23s linear infinite;
}

@-webkit-keyframes fall-45 {
    51.568% {
        -webkit-transform: translate(54.6464vw, 51.568vh) scale(0.9044);
                transform: translate(54.6464vw, 51.568vh) scale(0.9044);
    }

    to {
        -webkit-transform: translate(52.76355vw, 100vh) scale(0.9044);
                transform: translate(52.76355vw, 100vh) scale(0.9044);
    }
}

@keyframes fall-45 {
    51.568% {
        -webkit-transform: translate(54.6464vw, 51.568vh) scale(0.9044);
                transform: translate(54.6464vw, 51.568vh) scale(0.9044);
    }

    to {
        -webkit-transform: translate(52.76355vw, 100vh) scale(0.9044);
                transform: translate(52.76355vw, 100vh) scale(0.9044);
    }
}

.snow:nth-child(46) {
    opacity: 0.5528;
    -webkit-transform: translate(28.2763vw, -10px) scale(0.6962);
            transform: translate(28.2763vw, -10px) scale(0.6962);
    -webkit-animation: fall-46 25s -26s linear infinite;
            animation: fall-46 25s -26s linear infinite;
}

@-webkit-keyframes fall-46 {
    78.418% {
        -webkit-transform: translate(23.2896vw, 78.418vh) scale(0.6962);
                transform: translate(23.2896vw, 78.418vh) scale(0.6962);
    }

    to {
        -webkit-transform: translate(25.78295vw, 100vh) scale(0.6962);
                transform: translate(25.78295vw, 100vh) scale(0.6962);
    }
}

@keyframes fall-46 {
    78.418% {
        -webkit-transform: translate(23.2896vw, 78.418vh) scale(0.6962);
                transform: translate(23.2896vw, 78.418vh) scale(0.6962);
    }

    to {
        -webkit-transform: translate(25.78295vw, 100vh) scale(0.6962);
                transform: translate(25.78295vw, 100vh) scale(0.6962);
    }
}

.snow:nth-child(47) {
    opacity: 0.5709;
    -webkit-transform: translate(28.6696vw, -10px) scale(0.4586);
            transform: translate(28.6696vw, -10px) scale(0.4586);
    -webkit-animation: fall-47 27s -19s linear infinite;
            animation: fall-47 27s -19s linear infinite;
}

@-webkit-keyframes fall-47 {
    58.48% {
        -webkit-transform: translate(35.4292vw, 58.48vh) scale(0.4586);
                transform: translate(35.4292vw, 58.48vh) scale(0.4586);
    }

    to {
        -webkit-transform: translate(32.0494vw, 100vh) scale(0.4586);
                transform: translate(32.0494vw, 100vh) scale(0.4586);
    }
}

@keyframes fall-47 {
    58.48% {
        -webkit-transform: translate(35.4292vw, 58.48vh) scale(0.4586);
                transform: translate(35.4292vw, 58.48vh) scale(0.4586);
    }

    to {
        -webkit-transform: translate(32.0494vw, 100vh) scale(0.4586);
                transform: translate(32.0494vw, 100vh) scale(0.4586);
    }
}

.snow:nth-child(48) {
    opacity: 0.0123;
    -webkit-transform: translate(54.1919vw, -10px) scale(0.0215);
            transform: translate(54.1919vw, -10px) scale(0.0215);
    -webkit-animation: fall-48 29s -25s linear infinite;
            animation: fall-48 29s -25s linear infinite;
}

@-webkit-keyframes fall-48 {
    30.936% {
        -webkit-transform: translate(62.1272vw, 30.936vh) scale(0.0215);
                transform: translate(62.1272vw, 30.936vh) scale(0.0215);
    }

    to {
        -webkit-transform: translate(58.15955vw, 100vh) scale(0.0215);
                transform: translate(58.15955vw, 100vh) scale(0.0215);
    }
}

@keyframes fall-48 {
    30.936% {
        -webkit-transform: translate(62.1272vw, 30.936vh) scale(0.0215);
                transform: translate(62.1272vw, 30.936vh) scale(0.0215);
    }

    to {
        -webkit-transform: translate(58.15955vw, 100vh) scale(0.0215);
                transform: translate(58.15955vw, 100vh) scale(0.0215);
    }
}

.snow:nth-child(49) {
    opacity: 0.5692;
    -webkit-transform: translate(94.0176vw, -10px) scale(0.6759);
            transform: translate(94.0176vw, -10px) scale(0.6759);
    -webkit-animation: fall-49 19s -2s linear infinite;
            animation: fall-49 19s -2s linear infinite;
}

@-webkit-keyframes fall-49 {
    72.373% {
        -webkit-transform: translate(87.1851vw, 72.373vh) scale(0.6759);
                transform: translate(87.1851vw, 72.373vh) scale(0.6759);
    }

    to {
        -webkit-transform: translate(90.60135vw, 100vh) scale(0.6759);
                transform: translate(90.60135vw, 100vh) scale(0.6759);
    }
}

@keyframes fall-49 {
    72.373% {
        -webkit-transform: translate(87.1851vw, 72.373vh) scale(0.6759);
                transform: translate(87.1851vw, 72.373vh) scale(0.6759);
    }

    to {
        -webkit-transform: translate(90.60135vw, 100vh) scale(0.6759);
                transform: translate(90.60135vw, 100vh) scale(0.6759);
    }
}

.snow:nth-child(50) {
    opacity: 0.6581;
    -webkit-transform: translate(76.6352vw, -10px) scale(0.2075);
            transform: translate(76.6352vw, -10px) scale(0.2075);
    -webkit-animation: fall-50 18s -7s linear infinite;
            animation: fall-50 18s -7s linear infinite;
}

@-webkit-keyframes fall-50 {
    69.339% {
        -webkit-transform: translate(76.6882vw, 69.339vh) scale(0.2075);
                transform: translate(76.6882vw, 69.339vh) scale(0.2075);
    }

    to {
        -webkit-transform: translate(76.6617vw, 100vh) scale(0.2075);
                transform: translate(76.6617vw, 100vh) scale(0.2075);
    }
}

@keyframes fall-50 {
    69.339% {
        -webkit-transform: translate(76.6882vw, 69.339vh) scale(0.2075);
                transform: translate(76.6882vw, 69.339vh) scale(0.2075);
    }

    to {
        -webkit-transform: translate(76.6617vw, 100vh) scale(0.2075);
                transform: translate(76.6617vw, 100vh) scale(0.2075);
    }
}

.snow:nth-child(51) {
    opacity: 0.3049;
    -webkit-transform: translate(6.1095vw, -10px) scale(0.5599);
            transform: translate(6.1095vw, -10px) scale(0.5599);
    -webkit-animation: fall-51 29s -20s linear infinite;
            animation: fall-51 29s -20s linear infinite;
}

@-webkit-keyframes fall-51 {
    79.724% {
        -webkit-transform: translate(15.8711vw, 79.724vh) scale(0.5599);
                transform: translate(15.8711vw, 79.724vh) scale(0.5599);
    }

    to {
        -webkit-transform: translate(10.9903vw, 100vh) scale(0.5599);
                transform: translate(10.9903vw, 100vh) scale(0.5599);
    }
}

@keyframes fall-51 {
    79.724% {
        -webkit-transform: translate(15.8711vw, 79.724vh) scale(0.5599);
                transform: translate(15.8711vw, 79.724vh) scale(0.5599);
    }

    to {
        -webkit-transform: translate(10.9903vw, 100vh) scale(0.5599);
                transform: translate(10.9903vw, 100vh) scale(0.5599);
    }
}

.snow:nth-child(52) {
    opacity: 0.4077;
    -webkit-transform: translate(67.0559vw, -10px) scale(0.5377);
            transform: translate(67.0559vw, -10px) scale(0.5377);
    -webkit-animation: fall-52 15s -20s linear infinite;
            animation: fall-52 15s -20s linear infinite;
}

@-webkit-keyframes fall-52 {
    74.792% {
        -webkit-transform: translate(73.3527vw, 74.792vh) scale(0.5377);
                transform: translate(73.3527vw, 74.792vh) scale(0.5377);
    }

    to {
        -webkit-transform: translate(70.2043vw, 100vh) scale(0.5377);
                transform: translate(70.2043vw, 100vh) scale(0.5377);
    }
}

@keyframes fall-52 {
    74.792% {
        -webkit-transform: translate(73.3527vw, 74.792vh) scale(0.5377);
                transform: translate(73.3527vw, 74.792vh) scale(0.5377);
    }

    to {
        -webkit-transform: translate(70.2043vw, 100vh) scale(0.5377);
                transform: translate(70.2043vw, 100vh) scale(0.5377);
    }
}

.snow:nth-child(53) {
    opacity: 0.1545;
    -webkit-transform: translate(91.7714vw, -10px) scale(0.2725);
            transform: translate(91.7714vw, -10px) scale(0.2725);
    -webkit-animation: fall-53 30s -18s linear infinite;
            animation: fall-53 30s -18s linear infinite;
}

@-webkit-keyframes fall-53 {
    48.398% {
        -webkit-transform: translate(82.6575vw, 48.398vh) scale(0.2725);
                transform: translate(82.6575vw, 48.398vh) scale(0.2725);
    }

    to {
        -webkit-transform: translate(87.21445vw, 100vh) scale(0.2725);
                transform: translate(87.21445vw, 100vh) scale(0.2725);
    }
}

@keyframes fall-53 {
    48.398% {
        -webkit-transform: translate(82.6575vw, 48.398vh) scale(0.2725);
                transform: translate(82.6575vw, 48.398vh) scale(0.2725);
    }

    to {
        -webkit-transform: translate(87.21445vw, 100vh) scale(0.2725);
                transform: translate(87.21445vw, 100vh) scale(0.2725);
    }
}

.snow:nth-child(54) {
    opacity: 0.7219;
    -webkit-transform: translate(7.5636vw, -10px) scale(0.4801);
            transform: translate(7.5636vw, -10px) scale(0.4801);
    -webkit-animation: fall-54 17s -20s linear infinite;
            animation: fall-54 17s -20s linear infinite;
}

@-webkit-keyframes fall-54 {
    58.785% {
        -webkit-transform: translate(2.1401vw, 58.785vh) scale(0.4801);
                transform: translate(2.1401vw, 58.785vh) scale(0.4801);
    }

    to {
        -webkit-transform: translate(4.85185vw, 100vh) scale(0.4801);
                transform: translate(4.85185vw, 100vh) scale(0.4801);
    }
}

@keyframes fall-54 {
    58.785% {
        -webkit-transform: translate(2.1401vw, 58.785vh) scale(0.4801);
                transform: translate(2.1401vw, 58.785vh) scale(0.4801);
    }

    to {
        -webkit-transform: translate(4.85185vw, 100vh) scale(0.4801);
                transform: translate(4.85185vw, 100vh) scale(0.4801);
    }
}

.snow:nth-child(55) {
    opacity: 0.3697;
    -webkit-transform: translate(55.5572vw, -10px) scale(0.8132);
            transform: translate(55.5572vw, -10px) scale(0.8132);
    -webkit-animation: fall-55 26s -10s linear infinite;
            animation: fall-55 26s -10s linear infinite;
}

@-webkit-keyframes fall-55 {
    35.531% {
        -webkit-transform: translate(51.1585vw, 35.531vh) scale(0.8132);
                transform: translate(51.1585vw, 35.531vh) scale(0.8132);
    }

    to {
        -webkit-transform: translate(53.35785vw, 100vh) scale(0.8132);
                transform: translate(53.35785vw, 100vh) scale(0.8132);
    }
}

@keyframes fall-55 {
    35.531% {
        -webkit-transform: translate(51.1585vw, 35.531vh) scale(0.8132);
                transform: translate(51.1585vw, 35.531vh) scale(0.8132);
    }

    to {
        -webkit-transform: translate(53.35785vw, 100vh) scale(0.8132);
                transform: translate(53.35785vw, 100vh) scale(0.8132);
    }
}

.snow:nth-child(56) {
    opacity: 0.9959;
    -webkit-transform: translate(4.2913vw, -10px) scale(0.4411);
            transform: translate(4.2913vw, -10px) scale(0.4411);
    -webkit-animation: fall-56 30s -1s linear infinite;
            animation: fall-56 30s -1s linear infinite;
}

@-webkit-keyframes fall-56 {
    66.494% {
        -webkit-transform: translate(0.0078vw, 66.494vh) scale(0.4411);
                transform: translate(0.0078vw, 66.494vh) scale(0.4411);
    }

    to {
        -webkit-transform: translate(2.14955vw, 100vh) scale(0.4411);
                transform: translate(2.14955vw, 100vh) scale(0.4411);
    }
}

@keyframes fall-56 {
    66.494% {
        -webkit-transform: translate(0.0078vw, 66.494vh) scale(0.4411);
                transform: translate(0.0078vw, 66.494vh) scale(0.4411);
    }

    to {
        -webkit-transform: translate(2.14955vw, 100vh) scale(0.4411);
                transform: translate(2.14955vw, 100vh) scale(0.4411);
    }
}

.snow:nth-child(57) {
    opacity: 0.945;
    -webkit-transform: translate(26.9265vw, -10px) scale(0.4433);
            transform: translate(26.9265vw, -10px) scale(0.4433);
    -webkit-animation: fall-57 19s -25s linear infinite;
            animation: fall-57 19s -25s linear infinite;
}

@-webkit-keyframes fall-57 {
    54.941% {
        -webkit-transform: translate(35.172vw, 54.941vh) scale(0.4433);
                transform: translate(35.172vw, 54.941vh) scale(0.4433);
    }

    to {
        -webkit-transform: translate(31.04925vw, 100vh) scale(0.4433);
                transform: translate(31.04925vw, 100vh) scale(0.4433);
    }
}

@keyframes fall-57 {
    54.941% {
        -webkit-transform: translate(35.172vw, 54.941vh) scale(0.4433);
                transform: translate(35.172vw, 54.941vh) scale(0.4433);
    }

    to {
        -webkit-transform: translate(31.04925vw, 100vh) scale(0.4433);
                transform: translate(31.04925vw, 100vh) scale(0.4433);
    }
}

.snow:nth-child(58) {
    opacity: 0.8782;
    -webkit-transform: translate(29.4454vw, -10px) scale(0.6609);
            transform: translate(29.4454vw, -10px) scale(0.6609);
    -webkit-animation: fall-58 20s -14s linear infinite;
            animation: fall-58 20s -14s linear infinite;
}

@-webkit-keyframes fall-58 {
    51.521% {
        -webkit-transform: translate(37.8231vw, 51.521vh) scale(0.6609);
                transform: translate(37.8231vw, 51.521vh) scale(0.6609);
    }

    to {
        -webkit-transform: translate(33.63425vw, 100vh) scale(0.6609);
                transform: translate(33.63425vw, 100vh) scale(0.6609);
    }
}

@keyframes fall-58 {
    51.521% {
        -webkit-transform: translate(37.8231vw, 51.521vh) scale(0.6609);
                transform: translate(37.8231vw, 51.521vh) scale(0.6609);
    }

    to {
        -webkit-transform: translate(33.63425vw, 100vh) scale(0.6609);
                transform: translate(33.63425vw, 100vh) scale(0.6609);
    }
}

.snow:nth-child(59) {
    opacity: 0.2758;
    -webkit-transform: translate(93.0782vw, -10px) scale(0.2595);
            transform: translate(93.0782vw, -10px) scale(0.2595);
    -webkit-animation: fall-59 24s -1s linear infinite;
            animation: fall-59 24s -1s linear infinite;
}

@-webkit-keyframes fall-59 {
    77.755% {
        -webkit-transform: translate(84.3806vw, 77.755vh) scale(0.2595);
                transform: translate(84.3806vw, 77.755vh) scale(0.2595);
    }

    to {
        -webkit-transform: translate(88.7294vw, 100vh) scale(0.2595);
                transform: translate(88.7294vw, 100vh) scale(0.2595);
    }
}

@keyframes fall-59 {
    77.755% {
        -webkit-transform: translate(84.3806vw, 77.755vh) scale(0.2595);
                transform: translate(84.3806vw, 77.755vh) scale(0.2595);
    }

    to {
        -webkit-transform: translate(88.7294vw, 100vh) scale(0.2595);
                transform: translate(88.7294vw, 100vh) scale(0.2595);
    }
}

.snow:nth-child(60) {
    opacity: 0.4505;
    -webkit-transform: translate(84.9747vw, -10px) scale(0.8634);
            transform: translate(84.9747vw, -10px) scale(0.8634);
    -webkit-animation: fall-60 13s -4s linear infinite;
            animation: fall-60 13s -4s linear infinite;
}

@-webkit-keyframes fall-60 {
    48.148% {
        -webkit-transform: translate(82.335vw, 48.148vh) scale(0.8634);
                transform: translate(82.335vw, 48.148vh) scale(0.8634);
    }

    to {
        -webkit-transform: translate(83.65485vw, 100vh) scale(0.8634);
                transform: translate(83.65485vw, 100vh) scale(0.8634);
    }
}

@keyframes fall-60 {
    48.148% {
        -webkit-transform: translate(82.335vw, 48.148vh) scale(0.8634);
                transform: translate(82.335vw, 48.148vh) scale(0.8634);
    }

    to {
        -webkit-transform: translate(83.65485vw, 100vh) scale(0.8634);
                transform: translate(83.65485vw, 100vh) scale(0.8634);
    }
}

.snow:nth-child(61) {
    opacity: 0.9788;
    -webkit-transform: translate(87.3856vw, -10px) scale(0.9127);
            transform: translate(87.3856vw, -10px) scale(0.9127);
    -webkit-animation: fall-61 10s -18s linear infinite;
            animation: fall-61 10s -18s linear infinite;
}

@-webkit-keyframes fall-61 {
    37.516% {
        -webkit-transform: translate(77.9433vw, 37.516vh) scale(0.9127);
                transform: translate(77.9433vw, 37.516vh) scale(0.9127);
    }

    to {
        -webkit-transform: translate(82.66445vw, 100vh) scale(0.9127);
                transform: translate(82.66445vw, 100vh) scale(0.9127);
    }
}

@keyframes fall-61 {
    37.516% {
        -webkit-transform: translate(77.9433vw, 37.516vh) scale(0.9127);
                transform: translate(77.9433vw, 37.516vh) scale(0.9127);
    }

    to {
        -webkit-transform: translate(82.66445vw, 100vh) scale(0.9127);
                transform: translate(82.66445vw, 100vh) scale(0.9127);
    }
}

.snow:nth-child(62) {
    opacity: 0.7848;
    -webkit-transform: translate(12.8691vw, -10px) scale(0.5894);
            transform: translate(12.8691vw, -10px) scale(0.5894);
    -webkit-animation: fall-62 11s -26s linear infinite;
            animation: fall-62 11s -26s linear infinite;
}

@-webkit-keyframes fall-62 {
    47.245% {
        -webkit-transform: translate(12.6654vw, 47.245vh) scale(0.5894);
                transform: translate(12.6654vw, 47.245vh) scale(0.5894);
    }

    to {
        -webkit-transform: translate(12.76725vw, 100vh) scale(0.5894);
                transform: translate(12.76725vw, 100vh) scale(0.5894);
    }
}

@keyframes fall-62 {
    47.245% {
        -webkit-transform: translate(12.6654vw, 47.245vh) scale(0.5894);
                transform: translate(12.6654vw, 47.245vh) scale(0.5894);
    }

    to {
        -webkit-transform: translate(12.76725vw, 100vh) scale(0.5894);
                transform: translate(12.76725vw, 100vh) scale(0.5894);
    }
}

.snow:nth-child(63) {
    opacity: 0.1219;
    -webkit-transform: translate(31.5252vw, -10px) scale(0.9301);
            transform: translate(31.5252vw, -10px) scale(0.9301);
    -webkit-animation: fall-63 10s -22s linear infinite;
            animation: fall-63 10s -22s linear infinite;
}

@-webkit-keyframes fall-63 {
    46.92% {
        -webkit-transform: translate(35.433vw, 46.92vh) scale(0.9301);
                transform: translate(35.433vw, 46.92vh) scale(0.9301);
    }

    to {
        -webkit-transform: translate(33.4791vw, 100vh) scale(0.9301);
                transform: translate(33.4791vw, 100vh) scale(0.9301);
    }
}

@keyframes fall-63 {
    46.92% {
        -webkit-transform: translate(35.433vw, 46.92vh) scale(0.9301);
                transform: translate(35.433vw, 46.92vh) scale(0.9301);
    }

    to {
        -webkit-transform: translate(33.4791vw, 100vh) scale(0.9301);
                transform: translate(33.4791vw, 100vh) scale(0.9301);
    }
}

.snow:nth-child(64) {
    opacity: 0.1471;
    -webkit-transform: translate(28.6068vw, -10px) scale(0.255);
            transform: translate(28.6068vw, -10px) scale(0.255);
    -webkit-animation: fall-64 11s -1s linear infinite;
            animation: fall-64 11s -1s linear infinite;
}

@-webkit-keyframes fall-64 {
    35.771% {
        -webkit-transform: translate(31.1247vw, 35.771vh) scale(0.255);
                transform: translate(31.1247vw, 35.771vh) scale(0.255);
    }

    to {
        -webkit-transform: translate(29.86575vw, 100vh) scale(0.255);
                transform: translate(29.86575vw, 100vh) scale(0.255);
    }
}

@keyframes fall-64 {
    35.771% {
        -webkit-transform: translate(31.1247vw, 35.771vh) scale(0.255);
                transform: translate(31.1247vw, 35.771vh) scale(0.255);
    }

    to {
        -webkit-transform: translate(29.86575vw, 100vh) scale(0.255);
                transform: translate(29.86575vw, 100vh) scale(0.255);
    }
}

.snow:nth-child(65) {
    opacity: 0.7727;
    -webkit-transform: translate(86.1814vw, -10px) scale(0.6233);
            transform: translate(86.1814vw, -10px) scale(0.6233);
    -webkit-animation: fall-65 27s -20s linear infinite;
            animation: fall-65 27s -20s linear infinite;
}

@-webkit-keyframes fall-65 {
    34.2% {
        -webkit-transform: translate(90.3357vw, 34.2vh) scale(0.6233);
                transform: translate(90.3357vw, 34.2vh) scale(0.6233);
    }

    to {
        -webkit-transform: translate(88.25855vw, 100vh) scale(0.6233);
                transform: translate(88.25855vw, 100vh) scale(0.6233);
    }
}

@keyframes fall-65 {
    34.2% {
        -webkit-transform: translate(90.3357vw, 34.2vh) scale(0.6233);
                transform: translate(90.3357vw, 34.2vh) scale(0.6233);
    }

    to {
        -webkit-transform: translate(88.25855vw, 100vh) scale(0.6233);
                transform: translate(88.25855vw, 100vh) scale(0.6233);
    }
}

.snow:nth-child(66) {
    opacity: 0.8526;
    -webkit-transform: translate(12.7827vw, -10px) scale(0.1554);
            transform: translate(12.7827vw, -10px) scale(0.1554);
    -webkit-animation: fall-66 10s -8s linear infinite;
            animation: fall-66 10s -8s linear infinite;
}

@-webkit-keyframes fall-66 {
    51.59% {
        -webkit-transform: translate(3.5461vw, 51.59vh) scale(0.1554);
                transform: translate(3.5461vw, 51.59vh) scale(0.1554);
    }

    to {
        -webkit-transform: translate(8.1644vw, 100vh) scale(0.1554);
                transform: translate(8.1644vw, 100vh) scale(0.1554);
    }
}

@keyframes fall-66 {
    51.59% {
        -webkit-transform: translate(3.5461vw, 51.59vh) scale(0.1554);
                transform: translate(3.5461vw, 51.59vh) scale(0.1554);
    }

    to {
        -webkit-transform: translate(8.1644vw, 100vh) scale(0.1554);
                transform: translate(8.1644vw, 100vh) scale(0.1554);
    }
}

.snow:nth-child(67) {
    opacity: 0.9071;
    -webkit-transform: translate(51.4261vw, -10px) scale(0.6482);
            transform: translate(51.4261vw, -10px) scale(0.6482);
    -webkit-animation: fall-67 27s -18s linear infinite;
            animation: fall-67 27s -18s linear infinite;
}

@-webkit-keyframes fall-67 {
    77.726% {
        -webkit-transform: translate(61.1346vw, 77.726vh) scale(0.6482);
                transform: translate(61.1346vw, 77.726vh) scale(0.6482);
    }

    to {
        -webkit-transform: translate(56.28035vw, 100vh) scale(0.6482);
                transform: translate(56.28035vw, 100vh) scale(0.6482);
    }
}

@keyframes fall-67 {
    77.726% {
        -webkit-transform: translate(61.1346vw, 77.726vh) scale(0.6482);
                transform: translate(61.1346vw, 77.726vh) scale(0.6482);
    }

    to {
        -webkit-transform: translate(56.28035vw, 100vh) scale(0.6482);
                transform: translate(56.28035vw, 100vh) scale(0.6482);
    }
}

.snow:nth-child(68) {
    opacity: 0.3011;
    -webkit-transform: translate(48.9336vw, -10px) scale(0.7733);
            transform: translate(48.9336vw, -10px) scale(0.7733);
    -webkit-animation: fall-68 15s -21s linear infinite;
            animation: fall-68 15s -21s linear infinite;
}

@-webkit-keyframes fall-68 {
    78.29% {
        -webkit-transform: translate(53.9435vw, 78.29vh) scale(0.7733);
                transform: translate(53.9435vw, 78.29vh) scale(0.7733);
    }

    to {
        -webkit-transform: translate(51.43855vw, 100vh) scale(0.7733);
                transform: translate(51.43855vw, 100vh) scale(0.7733);
    }
}

@keyframes fall-68 {
    78.29% {
        -webkit-transform: translate(53.9435vw, 78.29vh) scale(0.7733);
                transform: translate(53.9435vw, 78.29vh) scale(0.7733);
    }

    to {
        -webkit-transform: translate(51.43855vw, 100vh) scale(0.7733);
                transform: translate(51.43855vw, 100vh) scale(0.7733);
    }
}

.snow:nth-child(69) {
    opacity: 0.9846;
    -webkit-transform: translate(32.4285vw, -10px) scale(0.3238);
            transform: translate(32.4285vw, -10px) scale(0.3238);
    -webkit-animation: fall-69 23s -15s linear infinite;
            animation: fall-69 23s -15s linear infinite;
}

@-webkit-keyframes fall-69 {
    38.929% {
        -webkit-transform: translate(41.6404vw, 38.929vh) scale(0.3238);
                transform: translate(41.6404vw, 38.929vh) scale(0.3238);
    }

    to {
        -webkit-transform: translate(37.03445vw, 100vh) scale(0.3238);
                transform: translate(37.03445vw, 100vh) scale(0.3238);
    }
}

@keyframes fall-69 {
    38.929% {
        -webkit-transform: translate(41.6404vw, 38.929vh) scale(0.3238);
                transform: translate(41.6404vw, 38.929vh) scale(0.3238);
    }

    to {
        -webkit-transform: translate(37.03445vw, 100vh) scale(0.3238);
                transform: translate(37.03445vw, 100vh) scale(0.3238);
    }
}

.snow:nth-child(70) {
    opacity: 0.7826;
    -webkit-transform: translate(69.3389vw, -10px) scale(0.3651);
            transform: translate(69.3389vw, -10px) scale(0.3651);
    -webkit-animation: fall-70 30s -2s linear infinite;
            animation: fall-70 30s -2s linear infinite;
}

@-webkit-keyframes fall-70 {
    45.302% {
        -webkit-transform: translate(77.396vw, 45.302vh) scale(0.3651);
                transform: translate(77.396vw, 45.302vh) scale(0.3651);
    }

    to {
        -webkit-transform: translate(73.36745vw, 100vh) scale(0.3651);
                transform: translate(73.36745vw, 100vh) scale(0.3651);
    }
}

@keyframes fall-70 {
    45.302% {
        -webkit-transform: translate(77.396vw, 45.302vh) scale(0.3651);
                transform: translate(77.396vw, 45.302vh) scale(0.3651);
    }

    to {
        -webkit-transform: translate(73.36745vw, 100vh) scale(0.3651);
                transform: translate(73.36745vw, 100vh) scale(0.3651);
    }
}

.snow:nth-child(71) {
    opacity: 0.4522;
    -webkit-transform: translate(7.3605vw, -10px) scale(0.846);
            transform: translate(7.3605vw, -10px) scale(0.846);
    -webkit-animation: fall-71 15s -1s linear infinite;
            animation: fall-71 15s -1s linear infinite;
}

@-webkit-keyframes fall-71 {
    38.111% {
        -webkit-transform: translate(13.4665vw, 38.111vh) scale(0.846);
                transform: translate(13.4665vw, 38.111vh) scale(0.846);
    }

    to {
        -webkit-transform: translate(10.4135vw, 100vh) scale(0.846);
                transform: translate(10.4135vw, 100vh) scale(0.846);
    }
}

@keyframes fall-71 {
    38.111% {
        -webkit-transform: translate(13.4665vw, 38.111vh) scale(0.846);
                transform: translate(13.4665vw, 38.111vh) scale(0.846);
    }

    to {
        -webkit-transform: translate(10.4135vw, 100vh) scale(0.846);
                transform: translate(10.4135vw, 100vh) scale(0.846);
    }
}

.snow:nth-child(72) {
    opacity: 0.6453;
    -webkit-transform: translate(3.9572vw, -10px) scale(0.9382);
            transform: translate(3.9572vw, -10px) scale(0.9382);
    -webkit-animation: fall-72 25s -18s linear infinite;
            animation: fall-72 25s -18s linear infinite;
}

@-webkit-keyframes fall-72 {
    60.648% {
        -webkit-transform: translate(-0.2483vw, 60.648vh) scale(0.9382);
                transform: translate(-0.2483vw, 60.648vh) scale(0.9382);
    }

    to {
        -webkit-transform: translate(1.85445vw, 100vh) scale(0.9382);
                transform: translate(1.85445vw, 100vh) scale(0.9382);
    }
}

@keyframes fall-72 {
    60.648% {
        -webkit-transform: translate(-0.2483vw, 60.648vh) scale(0.9382);
                transform: translate(-0.2483vw, 60.648vh) scale(0.9382);
    }

    to {
        -webkit-transform: translate(1.85445vw, 100vh) scale(0.9382);
                transform: translate(1.85445vw, 100vh) scale(0.9382);
    }
}

.snow:nth-child(73) {
    opacity: 0.7538;
    -webkit-transform: translate(98.2618vw, -10px) scale(0.1946);
            transform: translate(98.2618vw, -10px) scale(0.1946);
    -webkit-animation: fall-73 28s -17s linear infinite;
            animation: fall-73 28s -17s linear infinite;
}

@-webkit-keyframes fall-73 {
    40.069% {
        -webkit-transform: translate(93.3069vw, 40.069vh) scale(0.1946);
                transform: translate(93.3069vw, 40.069vh) scale(0.1946);
    }

    to {
        -webkit-transform: translate(95.78435vw, 100vh) scale(0.1946);
                transform: translate(95.78435vw, 100vh) scale(0.1946);
    }
}

@keyframes fall-73 {
    40.069% {
        -webkit-transform: translate(93.3069vw, 40.069vh) scale(0.1946);
                transform: translate(93.3069vw, 40.069vh) scale(0.1946);
    }

    to {
        -webkit-transform: translate(95.78435vw, 100vh) scale(0.1946);
                transform: translate(95.78435vw, 100vh) scale(0.1946);
    }
}

.snow:nth-child(74) {
    opacity: 0.3863;
    -webkit-transform: translate(24.9924vw, -10px) scale(0.1511);
            transform: translate(24.9924vw, -10px) scale(0.1511);
    -webkit-animation: fall-74 13s -17s linear infinite;
            animation: fall-74 13s -17s linear infinite;
}

@-webkit-keyframes fall-74 {
    55.027% {
        -webkit-transform: translate(25.7795vw, 55.027vh) scale(0.1511);
                transform: translate(25.7795vw, 55.027vh) scale(0.1511);
    }

    to {
        -webkit-transform: translate(25.38595vw, 100vh) scale(0.1511);
                transform: translate(25.38595vw, 100vh) scale(0.1511);
    }
}

@keyframes fall-74 {
    55.027% {
        -webkit-transform: translate(25.7795vw, 55.027vh) scale(0.1511);
                transform: translate(25.7795vw, 55.027vh) scale(0.1511);
    }

    to {
        -webkit-transform: translate(25.38595vw, 100vh) scale(0.1511);
                transform: translate(25.38595vw, 100vh) scale(0.1511);
    }
}

.snow:nth-child(75) {
    opacity: 0.4426;
    -webkit-transform: translate(83.1915vw, -10px) scale(0.0251);
            transform: translate(83.1915vw, -10px) scale(0.0251);
    -webkit-animation: fall-75 29s -10s linear infinite;
            animation: fall-75 29s -10s linear infinite;
}

@-webkit-keyframes fall-75 {
    34.871% {
        -webkit-transform: translate(83.9515vw, 34.871vh) scale(0.0251);
                transform: translate(83.9515vw, 34.871vh) scale(0.0251);
    }

    to {
        -webkit-transform: translate(83.5715vw, 100vh) scale(0.0251);
                transform: translate(83.5715vw, 100vh) scale(0.0251);
    }
}

@keyframes fall-75 {
    34.871% {
        -webkit-transform: translate(83.9515vw, 34.871vh) scale(0.0251);
                transform: translate(83.9515vw, 34.871vh) scale(0.0251);
    }

    to {
        -webkit-transform: translate(83.5715vw, 100vh) scale(0.0251);
                transform: translate(83.5715vw, 100vh) scale(0.0251);
    }
}

.snow:nth-child(76) {
    opacity: 0.0145;
    -webkit-transform: translate(65.8376vw, -10px) scale(0.4142);
            transform: translate(65.8376vw, -10px) scale(0.4142);
    -webkit-animation: fall-76 19s -5s linear infinite;
            animation: fall-76 19s -5s linear infinite;
}

@-webkit-keyframes fall-76 {
    42.066% {
        -webkit-transform: translate(71.3586vw, 42.066vh) scale(0.4142);
                transform: translate(71.3586vw, 42.066vh) scale(0.4142);
    }

    to {
        -webkit-transform: translate(68.5981vw, 100vh) scale(0.4142);
                transform: translate(68.5981vw, 100vh) scale(0.4142);
    }
}

@keyframes fall-76 {
    42.066% {
        -webkit-transform: translate(71.3586vw, 42.066vh) scale(0.4142);
                transform: translate(71.3586vw, 42.066vh) scale(0.4142);
    }

    to {
        -webkit-transform: translate(68.5981vw, 100vh) scale(0.4142);
                transform: translate(68.5981vw, 100vh) scale(0.4142);
    }
}

.snow:nth-child(77) {
    opacity: 0.9394;
    -webkit-transform: translate(84.1874vw, -10px) scale(0.7422);
            transform: translate(84.1874vw, -10px) scale(0.7422);
    -webkit-animation: fall-77 12s -18s linear infinite;
            animation: fall-77 12s -18s linear infinite;
}

@-webkit-keyframes fall-77 {
    56% {
        -webkit-transform: translate(86.5149vw, 56vh) scale(0.7422);
                transform: translate(86.5149vw, 56vh) scale(0.7422);
    }

    to {
        -webkit-transform: translate(85.35115vw, 100vh) scale(0.7422);
                transform: translate(85.35115vw, 100vh) scale(0.7422);
    }
}

@keyframes fall-77 {
    56% {
        -webkit-transform: translate(86.5149vw, 56vh) scale(0.7422);
                transform: translate(86.5149vw, 56vh) scale(0.7422);
    }

    to {
        -webkit-transform: translate(85.35115vw, 100vh) scale(0.7422);
                transform: translate(85.35115vw, 100vh) scale(0.7422);
    }
}

.snow:nth-child(78) {
    opacity: 0.284;
    -webkit-transform: translate(96.3179vw, -10px) scale(0.0557);
            transform: translate(96.3179vw, -10px) scale(0.0557);
    -webkit-animation: fall-78 25s -28s linear infinite;
            animation: fall-78 25s -28s linear infinite;
}

@-webkit-keyframes fall-78 {
    76.87% {
        -webkit-transform: translate(95.2308vw, 76.87vh) scale(0.0557);
                transform: translate(95.2308vw, 76.87vh) scale(0.0557);
    }

    to {
        -webkit-transform: translate(95.77435vw, 100vh) scale(0.0557);
                transform: translate(95.77435vw, 100vh) scale(0.0557);
    }
}

@keyframes fall-78 {
    76.87% {
        -webkit-transform: translate(95.2308vw, 76.87vh) scale(0.0557);
                transform: translate(95.2308vw, 76.87vh) scale(0.0557);
    }

    to {
        -webkit-transform: translate(95.77435vw, 100vh) scale(0.0557);
                transform: translate(95.77435vw, 100vh) scale(0.0557);
    }
}

.snow:nth-child(79) {
    opacity: 0.6252;
    -webkit-transform: translate(6.6979vw, -10px) scale(0.9642);
            transform: translate(6.6979vw, -10px) scale(0.9642);
    -webkit-animation: fall-79 11s -3s linear infinite;
            animation: fall-79 11s -3s linear infinite;
}

@-webkit-keyframes fall-79 {
    51.048% {
        -webkit-transform: translate(14.7084vw, 51.048vh) scale(0.9642);
                transform: translate(14.7084vw, 51.048vh) scale(0.9642);
    }

    to {
        -webkit-transform: translate(10.70315vw, 100vh) scale(0.9642);
                transform: translate(10.70315vw, 100vh) scale(0.9642);
    }
}

@keyframes fall-79 {
    51.048% {
        -webkit-transform: translate(14.7084vw, 51.048vh) scale(0.9642);
                transform: translate(14.7084vw, 51.048vh) scale(0.9642);
    }

    to {
        -webkit-transform: translate(10.70315vw, 100vh) scale(0.9642);
                transform: translate(10.70315vw, 100vh) scale(0.9642);
    }
}

.snow:nth-child(80) {
    opacity: 0.9144;
    -webkit-transform: translate(40.411vw, -10px) scale(0.2372);
            transform: translate(40.411vw, -10px) scale(0.2372);
    -webkit-animation: fall-80 30s -21s linear infinite;
            animation: fall-80 30s -21s linear infinite;
}

@-webkit-keyframes fall-80 {
    33.834% {
        -webkit-transform: translate(37.4425vw, 33.834vh) scale(0.2372);
                transform: translate(37.4425vw, 33.834vh) scale(0.2372);
    }

    to {
        -webkit-transform: translate(38.92675vw, 100vh) scale(0.2372);
                transform: translate(38.92675vw, 100vh) scale(0.2372);
    }
}

@keyframes fall-80 {
    33.834% {
        -webkit-transform: translate(37.4425vw, 33.834vh) scale(0.2372);
                transform: translate(37.4425vw, 33.834vh) scale(0.2372);
    }

    to {
        -webkit-transform: translate(38.92675vw, 100vh) scale(0.2372);
                transform: translate(38.92675vw, 100vh) scale(0.2372);
    }
}

.snow:nth-child(81) {
    opacity: 0.0358;
    -webkit-transform: translate(12.6521vw, -10px) scale(0.9705);
            transform: translate(12.6521vw, -10px) scale(0.9705);
    -webkit-animation: fall-81 30s -3s linear infinite;
            animation: fall-81 30s -3s linear infinite;
}

@-webkit-keyframes fall-81 {
    61.055% {
        -webkit-transform: translate(18.9085vw, 61.055vh) scale(0.9705);
                transform: translate(18.9085vw, 61.055vh) scale(0.9705);
    }

    to {
        -webkit-transform: translate(15.7803vw, 100vh) scale(0.9705);
                transform: translate(15.7803vw, 100vh) scale(0.9705);
    }
}

@keyframes fall-81 {
    61.055% {
        -webkit-transform: translate(18.9085vw, 61.055vh) scale(0.9705);
                transform: translate(18.9085vw, 61.055vh) scale(0.9705);
    }

    to {
        -webkit-transform: translate(15.7803vw, 100vh) scale(0.9705);
                transform: translate(15.7803vw, 100vh) scale(0.9705);
    }
}

.snow:nth-child(82) {
    opacity: 0.8052;
    -webkit-transform: translate(79.9388vw, -10px) scale(0.4759);
            transform: translate(79.9388vw, -10px) scale(0.4759);
    -webkit-animation: fall-82 30s -7s linear infinite;
            animation: fall-82 30s -7s linear infinite;
}

@-webkit-keyframes fall-82 {
    54.447% {
        -webkit-transform: translate(73.8486vw, 54.447vh) scale(0.4759);
                transform: translate(73.8486vw, 54.447vh) scale(0.4759);
    }

    to {
        -webkit-transform: translate(76.8937vw, 100vh) scale(0.4759);
                transform: translate(76.8937vw, 100vh) scale(0.4759);
    }
}

@keyframes fall-82 {
    54.447% {
        -webkit-transform: translate(73.8486vw, 54.447vh) scale(0.4759);
                transform: translate(73.8486vw, 54.447vh) scale(0.4759);
    }

    to {
        -webkit-transform: translate(76.8937vw, 100vh) scale(0.4759);
                transform: translate(76.8937vw, 100vh) scale(0.4759);
    }
}

.snow:nth-child(83) {
    opacity: 0.0649;
    -webkit-transform: translate(50.1089vw, -10px) scale(0.2798);
            transform: translate(50.1089vw, -10px) scale(0.2798);
    -webkit-animation: fall-83 27s -5s linear infinite;
            animation: fall-83 27s -5s linear infinite;
}

@-webkit-keyframes fall-83 {
    48.032% {
        -webkit-transform: translate(52.1227vw, 48.032vh) scale(0.2798);
                transform: translate(52.1227vw, 48.032vh) scale(0.2798);
    }

    to {
        -webkit-transform: translate(51.1158vw, 100vh) scale(0.2798);
                transform: translate(51.1158vw, 100vh) scale(0.2798);
    }
}

@keyframes fall-83 {
    48.032% {
        -webkit-transform: translate(52.1227vw, 48.032vh) scale(0.2798);
                transform: translate(52.1227vw, 48.032vh) scale(0.2798);
    }

    to {
        -webkit-transform: translate(51.1158vw, 100vh) scale(0.2798);
                transform: translate(51.1158vw, 100vh) scale(0.2798);
    }
}

.snow:nth-child(84) {
    opacity: 0.296;
    -webkit-transform: translate(55.115vw, -10px) scale(0.884);
            transform: translate(55.115vw, -10px) scale(0.884);
    -webkit-animation: fall-84 23s -15s linear infinite;
            animation: fall-84 23s -15s linear infinite;
}

@-webkit-keyframes fall-84 {
    44.328% {
        -webkit-transform: translate(46.2321vw, 44.328vh) scale(0.884);
                transform: translate(46.2321vw, 44.328vh) scale(0.884);
    }

    to {
        -webkit-transform: translate(50.67355vw, 100vh) scale(0.884);
                transform: translate(50.67355vw, 100vh) scale(0.884);
    }
}

@keyframes fall-84 {
    44.328% {
        -webkit-transform: translate(46.2321vw, 44.328vh) scale(0.884);
                transform: translate(46.2321vw, 44.328vh) scale(0.884);
    }

    to {
        -webkit-transform: translate(50.67355vw, 100vh) scale(0.884);
                transform: translate(50.67355vw, 100vh) scale(0.884);
    }
}

.snow:nth-child(85) {
    opacity: 0.1966;
    -webkit-transform: translate(15.6599vw, -10px) scale(0.3549);
            transform: translate(15.6599vw, -10px) scale(0.3549);
    -webkit-animation: fall-85 18s -20s linear infinite;
            animation: fall-85 18s -20s linear infinite;
}

@-webkit-keyframes fall-85 {
    76.494% {
        -webkit-transform: translate(20.3936vw, 76.494vh) scale(0.3549);
                transform: translate(20.3936vw, 76.494vh) scale(0.3549);
    }

    to {
        -webkit-transform: translate(18.02675vw, 100vh) scale(0.3549);
                transform: translate(18.02675vw, 100vh) scale(0.3549);
    }
}

@keyframes fall-85 {
    76.494% {
        -webkit-transform: translate(20.3936vw, 76.494vh) scale(0.3549);
                transform: translate(20.3936vw, 76.494vh) scale(0.3549);
    }

    to {
        -webkit-transform: translate(18.02675vw, 100vh) scale(0.3549);
                transform: translate(18.02675vw, 100vh) scale(0.3549);
    }
}

.snow:nth-child(86) {
    opacity: 0.333;
    -webkit-transform: translate(29.194vw, -10px) scale(0.1055);
            transform: translate(29.194vw, -10px) scale(0.1055);
    -webkit-animation: fall-86 27s -19s linear infinite;
            animation: fall-86 27s -19s linear infinite;
}

@-webkit-keyframes fall-86 {
    65.83% {
        -webkit-transform: translate(23.8525vw, 65.83vh) scale(0.1055);
                transform: translate(23.8525vw, 65.83vh) scale(0.1055);
    }

    to {
        -webkit-transform: translate(26.52325vw, 100vh) scale(0.1055);
                transform: translate(26.52325vw, 100vh) scale(0.1055);
    }
}

@keyframes fall-86 {
    65.83% {
        -webkit-transform: translate(23.8525vw, 65.83vh) scale(0.1055);
                transform: translate(23.8525vw, 65.83vh) scale(0.1055);
    }

    to {
        -webkit-transform: translate(26.52325vw, 100vh) scale(0.1055);
                transform: translate(26.52325vw, 100vh) scale(0.1055);
    }
}

.snow:nth-child(87) {
    opacity: 0.9776;
    -webkit-transform: translate(74.9454vw, -10px) scale(0.4586);
            transform: translate(74.9454vw, -10px) scale(0.4586);
    -webkit-animation: fall-87 26s -21s linear infinite;
            animation: fall-87 26s -21s linear infinite;
}

@-webkit-keyframes fall-87 {
    54.939% {
        -webkit-transform: translate(71.4916vw, 54.939vh) scale(0.4586);
                transform: translate(71.4916vw, 54.939vh) scale(0.4586);
    }

    to {
        -webkit-transform: translate(73.2185vw, 100vh) scale(0.4586);
                transform: translate(73.2185vw, 100vh) scale(0.4586);
    }
}

@keyframes fall-87 {
    54.939% {
        -webkit-transform: translate(71.4916vw, 54.939vh) scale(0.4586);
                transform: translate(71.4916vw, 54.939vh) scale(0.4586);
    }

    to {
        -webkit-transform: translate(73.2185vw, 100vh) scale(0.4586);
                transform: translate(73.2185vw, 100vh) scale(0.4586);
    }
}

.snow:nth-child(88) {
    opacity: 0.2781;
    -webkit-transform: translate(89.7726vw, -10px) scale(0.0931);
            transform: translate(89.7726vw, -10px) scale(0.0931);
    -webkit-animation: fall-88 10s -23s linear infinite;
            animation: fall-88 10s -23s linear infinite;
}

@-webkit-keyframes fall-88 {
    62.573% {
        -webkit-transform: translate(90.3326vw, 62.573vh) scale(0.0931);
                transform: translate(90.3326vw, 62.573vh) scale(0.0931);
    }

    to {
        -webkit-transform: translate(90.0526vw, 100vh) scale(0.0931);
                transform: translate(90.0526vw, 100vh) scale(0.0931);
    }
}

@keyframes fall-88 {
    62.573% {
        -webkit-transform: translate(90.3326vw, 62.573vh) scale(0.0931);
                transform: translate(90.3326vw, 62.573vh) scale(0.0931);
    }

    to {
        -webkit-transform: translate(90.0526vw, 100vh) scale(0.0931);
                transform: translate(90.0526vw, 100vh) scale(0.0931);
    }
}

.snow:nth-child(89) {
    opacity: 0.3411;
    -webkit-transform: translate(98.0784vw, -10px) scale(0.1677);
            transform: translate(98.0784vw, -10px) scale(0.1677);
    -webkit-animation: fall-89 19s -6s linear infinite;
            animation: fall-89 19s -6s linear infinite;
}

@-webkit-keyframes fall-89 {
    57.792% {
        -webkit-transform: translate(96.3754vw, 57.792vh) scale(0.1677);
                transform: translate(96.3754vw, 57.792vh) scale(0.1677);
    }

    to {
        -webkit-transform: translate(97.2269vw, 100vh) scale(0.1677);
                transform: translate(97.2269vw, 100vh) scale(0.1677);
    }
}

@keyframes fall-89 {
    57.792% {
        -webkit-transform: translate(96.3754vw, 57.792vh) scale(0.1677);
                transform: translate(96.3754vw, 57.792vh) scale(0.1677);
    }

    to {
        -webkit-transform: translate(97.2269vw, 100vh) scale(0.1677);
                transform: translate(97.2269vw, 100vh) scale(0.1677);
    }
}

.snow:nth-child(90) {
    opacity: 0.1266;
    -webkit-transform: translate(58.7641vw, -10px) scale(0.5378);
            transform: translate(58.7641vw, -10px) scale(0.5378);
    -webkit-animation: fall-90 10s -21s linear infinite;
            animation: fall-90 10s -21s linear infinite;
}

@-webkit-keyframes fall-90 {
    58.657% {
        -webkit-transform: translate(54.9218vw, 58.657vh) scale(0.5378);
                transform: translate(54.9218vw, 58.657vh) scale(0.5378);
    }

    to {
        -webkit-transform: translate(56.84295vw, 100vh) scale(0.5378);
                transform: translate(56.84295vw, 100vh) scale(0.5378);
    }
}

@keyframes fall-90 {
    58.657% {
        -webkit-transform: translate(54.9218vw, 58.657vh) scale(0.5378);
                transform: translate(54.9218vw, 58.657vh) scale(0.5378);
    }

    to {
        -webkit-transform: translate(56.84295vw, 100vh) scale(0.5378);
                transform: translate(56.84295vw, 100vh) scale(0.5378);
    }
}

.snow:nth-child(91) {
    opacity: 0.7332;
    -webkit-transform: translate(34.8021vw, -10px) scale(0.1424);
            transform: translate(34.8021vw, -10px) scale(0.1424);
    -webkit-animation: fall-91 10s -25s linear infinite;
            animation: fall-91 10s -25s linear infinite;
}

@-webkit-keyframes fall-91 {
    65.366% {
        -webkit-transform: translate(30.9961vw, 65.366vh) scale(0.1424);
                transform: translate(30.9961vw, 65.366vh) scale(0.1424);
    }

    to {
        -webkit-transform: translate(32.8991vw, 100vh) scale(0.1424);
                transform: translate(32.8991vw, 100vh) scale(0.1424);
    }
}

@keyframes fall-91 {
    65.366% {
        -webkit-transform: translate(30.9961vw, 65.366vh) scale(0.1424);
                transform: translate(30.9961vw, 65.366vh) scale(0.1424);
    }

    to {
        -webkit-transform: translate(32.8991vw, 100vh) scale(0.1424);
                transform: translate(32.8991vw, 100vh) scale(0.1424);
    }
}

.snow:nth-child(92) {
    opacity: 0.821;
    -webkit-transform: translate(60.6393vw, -10px) scale(0.4063);
            transform: translate(60.6393vw, -10px) scale(0.4063);
    -webkit-animation: fall-92 13s -11s linear infinite;
            animation: fall-92 13s -11s linear infinite;
}

@-webkit-keyframes fall-92 {
    59.488% {
        -webkit-transform: translate(64.783vw, 59.488vh) scale(0.4063);
                transform: translate(64.783vw, 59.488vh) scale(0.4063);
    }

    to {
        -webkit-transform: translate(62.71115vw, 100vh) scale(0.4063);
                transform: translate(62.71115vw, 100vh) scale(0.4063);
    }
}

@keyframes fall-92 {
    59.488% {
        -webkit-transform: translate(64.783vw, 59.488vh) scale(0.4063);
                transform: translate(64.783vw, 59.488vh) scale(0.4063);
    }

    to {
        -webkit-transform: translate(62.71115vw, 100vh) scale(0.4063);
                transform: translate(62.71115vw, 100vh) scale(0.4063);
    }
}

.snow:nth-child(93) {
    opacity: 0.8918;
    -webkit-transform: translate(38.4759vw, -10px) scale(0.4204);
            transform: translate(38.4759vw, -10px) scale(0.4204);
    -webkit-animation: fall-93 29s -19s linear infinite;
            animation: fall-93 29s -19s linear infinite;
}

@-webkit-keyframes fall-93 {
    72.45% {
        -webkit-transform: translate(45.3372vw, 72.45vh) scale(0.4204);
                transform: translate(45.3372vw, 72.45vh) scale(0.4204);
    }

    to {
        -webkit-transform: translate(41.90655vw, 100vh) scale(0.4204);
                transform: translate(41.90655vw, 100vh) scale(0.4204);
    }
}

@keyframes fall-93 {
    72.45% {
        -webkit-transform: translate(45.3372vw, 72.45vh) scale(0.4204);
                transform: translate(45.3372vw, 72.45vh) scale(0.4204);
    }

    to {
        -webkit-transform: translate(41.90655vw, 100vh) scale(0.4204);
                transform: translate(41.90655vw, 100vh) scale(0.4204);
    }
}

.snow:nth-child(94) {
    opacity: 0.5736;
    -webkit-transform: translate(88.0496vw, -10px) scale(0.6999);
            transform: translate(88.0496vw, -10px) scale(0.6999);
    -webkit-animation: fall-94 28s -17s linear infinite;
            animation: fall-94 28s -17s linear infinite;
}

@-webkit-keyframes fall-94 {
    44.477% {
        -webkit-transform: translate(80.2747vw, 44.477vh) scale(0.6999);
                transform: translate(80.2747vw, 44.477vh) scale(0.6999);
    }

    to {
        -webkit-transform: translate(84.16215vw, 100vh) scale(0.6999);
                transform: translate(84.16215vw, 100vh) scale(0.6999);
    }
}

@keyframes fall-94 {
    44.477% {
        -webkit-transform: translate(80.2747vw, 44.477vh) scale(0.6999);
                transform: translate(80.2747vw, 44.477vh) scale(0.6999);
    }

    to {
        -webkit-transform: translate(84.16215vw, 100vh) scale(0.6999);
                transform: translate(84.16215vw, 100vh) scale(0.6999);
    }
}

.snow:nth-child(95) {
    opacity: 0.7911;
    -webkit-transform: translate(28.7287vw, -10px) scale(0.0545);
            transform: translate(28.7287vw, -10px) scale(0.0545);
    -webkit-animation: fall-95 29s -13s linear infinite;
            animation: fall-95 29s -13s linear infinite;
}

@-webkit-keyframes fall-95 {
    54.614% {
        -webkit-transform: translate(26.3124vw, 54.614vh) scale(0.0545);
                transform: translate(26.3124vw, 54.614vh) scale(0.0545);
    }

    to {
        -webkit-transform: translate(27.52055vw, 100vh) scale(0.0545);
                transform: translate(27.52055vw, 100vh) scale(0.0545);
    }
}

@keyframes fall-95 {
    54.614% {
        -webkit-transform: translate(26.3124vw, 54.614vh) scale(0.0545);
                transform: translate(26.3124vw, 54.614vh) scale(0.0545);
    }

    to {
        -webkit-transform: translate(27.52055vw, 100vh) scale(0.0545);
                transform: translate(27.52055vw, 100vh) scale(0.0545);
    }
}

.snow:nth-child(96) {
    opacity: 0.6064;
    -webkit-transform: translate(26.8946vw, -10px) scale(0.6142);
            transform: translate(26.8946vw, -10px) scale(0.6142);
    -webkit-animation: fall-96 30s -24s linear infinite;
            animation: fall-96 30s -24s linear infinite;
}

@-webkit-keyframes fall-96 {
    66.914% {
        -webkit-transform: translate(22.3333vw, 66.914vh) scale(0.6142);
                transform: translate(22.3333vw, 66.914vh) scale(0.6142);
    }

    to {
        -webkit-transform: translate(24.61395vw, 100vh) scale(0.6142);
                transform: translate(24.61395vw, 100vh) scale(0.6142);
    }
}

@keyframes fall-96 {
    66.914% {
        -webkit-transform: translate(22.3333vw, 66.914vh) scale(0.6142);
                transform: translate(22.3333vw, 66.914vh) scale(0.6142);
    }

    to {
        -webkit-transform: translate(24.61395vw, 100vh) scale(0.6142);
                transform: translate(24.61395vw, 100vh) scale(0.6142);
    }
}

.snow:nth-child(97) {
    opacity: 0.8046;
    -webkit-transform: translate(60.6816vw, -10px) scale(0.0218);
            transform: translate(60.6816vw, -10px) scale(0.0218);
    -webkit-animation: fall-97 16s -16s linear infinite;
            animation: fall-97 16s -16s linear infinite;
}

@-webkit-keyframes fall-97 {
    33.227% {
        -webkit-transform: translate(70.0987vw, 33.227vh) scale(0.0218);
                transform: translate(70.0987vw, 33.227vh) scale(0.0218);
    }

    to {
        -webkit-transform: translate(65.39015vw, 100vh) scale(0.0218);
                transform: translate(65.39015vw, 100vh) scale(0.0218);
    }
}

@keyframes fall-97 {
    33.227% {
        -webkit-transform: translate(70.0987vw, 33.227vh) scale(0.0218);
                transform: translate(70.0987vw, 33.227vh) scale(0.0218);
    }

    to {
        -webkit-transform: translate(65.39015vw, 100vh) scale(0.0218);
                transform: translate(65.39015vw, 100vh) scale(0.0218);
    }
}

.snow:nth-child(98) {
    opacity: 0.7982;
    -webkit-transform: translate(14.6926vw, -10px) scale(0.8889);
            transform: translate(14.6926vw, -10px) scale(0.8889);
    -webkit-animation: fall-98 18s -22s linear infinite;
            animation: fall-98 18s -22s linear infinite;
}

@-webkit-keyframes fall-98 {
    48.528% {
        -webkit-transform: translate(21.7178vw, 48.528vh) scale(0.8889);
                transform: translate(21.7178vw, 48.528vh) scale(0.8889);
    }

    to {
        -webkit-transform: translate(18.2052vw, 100vh) scale(0.8889);
                transform: translate(18.2052vw, 100vh) scale(0.8889);
    }
}

@keyframes fall-98 {
    48.528% {
        -webkit-transform: translate(21.7178vw, 48.528vh) scale(0.8889);
                transform: translate(21.7178vw, 48.528vh) scale(0.8889);
    }

    to {
        -webkit-transform: translate(18.2052vw, 100vh) scale(0.8889);
                transform: translate(18.2052vw, 100vh) scale(0.8889);
    }
}

.snow:nth-child(99) {
    opacity: 0.0065;
    -webkit-transform: translate(22.5146vw, -10px) scale(0.4773);
            transform: translate(22.5146vw, -10px) scale(0.4773);
    -webkit-animation: fall-99 15s -25s linear infinite;
            animation: fall-99 15s -25s linear infinite;
}

@-webkit-keyframes fall-99 {
    53.928% {
        -webkit-transform: translate(18.5407vw, 53.928vh) scale(0.4773);
                transform: translate(18.5407vw, 53.928vh) scale(0.4773);
    }

    to {
        -webkit-transform: translate(20.52765vw, 100vh) scale(0.4773);
                transform: translate(20.52765vw, 100vh) scale(0.4773);
    }
}

@keyframes fall-99 {
    53.928% {
        -webkit-transform: translate(18.5407vw, 53.928vh) scale(0.4773);
                transform: translate(18.5407vw, 53.928vh) scale(0.4773);
    }

    to {
        -webkit-transform: translate(20.52765vw, 100vh) scale(0.4773);
                transform: translate(20.52765vw, 100vh) scale(0.4773);
    }
}

.snow:nth-child(100) {
    opacity: 0.4444;
    -webkit-transform: translate(31.527vw, -10px) scale(0.9243);
            transform: translate(31.527vw, -10px) scale(0.9243);
    -webkit-animation: fall-100 11s -5s linear infinite;
            animation: fall-100 11s -5s linear infinite;
}

@-webkit-keyframes fall-100 {
    51.299% {
        -webkit-transform: translate(23.9366vw, 51.299vh) scale(0.9243);
                transform: translate(23.9366vw, 51.299vh) scale(0.9243);
    }

    to {
        -webkit-transform: translate(27.7318vw, 100vh) scale(0.9243);
                transform: translate(27.7318vw, 100vh) scale(0.9243);
    }
}

@keyframes fall-100 {
    51.299% {
        -webkit-transform: translate(23.9366vw, 51.299vh) scale(0.9243);
                transform: translate(23.9366vw, 51.299vh) scale(0.9243);
    }

    to {
        -webkit-transform: translate(27.7318vw, 100vh) scale(0.9243);
                transform: translate(27.7318vw, 100vh) scale(0.9243);
    }
}

.snow:nth-child(101) {
    opacity: 0.3679;
    -webkit-transform: translate(48.588vw, -10px) scale(0.6813);
            transform: translate(48.588vw, -10px) scale(0.6813);
    -webkit-animation: fall-101 12s -4s linear infinite;
            animation: fall-101 12s -4s linear infinite;
}

@-webkit-keyframes fall-101 {
    60.044% {
        -webkit-transform: translate(50.6339vw, 60.044vh) scale(0.6813);
                transform: translate(50.6339vw, 60.044vh) scale(0.6813);
    }

    to {
        -webkit-transform: translate(49.61095vw, 100vh) scale(0.6813);
                transform: translate(49.61095vw, 100vh) scale(0.6813);
    }
}

@keyframes fall-101 {
    60.044% {
        -webkit-transform: translate(50.6339vw, 60.044vh) scale(0.6813);
                transform: translate(50.6339vw, 60.044vh) scale(0.6813);
    }

    to {
        -webkit-transform: translate(49.61095vw, 100vh) scale(0.6813);
                transform: translate(49.61095vw, 100vh) scale(0.6813);
    }
}

.snow:nth-child(102) {
    opacity: 0.1384;
    -webkit-transform: translate(72.1886vw, -10px) scale(0.4585);
            transform: translate(72.1886vw, -10px) scale(0.4585);
    -webkit-animation: fall-102 12s -14s linear infinite;
            animation: fall-102 12s -14s linear infinite;
}

@-webkit-keyframes fall-102 {
    41.381% {
        -webkit-transform: translate(71.6902vw, 41.381vh) scale(0.4585);
                transform: translate(71.6902vw, 41.381vh) scale(0.4585);
    }

    to {
        -webkit-transform: translate(71.9394vw, 100vh) scale(0.4585);
                transform: translate(71.9394vw, 100vh) scale(0.4585);
    }
}

@keyframes fall-102 {
    41.381% {
        -webkit-transform: translate(71.6902vw, 41.381vh) scale(0.4585);
                transform: translate(71.6902vw, 41.381vh) scale(0.4585);
    }

    to {
        -webkit-transform: translate(71.9394vw, 100vh) scale(0.4585);
                transform: translate(71.9394vw, 100vh) scale(0.4585);
    }
}

.snow:nth-child(103) {
    opacity: 0.532;
    -webkit-transform: translate(32.0388vw, -10px) scale(0.0676);
            transform: translate(32.0388vw, -10px) scale(0.0676);
    -webkit-animation: fall-103 18s -15s linear infinite;
            animation: fall-103 18s -15s linear infinite;
}

@-webkit-keyframes fall-103 {
    73.162% {
        -webkit-transform: translate(24.2015vw, 73.162vh) scale(0.0676);
                transform: translate(24.2015vw, 73.162vh) scale(0.0676);
    }

    to {
        -webkit-transform: translate(28.12015vw, 100vh) scale(0.0676);
                transform: translate(28.12015vw, 100vh) scale(0.0676);
    }
}

@keyframes fall-103 {
    73.162% {
        -webkit-transform: translate(24.2015vw, 73.162vh) scale(0.0676);
                transform: translate(24.2015vw, 73.162vh) scale(0.0676);
    }

    to {
        -webkit-transform: translate(28.12015vw, 100vh) scale(0.0676);
                transform: translate(28.12015vw, 100vh) scale(0.0676);
    }
}

.snow:nth-child(104) {
    opacity: 0.2116;
    -webkit-transform: translate(89.1928vw, -10px) scale(0.0513);
            transform: translate(89.1928vw, -10px) scale(0.0513);
    -webkit-animation: fall-104 29s -3s linear infinite;
            animation: fall-104 29s -3s linear infinite;
}

@-webkit-keyframes fall-104 {
    33.224% {
        -webkit-transform: translate(80.3268vw, 33.224vh) scale(0.0513);
                transform: translate(80.3268vw, 33.224vh) scale(0.0513);
    }

    to {
        -webkit-transform: translate(84.7598vw, 100vh) scale(0.0513);
                transform: translate(84.7598vw, 100vh) scale(0.0513);
    }
}

@keyframes fall-104 {
    33.224% {
        -webkit-transform: translate(80.3268vw, 33.224vh) scale(0.0513);
                transform: translate(80.3268vw, 33.224vh) scale(0.0513);
    }

    to {
        -webkit-transform: translate(84.7598vw, 100vh) scale(0.0513);
                transform: translate(84.7598vw, 100vh) scale(0.0513);
    }
}

.snow:nth-child(105) {
    opacity: 0.1949;
    -webkit-transform: translate(19.9681vw, -10px) scale(0.9725);
            transform: translate(19.9681vw, -10px) scale(0.9725);
    -webkit-animation: fall-105 26s -12s linear infinite;
            animation: fall-105 26s -12s linear infinite;
}

@-webkit-keyframes fall-105 {
    54.878% {
        -webkit-transform: translate(27.4442vw, 54.878vh) scale(0.9725);
                transform: translate(27.4442vw, 54.878vh) scale(0.9725);
    }

    to {
        -webkit-transform: translate(23.70615vw, 100vh) scale(0.9725);
                transform: translate(23.70615vw, 100vh) scale(0.9725);
    }
}

@keyframes fall-105 {
    54.878% {
        -webkit-transform: translate(27.4442vw, 54.878vh) scale(0.9725);
                transform: translate(27.4442vw, 54.878vh) scale(0.9725);
    }

    to {
        -webkit-transform: translate(23.70615vw, 100vh) scale(0.9725);
                transform: translate(23.70615vw, 100vh) scale(0.9725);
    }
}

.snow:nth-child(106) {
    opacity: 0.7174;
    -webkit-transform: translate(38.3156vw, -10px) scale(0.4109);
            transform: translate(38.3156vw, -10px) scale(0.4109);
    -webkit-animation: fall-106 18s -8s linear infinite;
            animation: fall-106 18s -8s linear infinite;
}

@-webkit-keyframes fall-106 {
    69.17% {
        -webkit-transform: translate(43.9189vw, 69.17vh) scale(0.4109);
                transform: translate(43.9189vw, 69.17vh) scale(0.4109);
    }

    to {
        -webkit-transform: translate(41.11725vw, 100vh) scale(0.4109);
                transform: translate(41.11725vw, 100vh) scale(0.4109);
    }
}

@keyframes fall-106 {
    69.17% {
        -webkit-transform: translate(43.9189vw, 69.17vh) scale(0.4109);
                transform: translate(43.9189vw, 69.17vh) scale(0.4109);
    }

    to {
        -webkit-transform: translate(41.11725vw, 100vh) scale(0.4109);
                transform: translate(41.11725vw, 100vh) scale(0.4109);
    }
}

.snow:nth-child(107) {
    opacity: 0.5868;
    -webkit-transform: translate(46.971vw, -10px) scale(0.6453);
            transform: translate(46.971vw, -10px) scale(0.6453);
    -webkit-animation: fall-107 25s -25s linear infinite;
            animation: fall-107 25s -25s linear infinite;
}

@-webkit-keyframes fall-107 {
    53.297% {
        -webkit-transform: translate(43.0753vw, 53.297vh) scale(0.6453);
                transform: translate(43.0753vw, 53.297vh) scale(0.6453);
    }

    to {
        -webkit-transform: translate(45.02315vw, 100vh) scale(0.6453);
                transform: translate(45.02315vw, 100vh) scale(0.6453);
    }
}

@keyframes fall-107 {
    53.297% {
        -webkit-transform: translate(43.0753vw, 53.297vh) scale(0.6453);
                transform: translate(43.0753vw, 53.297vh) scale(0.6453);
    }

    to {
        -webkit-transform: translate(45.02315vw, 100vh) scale(0.6453);
                transform: translate(45.02315vw, 100vh) scale(0.6453);
    }
}

.snow:nth-child(108) {
    opacity: 0.4316;
    -webkit-transform: translate(30.6227vw, -10px) scale(0.8366);
            transform: translate(30.6227vw, -10px) scale(0.8366);
    -webkit-animation: fall-108 11s -12s linear infinite;
            animation: fall-108 11s -12s linear infinite;
}

@-webkit-keyframes fall-108 {
    56.587% {
        -webkit-transform: translate(31.9175vw, 56.587vh) scale(0.8366);
                transform: translate(31.9175vw, 56.587vh) scale(0.8366);
    }

    to {
        -webkit-transform: translate(31.2701vw, 100vh) scale(0.8366);
                transform: translate(31.2701vw, 100vh) scale(0.8366);
    }
}

@keyframes fall-108 {
    56.587% {
        -webkit-transform: translate(31.9175vw, 56.587vh) scale(0.8366);
                transform: translate(31.9175vw, 56.587vh) scale(0.8366);
    }

    to {
        -webkit-transform: translate(31.2701vw, 100vh) scale(0.8366);
                transform: translate(31.2701vw, 100vh) scale(0.8366);
    }
}

.snow:nth-child(109) {
    opacity: 0.9455;
    -webkit-transform: translate(18.2677vw, -10px) scale(0.8011);
            transform: translate(18.2677vw, -10px) scale(0.8011);
    -webkit-animation: fall-109 30s -17s linear infinite;
            animation: fall-109 30s -17s linear infinite;
}

@-webkit-keyframes fall-109 {
    58.144% {
        -webkit-transform: translate(18.146vw, 58.144vh) scale(0.8011);
                transform: translate(18.146vw, 58.144vh) scale(0.8011);
    }

    to {
        -webkit-transform: translate(18.20685vw, 100vh) scale(0.8011);
                transform: translate(18.20685vw, 100vh) scale(0.8011);
    }
}

@keyframes fall-109 {
    58.144% {
        -webkit-transform: translate(18.146vw, 58.144vh) scale(0.8011);
                transform: translate(18.146vw, 58.144vh) scale(0.8011);
    }

    to {
        -webkit-transform: translate(18.20685vw, 100vh) scale(0.8011);
                transform: translate(18.20685vw, 100vh) scale(0.8011);
    }
}

.snow:nth-child(110) {
    opacity: 0.7562;
    -webkit-transform: translate(26.8463vw, -10px) scale(0.5023);
            transform: translate(26.8463vw, -10px) scale(0.5023);
    -webkit-animation: fall-110 11s -28s linear infinite;
            animation: fall-110 11s -28s linear infinite;
}

@-webkit-keyframes fall-110 {
    54.863% {
        -webkit-transform: translate(23.1987vw, 54.863vh) scale(0.5023);
                transform: translate(23.1987vw, 54.863vh) scale(0.5023);
    }

    to {
        -webkit-transform: translate(25.0225vw, 100vh) scale(0.5023);
                transform: translate(25.0225vw, 100vh) scale(0.5023);
    }
}

@keyframes fall-110 {
    54.863% {
        -webkit-transform: translate(23.1987vw, 54.863vh) scale(0.5023);
                transform: translate(23.1987vw, 54.863vh) scale(0.5023);
    }

    to {
        -webkit-transform: translate(25.0225vw, 100vh) scale(0.5023);
                transform: translate(25.0225vw, 100vh) scale(0.5023);
    }
}

.snow:nth-child(111) {
    opacity: 0.6497;
    -webkit-transform: translate(20.9134vw, -10px) scale(0.4358);
            transform: translate(20.9134vw, -10px) scale(0.4358);
    -webkit-animation: fall-111 30s -24s linear infinite;
            animation: fall-111 30s -24s linear infinite;
}

@-webkit-keyframes fall-111 {
    49.299% {
        -webkit-transform: translate(22.841vw, 49.299vh) scale(0.4358);
                transform: translate(22.841vw, 49.299vh) scale(0.4358);
    }

    to {
        -webkit-transform: translate(21.8772vw, 100vh) scale(0.4358);
                transform: translate(21.8772vw, 100vh) scale(0.4358);
    }
}

@keyframes fall-111 {
    49.299% {
        -webkit-transform: translate(22.841vw, 49.299vh) scale(0.4358);
                transform: translate(22.841vw, 49.299vh) scale(0.4358);
    }

    to {
        -webkit-transform: translate(21.8772vw, 100vh) scale(0.4358);
                transform: translate(21.8772vw, 100vh) scale(0.4358);
    }
}

.snow:nth-child(112) {
    opacity: 0.4945;
    -webkit-transform: translate(81.8294vw, -10px) scale(0.0497);
            transform: translate(81.8294vw, -10px) scale(0.0497);
    -webkit-animation: fall-112 19s -14s linear infinite;
            animation: fall-112 19s -14s linear infinite;
}

@-webkit-keyframes fall-112 {
    64.628% {
        -webkit-transform: translate(84.6183vw, 64.628vh) scale(0.0497);
                transform: translate(84.6183vw, 64.628vh) scale(0.0497);
    }

    to {
        -webkit-transform: translate(83.22385vw, 100vh) scale(0.0497);
                transform: translate(83.22385vw, 100vh) scale(0.0497);
    }
}

@keyframes fall-112 {
    64.628% {
        -webkit-transform: translate(84.6183vw, 64.628vh) scale(0.0497);
                transform: translate(84.6183vw, 64.628vh) scale(0.0497);
    }

    to {
        -webkit-transform: translate(83.22385vw, 100vh) scale(0.0497);
                transform: translate(83.22385vw, 100vh) scale(0.0497);
    }
}

.snow:nth-child(113) {
    opacity: 0.0946;
    -webkit-transform: translate(96.2974vw, -10px) scale(0.0372);
            transform: translate(96.2974vw, -10px) scale(0.0372);
    -webkit-animation: fall-113 15s -10s linear infinite;
            animation: fall-113 15s -10s linear infinite;
}

@-webkit-keyframes fall-113 {
    58.574% {
        -webkit-transform: translate(93.3828vw, 58.574vh) scale(0.0372);
                transform: translate(93.3828vw, 58.574vh) scale(0.0372);
    }

    to {
        -webkit-transform: translate(94.8401vw, 100vh) scale(0.0372);
                transform: translate(94.8401vw, 100vh) scale(0.0372);
    }
}

@keyframes fall-113 {
    58.574% {
        -webkit-transform: translate(93.3828vw, 58.574vh) scale(0.0372);
                transform: translate(93.3828vw, 58.574vh) scale(0.0372);
    }

    to {
        -webkit-transform: translate(94.8401vw, 100vh) scale(0.0372);
                transform: translate(94.8401vw, 100vh) scale(0.0372);
    }
}

.snow:nth-child(114) {
    opacity: 0.2968;
    -webkit-transform: translate(55.579vw, -10px) scale(0.3632);
            transform: translate(55.579vw, -10px) scale(0.3632);
    -webkit-animation: fall-114 17s -27s linear infinite;
            animation: fall-114 17s -27s linear infinite;
}

@-webkit-keyframes fall-114 {
    45.544% {
        -webkit-transform: translate(58.137vw, 45.544vh) scale(0.3632);
                transform: translate(58.137vw, 45.544vh) scale(0.3632);
    }

    to {
        -webkit-transform: translate(56.858vw, 100vh) scale(0.3632);
                transform: translate(56.858vw, 100vh) scale(0.3632);
    }
}

@keyframes fall-114 {
    45.544% {
        -webkit-transform: translate(58.137vw, 45.544vh) scale(0.3632);
                transform: translate(58.137vw, 45.544vh) scale(0.3632);
    }

    to {
        -webkit-transform: translate(56.858vw, 100vh) scale(0.3632);
                transform: translate(56.858vw, 100vh) scale(0.3632);
    }
}

.snow:nth-child(115) {
    opacity: 0.634;
    -webkit-transform: translate(46.201vw, -10px) scale(0.3388);
            transform: translate(46.201vw, -10px) scale(0.3388);
    -webkit-animation: fall-115 23s -5s linear infinite;
            animation: fall-115 23s -5s linear infinite;
}

@-webkit-keyframes fall-115 {
    75.83% {
        -webkit-transform: translate(45.5779vw, 75.83vh) scale(0.3388);
                transform: translate(45.5779vw, 75.83vh) scale(0.3388);
    }

    to {
        -webkit-transform: translate(45.88945vw, 100vh) scale(0.3388);
                transform: translate(45.88945vw, 100vh) scale(0.3388);
    }
}

@keyframes fall-115 {
    75.83% {
        -webkit-transform: translate(45.5779vw, 75.83vh) scale(0.3388);
                transform: translate(45.5779vw, 75.83vh) scale(0.3388);
    }

    to {
        -webkit-transform: translate(45.88945vw, 100vh) scale(0.3388);
                transform: translate(45.88945vw, 100vh) scale(0.3388);
    }
}

.snow:nth-child(116) {
    opacity: 0.2255;
    -webkit-transform: translate(85.5345vw, -10px) scale(0.2956);
            transform: translate(85.5345vw, -10px) scale(0.2956);
    -webkit-animation: fall-116 27s -10s linear infinite;
            animation: fall-116 27s -10s linear infinite;
}

@-webkit-keyframes fall-116 {
    68.892% {
        -webkit-transform: translate(78.3845vw, 68.892vh) scale(0.2956);
                transform: translate(78.3845vw, 68.892vh) scale(0.2956);
    }

    to {
        -webkit-transform: translate(81.9595vw, 100vh) scale(0.2956);
                transform: translate(81.9595vw, 100vh) scale(0.2956);
    }
}

@keyframes fall-116 {
    68.892% {
        -webkit-transform: translate(78.3845vw, 68.892vh) scale(0.2956);
                transform: translate(78.3845vw, 68.892vh) scale(0.2956);
    }

    to {
        -webkit-transform: translate(81.9595vw, 100vh) scale(0.2956);
                transform: translate(81.9595vw, 100vh) scale(0.2956);
    }
}

.snow:nth-child(117) {
    opacity: 0.7588;
    -webkit-transform: translate(72.3845vw, -10px) scale(0.8558);
            transform: translate(72.3845vw, -10px) scale(0.8558);
    -webkit-animation: fall-117 29s -19s linear infinite;
            animation: fall-117 29s -19s linear infinite;
}

@-webkit-keyframes fall-117 {
    68.155% {
        -webkit-transform: translate(68.8982vw, 68.155vh) scale(0.8558);
                transform: translate(68.8982vw, 68.155vh) scale(0.8558);
    }

    to {
        -webkit-transform: translate(70.64135vw, 100vh) scale(0.8558);
                transform: translate(70.64135vw, 100vh) scale(0.8558);
    }
}

@keyframes fall-117 {
    68.155% {
        -webkit-transform: translate(68.8982vw, 68.155vh) scale(0.8558);
                transform: translate(68.8982vw, 68.155vh) scale(0.8558);
    }

    to {
        -webkit-transform: translate(70.64135vw, 100vh) scale(0.8558);
                transform: translate(70.64135vw, 100vh) scale(0.8558);
    }
}

.snow:nth-child(118) {
    opacity: 0.4243;
    -webkit-transform: translate(28.0405vw, -10px) scale(0.0234);
            transform: translate(28.0405vw, -10px) scale(0.0234);
    -webkit-animation: fall-118 27s -6s linear infinite;
            animation: fall-118 27s -6s linear infinite;
}

@-webkit-keyframes fall-118 {
    56.411% {
        -webkit-transform: translate(36.1339vw, 56.411vh) scale(0.0234);
                transform: translate(36.1339vw, 56.411vh) scale(0.0234);
    }

    to {
        -webkit-transform: translate(32.0872vw, 100vh) scale(0.0234);
                transform: translate(32.0872vw, 100vh) scale(0.0234);
    }
}

@keyframes fall-118 {
    56.411% {
        -webkit-transform: translate(36.1339vw, 56.411vh) scale(0.0234);
                transform: translate(36.1339vw, 56.411vh) scale(0.0234);
    }

    to {
        -webkit-transform: translate(32.0872vw, 100vh) scale(0.0234);
                transform: translate(32.0872vw, 100vh) scale(0.0234);
    }
}

.snow:nth-child(119) {
    opacity: 0.6637;
    -webkit-transform: translate(91.6046vw, -10px) scale(0.6183);
            transform: translate(91.6046vw, -10px) scale(0.6183);
    -webkit-animation: fall-119 21s -15s linear infinite;
            animation: fall-119 21s -15s linear infinite;
}

@-webkit-keyframes fall-119 {
    53.704% {
        -webkit-transform: translate(83.6222vw, 53.704vh) scale(0.6183);
                transform: translate(83.6222vw, 53.704vh) scale(0.6183);
    }

    to {
        -webkit-transform: translate(87.6134vw, 100vh) scale(0.6183);
                transform: translate(87.6134vw, 100vh) scale(0.6183);
    }
}

@keyframes fall-119 {
    53.704% {
        -webkit-transform: translate(83.6222vw, 53.704vh) scale(0.6183);
                transform: translate(83.6222vw, 53.704vh) scale(0.6183);
    }

    to {
        -webkit-transform: translate(87.6134vw, 100vh) scale(0.6183);
                transform: translate(87.6134vw, 100vh) scale(0.6183);
    }
}

.snow:nth-child(120) {
    opacity: 0.0048;
    -webkit-transform: translate(86.2396vw, -10px) scale(0.5522);
            transform: translate(86.2396vw, -10px) scale(0.5522);
    -webkit-animation: fall-120 18s -1s linear infinite;
            animation: fall-120 18s -1s linear infinite;
}

@-webkit-keyframes fall-120 {
    39.164% {
        -webkit-transform: translate(85.1508vw, 39.164vh) scale(0.5522);
                transform: translate(85.1508vw, 39.164vh) scale(0.5522);
    }

    to {
        -webkit-transform: translate(85.6952vw, 100vh) scale(0.5522);
                transform: translate(85.6952vw, 100vh) scale(0.5522);
    }
}

@keyframes fall-120 {
    39.164% {
        -webkit-transform: translate(85.1508vw, 39.164vh) scale(0.5522);
                transform: translate(85.1508vw, 39.164vh) scale(0.5522);
    }

    to {
        -webkit-transform: translate(85.6952vw, 100vh) scale(0.5522);
                transform: translate(85.6952vw, 100vh) scale(0.5522);
    }
}

.snow:nth-child(121) {
    opacity: 0.8965;
    -webkit-transform: translate(70.3378vw, -10px) scale(0.2267);
            transform: translate(70.3378vw, -10px) scale(0.2267);
    -webkit-animation: fall-121 17s -6s linear infinite;
            animation: fall-121 17s -6s linear infinite;
}

@-webkit-keyframes fall-121 {
    78.599% {
        -webkit-transform: translate(69.4095vw, 78.599vh) scale(0.2267);
                transform: translate(69.4095vw, 78.599vh) scale(0.2267);
    }

    to {
        -webkit-transform: translate(69.87365vw, 100vh) scale(0.2267);
                transform: translate(69.87365vw, 100vh) scale(0.2267);
    }
}

@keyframes fall-121 {
    78.599% {
        -webkit-transform: translate(69.4095vw, 78.599vh) scale(0.2267);
                transform: translate(69.4095vw, 78.599vh) scale(0.2267);
    }

    to {
        -webkit-transform: translate(69.87365vw, 100vh) scale(0.2267);
                transform: translate(69.87365vw, 100vh) scale(0.2267);
    }
}

.snow:nth-child(122) {
    opacity: 0.265;
    -webkit-transform: translate(50.9885vw, -10px) scale(0.3051);
            transform: translate(50.9885vw, -10px) scale(0.3051);
    -webkit-animation: fall-122 21s -22s linear infinite;
            animation: fall-122 21s -22s linear infinite;
}

@-webkit-keyframes fall-122 {
    57.121% {
        -webkit-transform: translate(57.7897vw, 57.121vh) scale(0.3051);
                transform: translate(57.7897vw, 57.121vh) scale(0.3051);
    }

    to {
        -webkit-transform: translate(54.3891vw, 100vh) scale(0.3051);
                transform: translate(54.3891vw, 100vh) scale(0.3051);
    }
}

@keyframes fall-122 {
    57.121% {
        -webkit-transform: translate(57.7897vw, 57.121vh) scale(0.3051);
                transform: translate(57.7897vw, 57.121vh) scale(0.3051);
    }

    to {
        -webkit-transform: translate(54.3891vw, 100vh) scale(0.3051);
                transform: translate(54.3891vw, 100vh) scale(0.3051);
    }
}

.snow:nth-child(123) {
    opacity: 0.2641;
    -webkit-transform: translate(73.5155vw, -10px) scale(0.0104);
            transform: translate(73.5155vw, -10px) scale(0.0104);
    -webkit-animation: fall-123 13s -21s linear infinite;
            animation: fall-123 13s -21s linear infinite;
}

@-webkit-keyframes fall-123 {
    47.92% {
        -webkit-transform: translate(68.5208vw, 47.92vh) scale(0.0104);
                transform: translate(68.5208vw, 47.92vh) scale(0.0104);
    }

    to {
        -webkit-transform: translate(71.01815vw, 100vh) scale(0.0104);
                transform: translate(71.01815vw, 100vh) scale(0.0104);
    }
}

@keyframes fall-123 {
    47.92% {
        -webkit-transform: translate(68.5208vw, 47.92vh) scale(0.0104);
                transform: translate(68.5208vw, 47.92vh) scale(0.0104);
    }

    to {
        -webkit-transform: translate(71.01815vw, 100vh) scale(0.0104);
                transform: translate(71.01815vw, 100vh) scale(0.0104);
    }
}

.snow:nth-child(124) {
    opacity: 0.8327;
    -webkit-transform: translate(4.0517vw, -10px) scale(0.4919);
            transform: translate(4.0517vw, -10px) scale(0.4919);
    -webkit-animation: fall-124 27s -11s linear infinite;
            animation: fall-124 27s -11s linear infinite;
}

@-webkit-keyframes fall-124 {
    56.189% {
        -webkit-transform: translate(7.999vw, 56.189vh) scale(0.4919);
                transform: translate(7.999vw, 56.189vh) scale(0.4919);
    }

    to {
        -webkit-transform: translate(6.02535vw, 100vh) scale(0.4919);
                transform: translate(6.02535vw, 100vh) scale(0.4919);
    }
}

@keyframes fall-124 {
    56.189% {
        -webkit-transform: translate(7.999vw, 56.189vh) scale(0.4919);
                transform: translate(7.999vw, 56.189vh) scale(0.4919);
    }

    to {
        -webkit-transform: translate(6.02535vw, 100vh) scale(0.4919);
                transform: translate(6.02535vw, 100vh) scale(0.4919);
    }
}

.snow:nth-child(125) {
    opacity: 0.8288;
    -webkit-transform: translate(46.7245vw, -10px) scale(0.0206);
            transform: translate(46.7245vw, -10px) scale(0.0206);
    -webkit-animation: fall-125 29s -4s linear infinite;
            animation: fall-125 29s -4s linear infinite;
}

@-webkit-keyframes fall-125 {
    79.337% {
        -webkit-transform: translate(54.387vw, 79.337vh) scale(0.0206);
                transform: translate(54.387vw, 79.337vh) scale(0.0206);
    }

    to {
        -webkit-transform: translate(50.55575vw, 100vh) scale(0.0206);
                transform: translate(50.55575vw, 100vh) scale(0.0206);
    }
}

@keyframes fall-125 {
    79.337% {
        -webkit-transform: translate(54.387vw, 79.337vh) scale(0.0206);
                transform: translate(54.387vw, 79.337vh) scale(0.0206);
    }

    to {
        -webkit-transform: translate(50.55575vw, 100vh) scale(0.0206);
                transform: translate(50.55575vw, 100vh) scale(0.0206);
    }
}

.snow:nth-child(126) {
    opacity: 0.0886;
    -webkit-transform: translate(91.6466vw, -10px) scale(0.96);
            transform: translate(91.6466vw, -10px) scale(0.96);
    -webkit-animation: fall-126 29s -2s linear infinite;
            animation: fall-126 29s -2s linear infinite;
}

@-webkit-keyframes fall-126 {
    46.387% {
        -webkit-transform: translate(100.7653vw, 46.387vh) scale(0.96);
                transform: translate(100.7653vw, 46.387vh) scale(0.96);
    }

    to {
        -webkit-transform: translate(96.20595vw, 100vh) scale(0.96);
                transform: translate(96.20595vw, 100vh) scale(0.96);
    }
}

@keyframes fall-126 {
    46.387% {
        -webkit-transform: translate(100.7653vw, 46.387vh) scale(0.96);
                transform: translate(100.7653vw, 46.387vh) scale(0.96);
    }

    to {
        -webkit-transform: translate(96.20595vw, 100vh) scale(0.96);
                transform: translate(96.20595vw, 100vh) scale(0.96);
    }
}

.snow:nth-child(127) {
    opacity: 0.2428;
    -webkit-transform: translate(41.9853vw, -10px) scale(0.423);
            transform: translate(41.9853vw, -10px) scale(0.423);
    -webkit-animation: fall-127 11s -6s linear infinite;
            animation: fall-127 11s -6s linear infinite;
}

@-webkit-keyframes fall-127 {
    70.054% {
        -webkit-transform: translate(35.4748vw, 70.054vh) scale(0.423);
                transform: translate(35.4748vw, 70.054vh) scale(0.423);
    }

    to {
        -webkit-transform: translate(38.73005vw, 100vh) scale(0.423);
                transform: translate(38.73005vw, 100vh) scale(0.423);
    }
}

@keyframes fall-127 {
    70.054% {
        -webkit-transform: translate(35.4748vw, 70.054vh) scale(0.423);
                transform: translate(35.4748vw, 70.054vh) scale(0.423);
    }

    to {
        -webkit-transform: translate(38.73005vw, 100vh) scale(0.423);
                transform: translate(38.73005vw, 100vh) scale(0.423);
    }
}

.snow:nth-child(128) {
    opacity: 0.7005;
    -webkit-transform: translate(7.7635vw, -10px) scale(0.4976);
            transform: translate(7.7635vw, -10px) scale(0.4976);
    -webkit-animation: fall-128 18s -19s linear infinite;
            animation: fall-128 18s -19s linear infinite;
}

@-webkit-keyframes fall-128 {
    46.157% {
        -webkit-transform: translate(8.0557vw, 46.157vh) scale(0.4976);
                transform: translate(8.0557vw, 46.157vh) scale(0.4976);
    }

    to {
        -webkit-transform: translate(7.9096vw, 100vh) scale(0.4976);
                transform: translate(7.9096vw, 100vh) scale(0.4976);
    }
}

@keyframes fall-128 {
    46.157% {
        -webkit-transform: translate(8.0557vw, 46.157vh) scale(0.4976);
                transform: translate(8.0557vw, 46.157vh) scale(0.4976);
    }

    to {
        -webkit-transform: translate(7.9096vw, 100vh) scale(0.4976);
                transform: translate(7.9096vw, 100vh) scale(0.4976);
    }
}

.snow:nth-child(129) {
    opacity: 0.3487;
    -webkit-transform: translate(91.0052vw, -10px) scale(0.6436);
            transform: translate(91.0052vw, -10px) scale(0.6436);
    -webkit-animation: fall-129 24s -16s linear infinite;
            animation: fall-129 24s -16s linear infinite;
}

@-webkit-keyframes fall-129 {
    47.009% {
        -webkit-transform: translate(96.1287vw, 47.009vh) scale(0.6436);
                transform: translate(96.1287vw, 47.009vh) scale(0.6436);
    }

    to {
        -webkit-transform: translate(93.56695vw, 100vh) scale(0.6436);
                transform: translate(93.56695vw, 100vh) scale(0.6436);
    }
}

@keyframes fall-129 {
    47.009% {
        -webkit-transform: translate(96.1287vw, 47.009vh) scale(0.6436);
                transform: translate(96.1287vw, 47.009vh) scale(0.6436);
    }

    to {
        -webkit-transform: translate(93.56695vw, 100vh) scale(0.6436);
                transform: translate(93.56695vw, 100vh) scale(0.6436);
    }
}

.snow:nth-child(130) {
    opacity: 0.5269;
    -webkit-transform: translate(10.5745vw, -10px) scale(0.5288);
            transform: translate(10.5745vw, -10px) scale(0.5288);
    -webkit-animation: fall-130 18s -26s linear infinite;
            animation: fall-130 18s -26s linear infinite;
}

@-webkit-keyframes fall-130 {
    63.395% {
        -webkit-transform: translate(3.9231vw, 63.395vh) scale(0.5288);
                transform: translate(3.9231vw, 63.395vh) scale(0.5288);
    }

    to {
        -webkit-transform: translate(7.2488vw, 100vh) scale(0.5288);
                transform: translate(7.2488vw, 100vh) scale(0.5288);
    }
}

@keyframes fall-130 {
    63.395% {
        -webkit-transform: translate(3.9231vw, 63.395vh) scale(0.5288);
                transform: translate(3.9231vw, 63.395vh) scale(0.5288);
    }

    to {
        -webkit-transform: translate(7.2488vw, 100vh) scale(0.5288);
                transform: translate(7.2488vw, 100vh) scale(0.5288);
    }
}

.snow:nth-child(131) {
    opacity: 0.0996;
    -webkit-transform: translate(72.5694vw, -10px) scale(0.1653);
            transform: translate(72.5694vw, -10px) scale(0.1653);
    -webkit-animation: fall-131 25s -5s linear infinite;
            animation: fall-131 25s -5s linear infinite;
}

@-webkit-keyframes fall-131 {
    67.196% {
        -webkit-transform: translate(66.5674vw, 67.196vh) scale(0.1653);
                transform: translate(66.5674vw, 67.196vh) scale(0.1653);
    }

    to {
        -webkit-transform: translate(69.5684vw, 100vh) scale(0.1653);
                transform: translate(69.5684vw, 100vh) scale(0.1653);
    }
}

@keyframes fall-131 {
    67.196% {
        -webkit-transform: translate(66.5674vw, 67.196vh) scale(0.1653);
                transform: translate(66.5674vw, 67.196vh) scale(0.1653);
    }

    to {
        -webkit-transform: translate(69.5684vw, 100vh) scale(0.1653);
                transform: translate(69.5684vw, 100vh) scale(0.1653);
    }
}

.snow:nth-child(132) {
    opacity: 0.8956;
    -webkit-transform: translate(62.5299vw, -10px) scale(0.0148);
            transform: translate(62.5299vw, -10px) scale(0.0148);
    -webkit-animation: fall-132 28s -5s linear infinite;
            animation: fall-132 28s -5s linear infinite;
}

@-webkit-keyframes fall-132 {
    36.814% {
        -webkit-transform: translate(55.5494vw, 36.814vh) scale(0.0148);
                transform: translate(55.5494vw, 36.814vh) scale(0.0148);
    }

    to {
        -webkit-transform: translate(59.03965vw, 100vh) scale(0.0148);
                transform: translate(59.03965vw, 100vh) scale(0.0148);
    }
}

@keyframes fall-132 {
    36.814% {
        -webkit-transform: translate(55.5494vw, 36.814vh) scale(0.0148);
                transform: translate(55.5494vw, 36.814vh) scale(0.0148);
    }

    to {
        -webkit-transform: translate(59.03965vw, 100vh) scale(0.0148);
                transform: translate(59.03965vw, 100vh) scale(0.0148);
    }
}

.snow:nth-child(133) {
    opacity: 0.33;
    -webkit-transform: translate(56.1658vw, -10px) scale(0.6141);
            transform: translate(56.1658vw, -10px) scale(0.6141);
    -webkit-animation: fall-133 12s -1s linear infinite;
            animation: fall-133 12s -1s linear infinite;
}

@-webkit-keyframes fall-133 {
    36.491% {
        -webkit-transform: translate(60.3522vw, 36.491vh) scale(0.6141);
                transform: translate(60.3522vw, 36.491vh) scale(0.6141);
    }

    to {
        -webkit-transform: translate(58.259vw, 100vh) scale(0.6141);
                transform: translate(58.259vw, 100vh) scale(0.6141);
    }
}

@keyframes fall-133 {
    36.491% {
        -webkit-transform: translate(60.3522vw, 36.491vh) scale(0.6141);
                transform: translate(60.3522vw, 36.491vh) scale(0.6141);
    }

    to {
        -webkit-transform: translate(58.259vw, 100vh) scale(0.6141);
                transform: translate(58.259vw, 100vh) scale(0.6141);
    }
}

.snow:nth-child(134) {
    opacity: 0.9833;
    -webkit-transform: translate(30.0369vw, -10px) scale(0.1132);
            transform: translate(30.0369vw, -10px) scale(0.1132);
    -webkit-animation: fall-134 19s -11s linear infinite;
            animation: fall-134 19s -11s linear infinite;
}

@-webkit-keyframes fall-134 {
    59.687% {
        -webkit-transform: translate(26.4272vw, 59.687vh) scale(0.1132);
                transform: translate(26.4272vw, 59.687vh) scale(0.1132);
    }

    to {
        -webkit-transform: translate(28.23205vw, 100vh) scale(0.1132);
                transform: translate(28.23205vw, 100vh) scale(0.1132);
    }
}

@keyframes fall-134 {
    59.687% {
        -webkit-transform: translate(26.4272vw, 59.687vh) scale(0.1132);
                transform: translate(26.4272vw, 59.687vh) scale(0.1132);
    }

    to {
        -webkit-transform: translate(28.23205vw, 100vh) scale(0.1132);
                transform: translate(28.23205vw, 100vh) scale(0.1132);
    }
}

.snow:nth-child(135) {
    opacity: 0.4843;
    -webkit-transform: translate(99.2804vw, -10px) scale(0.8273);
            transform: translate(99.2804vw, -10px) scale(0.8273);
    -webkit-animation: fall-135 24s -11s linear infinite;
            animation: fall-135 24s -11s linear infinite;
}

@-webkit-keyframes fall-135 {
    59.46% {
        -webkit-transform: translate(100.5501vw, 59.46vh) scale(0.8273);
                transform: translate(100.5501vw, 59.46vh) scale(0.8273);
    }

    to {
        -webkit-transform: translate(99.91525vw, 100vh) scale(0.8273);
                transform: translate(99.91525vw, 100vh) scale(0.8273);
    }
}

@keyframes fall-135 {
    59.46% {
        -webkit-transform: translate(100.5501vw, 59.46vh) scale(0.8273);
                transform: translate(100.5501vw, 59.46vh) scale(0.8273);
    }

    to {
        -webkit-transform: translate(99.91525vw, 100vh) scale(0.8273);
                transform: translate(99.91525vw, 100vh) scale(0.8273);
    }
}

.snow:nth-child(136) {
    opacity: 0.8998;
    -webkit-transform: translate(20.9429vw, -10px) scale(0.0421);
            transform: translate(20.9429vw, -10px) scale(0.0421);
    -webkit-animation: fall-136 20s -15s linear infinite;
            animation: fall-136 20s -15s linear infinite;
}

@-webkit-keyframes fall-136 {
    56.862% {
        -webkit-transform: translate(23.9861vw, 56.862vh) scale(0.0421);
                transform: translate(23.9861vw, 56.862vh) scale(0.0421);
    }

    to {
        -webkit-transform: translate(22.4645vw, 100vh) scale(0.0421);
                transform: translate(22.4645vw, 100vh) scale(0.0421);
    }
}

@keyframes fall-136 {
    56.862% {
        -webkit-transform: translate(23.9861vw, 56.862vh) scale(0.0421);
                transform: translate(23.9861vw, 56.862vh) scale(0.0421);
    }

    to {
        -webkit-transform: translate(22.4645vw, 100vh) scale(0.0421);
                transform: translate(22.4645vw, 100vh) scale(0.0421);
    }
}

.snow:nth-child(137) {
    opacity: 0.7169;
    -webkit-transform: translate(94.0606vw, -10px) scale(0.8657);
            transform: translate(94.0606vw, -10px) scale(0.8657);
    -webkit-animation: fall-137 25s -11s linear infinite;
            animation: fall-137 25s -11s linear infinite;
}

@-webkit-keyframes fall-137 {
    54.649% {
        -webkit-transform: translate(84.6662vw, 54.649vh) scale(0.8657);
                transform: translate(84.6662vw, 54.649vh) scale(0.8657);
    }

    to {
        -webkit-transform: translate(89.3634vw, 100vh) scale(0.8657);
                transform: translate(89.3634vw, 100vh) scale(0.8657);
    }
}

@keyframes fall-137 {
    54.649% {
        -webkit-transform: translate(84.6662vw, 54.649vh) scale(0.8657);
                transform: translate(84.6662vw, 54.649vh) scale(0.8657);
    }

    to {
        -webkit-transform: translate(89.3634vw, 100vh) scale(0.8657);
                transform: translate(89.3634vw, 100vh) scale(0.8657);
    }
}

.snow:nth-child(138) {
    opacity: 0.053;
    -webkit-transform: translate(94.5216vw, -10px) scale(0.6225);
            transform: translate(94.5216vw, -10px) scale(0.6225);
    -webkit-animation: fall-138 14s -9s linear infinite;
            animation: fall-138 14s -9s linear infinite;
}

@-webkit-keyframes fall-138 {
    32.498% {
        -webkit-transform: translate(98.0666vw, 32.498vh) scale(0.6225);
                transform: translate(98.0666vw, 32.498vh) scale(0.6225);
    }

    to {
        -webkit-transform: translate(96.2941vw, 100vh) scale(0.6225);
                transform: translate(96.2941vw, 100vh) scale(0.6225);
    }
}

@keyframes fall-138 {
    32.498% {
        -webkit-transform: translate(98.0666vw, 32.498vh) scale(0.6225);
                transform: translate(98.0666vw, 32.498vh) scale(0.6225);
    }

    to {
        -webkit-transform: translate(96.2941vw, 100vh) scale(0.6225);
                transform: translate(96.2941vw, 100vh) scale(0.6225);
    }
}

.snow:nth-child(139) {
    opacity: 0.2773;
    -webkit-transform: translate(19.2032vw, -10px) scale(0.4227);
            transform: translate(19.2032vw, -10px) scale(0.4227);
    -webkit-animation: fall-139 12s -15s linear infinite;
            animation: fall-139 12s -15s linear infinite;
}

@-webkit-keyframes fall-139 {
    30.719% {
        -webkit-transform: translate(10.5332vw, 30.719vh) scale(0.4227);
                transform: translate(10.5332vw, 30.719vh) scale(0.4227);
    }

    to {
        -webkit-transform: translate(14.8682vw, 100vh) scale(0.4227);
                transform: translate(14.8682vw, 100vh) scale(0.4227);
    }
}

@keyframes fall-139 {
    30.719% {
        -webkit-transform: translate(10.5332vw, 30.719vh) scale(0.4227);
                transform: translate(10.5332vw, 30.719vh) scale(0.4227);
    }

    to {
        -webkit-transform: translate(14.8682vw, 100vh) scale(0.4227);
                transform: translate(14.8682vw, 100vh) scale(0.4227);
    }
}

.snow:nth-child(140) {
    opacity: 0.5327;
    -webkit-transform: translate(72.1025vw, -10px) scale(0.83);
            transform: translate(72.1025vw, -10px) scale(0.83);
    -webkit-animation: fall-140 11s -8s linear infinite;
            animation: fall-140 11s -8s linear infinite;
}

@-webkit-keyframes fall-140 {
    30.386% {
        -webkit-transform: translate(68.6125vw, 30.386vh) scale(0.83);
                transform: translate(68.6125vw, 30.386vh) scale(0.83);
    }

    to {
        -webkit-transform: translate(70.3575vw, 100vh) scale(0.83);
                transform: translate(70.3575vw, 100vh) scale(0.83);
    }
}

@keyframes fall-140 {
    30.386% {
        -webkit-transform: translate(68.6125vw, 30.386vh) scale(0.83);
                transform: translate(68.6125vw, 30.386vh) scale(0.83);
    }

    to {
        -webkit-transform: translate(70.3575vw, 100vh) scale(0.83);
                transform: translate(70.3575vw, 100vh) scale(0.83);
    }
}

.snow:nth-child(141) {
    opacity: 0.2286;
    -webkit-transform: translate(28.1143vw, -10px) scale(0.444);
            transform: translate(28.1143vw, -10px) scale(0.444);
    -webkit-animation: fall-141 29s -8s linear infinite;
            animation: fall-141 29s -8s linear infinite;
}

@-webkit-keyframes fall-141 {
    61.999% {
        -webkit-transform: translate(30.3621vw, 61.999vh) scale(0.444);
                transform: translate(30.3621vw, 61.999vh) scale(0.444);
    }

    to {
        -webkit-transform: translate(29.2382vw, 100vh) scale(0.444);
                transform: translate(29.2382vw, 100vh) scale(0.444);
    }
}

@keyframes fall-141 {
    61.999% {
        -webkit-transform: translate(30.3621vw, 61.999vh) scale(0.444);
                transform: translate(30.3621vw, 61.999vh) scale(0.444);
    }

    to {
        -webkit-transform: translate(29.2382vw, 100vh) scale(0.444);
                transform: translate(29.2382vw, 100vh) scale(0.444);
    }
}

.snow:nth-child(142) {
    opacity: 0.1206;
    -webkit-transform: translate(67.2045vw, -10px) scale(0.671);
            transform: translate(67.2045vw, -10px) scale(0.671);
    -webkit-animation: fall-142 10s -3s linear infinite;
            animation: fall-142 10s -3s linear infinite;
}

@-webkit-keyframes fall-142 {
    55.277% {
        -webkit-transform: translate(65.9168vw, 55.277vh) scale(0.671);
                transform: translate(65.9168vw, 55.277vh) scale(0.671);
    }

    to {
        -webkit-transform: translate(66.56065vw, 100vh) scale(0.671);
                transform: translate(66.56065vw, 100vh) scale(0.671);
    }
}

@keyframes fall-142 {
    55.277% {
        -webkit-transform: translate(65.9168vw, 55.277vh) scale(0.671);
                transform: translate(65.9168vw, 55.277vh) scale(0.671);
    }

    to {
        -webkit-transform: translate(66.56065vw, 100vh) scale(0.671);
                transform: translate(66.56065vw, 100vh) scale(0.671);
    }
}

.snow:nth-child(143) {
    opacity: 0.7756;
    -webkit-transform: translate(80.1518vw, -10px) scale(0.0778);
            transform: translate(80.1518vw, -10px) scale(0.0778);
    -webkit-animation: fall-143 28s -16s linear infinite;
            animation: fall-143 28s -16s linear infinite;
}

@-webkit-keyframes fall-143 {
    40.999% {
        -webkit-transform: translate(72.9798vw, 40.999vh) scale(0.0778);
                transform: translate(72.9798vw, 40.999vh) scale(0.0778);
    }

    to {
        -webkit-transform: translate(76.5658vw, 100vh) scale(0.0778);
                transform: translate(76.5658vw, 100vh) scale(0.0778);
    }
}

@keyframes fall-143 {
    40.999% {
        -webkit-transform: translate(72.9798vw, 40.999vh) scale(0.0778);
                transform: translate(72.9798vw, 40.999vh) scale(0.0778);
    }

    to {
        -webkit-transform: translate(76.5658vw, 100vh) scale(0.0778);
                transform: translate(76.5658vw, 100vh) scale(0.0778);
    }
}

.snow:nth-child(144) {
    opacity: 0.0814;
    -webkit-transform: translate(71.8641vw, -10px) scale(0.0653);
            transform: translate(71.8641vw, -10px) scale(0.0653);
    -webkit-animation: fall-144 20s -28s linear infinite;
            animation: fall-144 20s -28s linear infinite;
}

@-webkit-keyframes fall-144 {
    32.487% {
        -webkit-transform: translate(71.5419vw, 32.487vh) scale(0.0653);
                transform: translate(71.5419vw, 32.487vh) scale(0.0653);
    }

    to {
        -webkit-transform: translate(71.703vw, 100vh) scale(0.0653);
                transform: translate(71.703vw, 100vh) scale(0.0653);
    }
}

@keyframes fall-144 {
    32.487% {
        -webkit-transform: translate(71.5419vw, 32.487vh) scale(0.0653);
                transform: translate(71.5419vw, 32.487vh) scale(0.0653);
    }

    to {
        -webkit-transform: translate(71.703vw, 100vh) scale(0.0653);
                transform: translate(71.703vw, 100vh) scale(0.0653);
    }
}

.snow:nth-child(145) {
    opacity: 0.5467;
    -webkit-transform: translate(30.547vw, -10px) scale(0.1925);
            transform: translate(30.547vw, -10px) scale(0.1925);
    -webkit-animation: fall-145 11s -14s linear infinite;
            animation: fall-145 11s -14s linear infinite;
}

@-webkit-keyframes fall-145 {
    69.691% {
        -webkit-transform: translate(22.7165vw, 69.691vh) scale(0.1925);
                transform: translate(22.7165vw, 69.691vh) scale(0.1925);
    }

    to {
        -webkit-transform: translate(26.63175vw, 100vh) scale(0.1925);
                transform: translate(26.63175vw, 100vh) scale(0.1925);
    }
}

@keyframes fall-145 {
    69.691% {
        -webkit-transform: translate(22.7165vw, 69.691vh) scale(0.1925);
                transform: translate(22.7165vw, 69.691vh) scale(0.1925);
    }

    to {
        -webkit-transform: translate(26.63175vw, 100vh) scale(0.1925);
                transform: translate(26.63175vw, 100vh) scale(0.1925);
    }
}

.snow:nth-child(146) {
    opacity: 0.9215;
    -webkit-transform: translate(45.2744vw, -10px) scale(0.1609);
            transform: translate(45.2744vw, -10px) scale(0.1609);
    -webkit-animation: fall-146 17s -9s linear infinite;
            animation: fall-146 17s -9s linear infinite;
}

@-webkit-keyframes fall-146 {
    58.19% {
        -webkit-transform: translate(52.8949vw, 58.19vh) scale(0.1609);
                transform: translate(52.8949vw, 58.19vh) scale(0.1609);
    }

    to {
        -webkit-transform: translate(49.08465vw, 100vh) scale(0.1609);
                transform: translate(49.08465vw, 100vh) scale(0.1609);
    }
}

@keyframes fall-146 {
    58.19% {
        -webkit-transform: translate(52.8949vw, 58.19vh) scale(0.1609);
                transform: translate(52.8949vw, 58.19vh) scale(0.1609);
    }

    to {
        -webkit-transform: translate(49.08465vw, 100vh) scale(0.1609);
                transform: translate(49.08465vw, 100vh) scale(0.1609);
    }
}

.snow:nth-child(147) {
    opacity: 0.3525;
    -webkit-transform: translate(34.446vw, -10px) scale(0.3309);
            transform: translate(34.446vw, -10px) scale(0.3309);
    -webkit-animation: fall-147 10s -24s linear infinite;
            animation: fall-147 10s -24s linear infinite;
}

@-webkit-keyframes fall-147 {
    61.19% {
        -webkit-transform: translate(28.3769vw, 61.19vh) scale(0.3309);
                transform: translate(28.3769vw, 61.19vh) scale(0.3309);
    }

    to {
        -webkit-transform: translate(31.41145vw, 100vh) scale(0.3309);
                transform: translate(31.41145vw, 100vh) scale(0.3309);
    }
}

@keyframes fall-147 {
    61.19% {
        -webkit-transform: translate(28.3769vw, 61.19vh) scale(0.3309);
                transform: translate(28.3769vw, 61.19vh) scale(0.3309);
    }

    to {
        -webkit-transform: translate(31.41145vw, 100vh) scale(0.3309);
                transform: translate(31.41145vw, 100vh) scale(0.3309);
    }
}

.snow:nth-child(148) {
    opacity: 0.8529;
    -webkit-transform: translate(24.5091vw, -10px) scale(0.9865);
            transform: translate(24.5091vw, -10px) scale(0.9865);
    -webkit-animation: fall-148 27s -6s linear infinite;
            animation: fall-148 27s -6s linear infinite;
}

@-webkit-keyframes fall-148 {
    55.319% {
        -webkit-transform: translate(28.8745vw, 55.319vh) scale(0.9865);
                transform: translate(28.8745vw, 55.319vh) scale(0.9865);
    }

    to {
        -webkit-transform: translate(26.6918vw, 100vh) scale(0.9865);
                transform: translate(26.6918vw, 100vh) scale(0.9865);
    }
}

@keyframes fall-148 {
    55.319% {
        -webkit-transform: translate(28.8745vw, 55.319vh) scale(0.9865);
                transform: translate(28.8745vw, 55.319vh) scale(0.9865);
    }

    to {
        -webkit-transform: translate(26.6918vw, 100vh) scale(0.9865);
                transform: translate(26.6918vw, 100vh) scale(0.9865);
    }
}

.snow:nth-child(149) {
    opacity: 0.3538;
    -webkit-transform: translate(74.1551vw, -10px) scale(0.0549);
            transform: translate(74.1551vw, -10px) scale(0.0549);
    -webkit-animation: fall-149 23s -23s linear infinite;
            animation: fall-149 23s -23s linear infinite;
}

@-webkit-keyframes fall-149 {
    38.518% {
        -webkit-transform: translate(70.3022vw, 38.518vh) scale(0.0549);
                transform: translate(70.3022vw, 38.518vh) scale(0.0549);
    }

    to {
        -webkit-transform: translate(72.22865vw, 100vh) scale(0.0549);
                transform: translate(72.22865vw, 100vh) scale(0.0549);
    }
}

@keyframes fall-149 {
    38.518% {
        -webkit-transform: translate(70.3022vw, 38.518vh) scale(0.0549);
                transform: translate(70.3022vw, 38.518vh) scale(0.0549);
    }

    to {
        -webkit-transform: translate(72.22865vw, 100vh) scale(0.0549);
                transform: translate(72.22865vw, 100vh) scale(0.0549);
    }
}

.snow:nth-child(150) {
    opacity: 0.1627;
    -webkit-transform: translate(40.9412vw, -10px) scale(0.2946);
            transform: translate(40.9412vw, -10px) scale(0.2946);
    -webkit-animation: fall-150 30s -11s linear infinite;
            animation: fall-150 30s -11s linear infinite;
}

@-webkit-keyframes fall-150 {
    59.945% {
        -webkit-transform: translate(41.8076vw, 59.945vh) scale(0.2946);
                transform: translate(41.8076vw, 59.945vh) scale(0.2946);
    }

    to {
        -webkit-transform: translate(41.3744vw, 100vh) scale(0.2946);
                transform: translate(41.3744vw, 100vh) scale(0.2946);
    }
}

@keyframes fall-150 {
    59.945% {
        -webkit-transform: translate(41.8076vw, 59.945vh) scale(0.2946);
                transform: translate(41.8076vw, 59.945vh) scale(0.2946);
    }

    to {
        -webkit-transform: translate(41.3744vw, 100vh) scale(0.2946);
                transform: translate(41.3744vw, 100vh) scale(0.2946);
    }
}

.snow:nth-child(151) {
    opacity: 0.5301;
    -webkit-transform: translate(59.488vw, -10px) scale(0.1793);
            transform: translate(59.488vw, -10px) scale(0.1793);
    -webkit-animation: fall-151 29s -10s linear infinite;
            animation: fall-151 29s -10s linear infinite;
}

@-webkit-keyframes fall-151 {
    72.603% {
        -webkit-transform: translate(51.2822vw, 72.603vh) scale(0.1793);
                transform: translate(51.2822vw, 72.603vh) scale(0.1793);
    }

    to {
        -webkit-transform: translate(55.3851vw, 100vh) scale(0.1793);
                transform: translate(55.3851vw, 100vh) scale(0.1793);
    }
}

@keyframes fall-151 {
    72.603% {
        -webkit-transform: translate(51.2822vw, 72.603vh) scale(0.1793);
                transform: translate(51.2822vw, 72.603vh) scale(0.1793);
    }

    to {
        -webkit-transform: translate(55.3851vw, 100vh) scale(0.1793);
                transform: translate(55.3851vw, 100vh) scale(0.1793);
    }
}

.snow:nth-child(152) {
    opacity: 0.8422;
    -webkit-transform: translate(69.6298vw, -10px) scale(0.7747);
            transform: translate(69.6298vw, -10px) scale(0.7747);
    -webkit-animation: fall-152 28s -11s linear infinite;
            animation: fall-152 28s -11s linear infinite;
}

@-webkit-keyframes fall-152 {
    59.883% {
        -webkit-transform: translate(68.1236vw, 59.883vh) scale(0.7747);
                transform: translate(68.1236vw, 59.883vh) scale(0.7747);
    }

    to {
        -webkit-transform: translate(68.8767vw, 100vh) scale(0.7747);
                transform: translate(68.8767vw, 100vh) scale(0.7747);
    }
}

@keyframes fall-152 {
    59.883% {
        -webkit-transform: translate(68.1236vw, 59.883vh) scale(0.7747);
                transform: translate(68.1236vw, 59.883vh) scale(0.7747);
    }

    to {
        -webkit-transform: translate(68.8767vw, 100vh) scale(0.7747);
                transform: translate(68.8767vw, 100vh) scale(0.7747);
    }
}

.snow:nth-child(153) {
    opacity: 0.1928;
    -webkit-transform: translate(67.6691vw, -10px) scale(0.0578);
            transform: translate(67.6691vw, -10px) scale(0.0578);
    -webkit-animation: fall-153 22s -9s linear infinite;
            animation: fall-153 22s -9s linear infinite;
}

@-webkit-keyframes fall-153 {
    58.822% {
        -webkit-transform: translate(67.621vw, 58.822vh) scale(0.0578);
                transform: translate(67.621vw, 58.822vh) scale(0.0578);
    }

    to {
        -webkit-transform: translate(67.64505vw, 100vh) scale(0.0578);
                transform: translate(67.64505vw, 100vh) scale(0.0578);
    }
}

@keyframes fall-153 {
    58.822% {
        -webkit-transform: translate(67.621vw, 58.822vh) scale(0.0578);
                transform: translate(67.621vw, 58.822vh) scale(0.0578);
    }

    to {
        -webkit-transform: translate(67.64505vw, 100vh) scale(0.0578);
                transform: translate(67.64505vw, 100vh) scale(0.0578);
    }
}

.snow:nth-child(154) {
    opacity: 0.3973;
    -webkit-transform: translate(32.8177vw, -10px) scale(0.4415);
            transform: translate(32.8177vw, -10px) scale(0.4415);
    -webkit-animation: fall-154 17s -8s linear infinite;
            animation: fall-154 17s -8s linear infinite;
}

@-webkit-keyframes fall-154 {
    46.941% {
        -webkit-transform: translate(40.2943vw, 46.941vh) scale(0.4415);
                transform: translate(40.2943vw, 46.941vh) scale(0.4415);
    }

    to {
        -webkit-transform: translate(36.556vw, 100vh) scale(0.4415);
                transform: translate(36.556vw, 100vh) scale(0.4415);
    }
}

@keyframes fall-154 {
    46.941% {
        -webkit-transform: translate(40.2943vw, 46.941vh) scale(0.4415);
                transform: translate(40.2943vw, 46.941vh) scale(0.4415);
    }

    to {
        -webkit-transform: translate(36.556vw, 100vh) scale(0.4415);
                transform: translate(36.556vw, 100vh) scale(0.4415);
    }
}

.snow:nth-child(155) {
    opacity: 0.3646;
    -webkit-transform: translate(10.5176vw, -10px) scale(0.2703);
            transform: translate(10.5176vw, -10px) scale(0.2703);
    -webkit-animation: fall-155 20s -16s linear infinite;
            animation: fall-155 20s -16s linear infinite;
}

@-webkit-keyframes fall-155 {
    55.274% {
        -webkit-transform: translate(10.3543vw, 55.274vh) scale(0.2703);
                transform: translate(10.3543vw, 55.274vh) scale(0.2703);
    }

    to {
        -webkit-transform: translate(10.43595vw, 100vh) scale(0.2703);
                transform: translate(10.43595vw, 100vh) scale(0.2703);
    }
}

@keyframes fall-155 {
    55.274% {
        -webkit-transform: translate(10.3543vw, 55.274vh) scale(0.2703);
                transform: translate(10.3543vw, 55.274vh) scale(0.2703);
    }

    to {
        -webkit-transform: translate(10.43595vw, 100vh) scale(0.2703);
                transform: translate(10.43595vw, 100vh) scale(0.2703);
    }
}

.snow:nth-child(156) {
    opacity: 0.8995;
    -webkit-transform: translate(57.7998vw, -10px) scale(0.4737);
            transform: translate(57.7998vw, -10px) scale(0.4737);
    -webkit-animation: fall-156 26s -12s linear infinite;
            animation: fall-156 26s -12s linear infinite;
}

@-webkit-keyframes fall-156 {
    50.376% {
        -webkit-transform: translate(52.9035vw, 50.376vh) scale(0.4737);
                transform: translate(52.9035vw, 50.376vh) scale(0.4737);
    }

    to {
        -webkit-transform: translate(55.35165vw, 100vh) scale(0.4737);
                transform: translate(55.35165vw, 100vh) scale(0.4737);
    }
}

@keyframes fall-156 {
    50.376% {
        -webkit-transform: translate(52.9035vw, 50.376vh) scale(0.4737);
                transform: translate(52.9035vw, 50.376vh) scale(0.4737);
    }

    to {
        -webkit-transform: translate(55.35165vw, 100vh) scale(0.4737);
                transform: translate(55.35165vw, 100vh) scale(0.4737);
    }
}

.snow:nth-child(157) {
    opacity: 0.794;
    -webkit-transform: translate(74.0607vw, -10px) scale(0.2427);
            transform: translate(74.0607vw, -10px) scale(0.2427);
    -webkit-animation: fall-157 26s -25s linear infinite;
            animation: fall-157 26s -25s linear infinite;
}

@-webkit-keyframes fall-157 {
    37.928% {
        -webkit-transform: translate(70.4138vw, 37.928vh) scale(0.2427);
                transform: translate(70.4138vw, 37.928vh) scale(0.2427);
    }

    to {
        -webkit-transform: translate(72.23725vw, 100vh) scale(0.2427);
                transform: translate(72.23725vw, 100vh) scale(0.2427);
    }
}

@keyframes fall-157 {
    37.928% {
        -webkit-transform: translate(70.4138vw, 37.928vh) scale(0.2427);
                transform: translate(70.4138vw, 37.928vh) scale(0.2427);
    }

    to {
        -webkit-transform: translate(72.23725vw, 100vh) scale(0.2427);
                transform: translate(72.23725vw, 100vh) scale(0.2427);
    }
}

.snow:nth-child(158) {
    opacity: 0.7287;
    -webkit-transform: translate(52.1804vw, -10px) scale(0.4054);
            transform: translate(52.1804vw, -10px) scale(0.4054);
    -webkit-animation: fall-158 25s -2s linear infinite;
            animation: fall-158 25s -2s linear infinite;
}

@-webkit-keyframes fall-158 {
    48.626% {
        -webkit-transform: translate(44.2117vw, 48.626vh) scale(0.4054);
                transform: translate(44.2117vw, 48.626vh) scale(0.4054);
    }

    to {
        -webkit-transform: translate(48.19605vw, 100vh) scale(0.4054);
                transform: translate(48.19605vw, 100vh) scale(0.4054);
    }
}

@keyframes fall-158 {
    48.626% {
        -webkit-transform: translate(44.2117vw, 48.626vh) scale(0.4054);
                transform: translate(44.2117vw, 48.626vh) scale(0.4054);
    }

    to {
        -webkit-transform: translate(48.19605vw, 100vh) scale(0.4054);
                transform: translate(48.19605vw, 100vh) scale(0.4054);
    }
}

.snow:nth-child(159) {
    opacity: 0.4286;
    -webkit-transform: translate(96.6589vw, -10px) scale(0.185);
            transform: translate(96.6589vw, -10px) scale(0.185);
    -webkit-animation: fall-159 16s -2s linear infinite;
            animation: fall-159 16s -2s linear infinite;
}

@-webkit-keyframes fall-159 {
    67.544% {
        -webkit-transform: translate(103.7379vw, 67.544vh) scale(0.185);
                transform: translate(103.7379vw, 67.544vh) scale(0.185);
    }

    to {
        -webkit-transform: translate(100.1984vw, 100vh) scale(0.185);
                transform: translate(100.1984vw, 100vh) scale(0.185);
    }
}

@keyframes fall-159 {
    67.544% {
        -webkit-transform: translate(103.7379vw, 67.544vh) scale(0.185);
                transform: translate(103.7379vw, 67.544vh) scale(0.185);
    }

    to {
        -webkit-transform: translate(100.1984vw, 100vh) scale(0.185);
                transform: translate(100.1984vw, 100vh) scale(0.185);
    }
}

.snow:nth-child(160) {
    opacity: 0.646;
    -webkit-transform: translate(88.9578vw, -10px) scale(0.9542);
            transform: translate(88.9578vw, -10px) scale(0.9542);
    -webkit-animation: fall-160 23s -21s linear infinite;
            animation: fall-160 23s -21s linear infinite;
}

@-webkit-keyframes fall-160 {
    69.18% {
        -webkit-transform: translate(93.5925vw, 69.18vh) scale(0.9542);
                transform: translate(93.5925vw, 69.18vh) scale(0.9542);
    }

    to {
        -webkit-transform: translate(91.27515vw, 100vh) scale(0.9542);
                transform: translate(91.27515vw, 100vh) scale(0.9542);
    }
}

@keyframes fall-160 {
    69.18% {
        -webkit-transform: translate(93.5925vw, 69.18vh) scale(0.9542);
                transform: translate(93.5925vw, 69.18vh) scale(0.9542);
    }

    to {
        -webkit-transform: translate(91.27515vw, 100vh) scale(0.9542);
                transform: translate(91.27515vw, 100vh) scale(0.9542);
    }
}

.snow:nth-child(161) {
    opacity: 0.5689;
    -webkit-transform: translate(42.0793vw, -10px) scale(0.931);
            transform: translate(42.0793vw, -10px) scale(0.931);
    -webkit-animation: fall-161 30s -13s linear infinite;
            animation: fall-161 30s -13s linear infinite;
}

@-webkit-keyframes fall-161 {
    62.995% {
        -webkit-transform: translate(43.3121vw, 62.995vh) scale(0.931);
                transform: translate(43.3121vw, 62.995vh) scale(0.931);
    }

    to {
        -webkit-transform: translate(42.6957vw, 100vh) scale(0.931);
                transform: translate(42.6957vw, 100vh) scale(0.931);
    }
}

@keyframes fall-161 {
    62.995% {
        -webkit-transform: translate(43.3121vw, 62.995vh) scale(0.931);
                transform: translate(43.3121vw, 62.995vh) scale(0.931);
    }

    to {
        -webkit-transform: translate(42.6957vw, 100vh) scale(0.931);
                transform: translate(42.6957vw, 100vh) scale(0.931);
    }
}

.snow:nth-child(162) {
    opacity: 0.8679;
    -webkit-transform: translate(61.6565vw, -10px) scale(0.1362);
            transform: translate(61.6565vw, -10px) scale(0.1362);
    -webkit-animation: fall-162 26s -7s linear infinite;
            animation: fall-162 26s -7s linear infinite;
}

@-webkit-keyframes fall-162 {
    30.35% {
        -webkit-transform: translate(67.3672vw, 30.35vh) scale(0.1362);
                transform: translate(67.3672vw, 30.35vh) scale(0.1362);
    }

    to {
        -webkit-transform: translate(64.51185vw, 100vh) scale(0.1362);
                transform: translate(64.51185vw, 100vh) scale(0.1362);
    }
}

@keyframes fall-162 {
    30.35% {
        -webkit-transform: translate(67.3672vw, 30.35vh) scale(0.1362);
                transform: translate(67.3672vw, 30.35vh) scale(0.1362);
    }

    to {
        -webkit-transform: translate(64.51185vw, 100vh) scale(0.1362);
                transform: translate(64.51185vw, 100vh) scale(0.1362);
    }
}

.snow:nth-child(163) {
    opacity: 0.8508;
    -webkit-transform: translate(50.152vw, -10px) scale(0.5965);
            transform: translate(50.152vw, -10px) scale(0.5965);
    -webkit-animation: fall-163 15s -14s linear infinite;
            animation: fall-163 15s -14s linear infinite;
}

@-webkit-keyframes fall-163 {
    60.35% {
        -webkit-transform: translate(51.8518vw, 60.35vh) scale(0.5965);
                transform: translate(51.8518vw, 60.35vh) scale(0.5965);
    }

    to {
        -webkit-transform: translate(51.0019vw, 100vh) scale(0.5965);
                transform: translate(51.0019vw, 100vh) scale(0.5965);
    }
}

@keyframes fall-163 {
    60.35% {
        -webkit-transform: translate(51.8518vw, 60.35vh) scale(0.5965);
                transform: translate(51.8518vw, 60.35vh) scale(0.5965);
    }

    to {
        -webkit-transform: translate(51.0019vw, 100vh) scale(0.5965);
                transform: translate(51.0019vw, 100vh) scale(0.5965);
    }
}

.snow:nth-child(164) {
    opacity: 0.2237;
    -webkit-transform: translate(52.1855vw, -10px) scale(0.5429);
            transform: translate(52.1855vw, -10px) scale(0.5429);
    -webkit-animation: fall-164 21s -5s linear infinite;
            animation: fall-164 21s -5s linear infinite;
}

@-webkit-keyframes fall-164 {
    45.717% {
        -webkit-transform: translate(56.5147vw, 45.717vh) scale(0.5429);
                transform: translate(56.5147vw, 45.717vh) scale(0.5429);
    }

    to {
        -webkit-transform: translate(54.3501vw, 100vh) scale(0.5429);
                transform: translate(54.3501vw, 100vh) scale(0.5429);
    }
}

@keyframes fall-164 {
    45.717% {
        -webkit-transform: translate(56.5147vw, 45.717vh) scale(0.5429);
                transform: translate(56.5147vw, 45.717vh) scale(0.5429);
    }

    to {
        -webkit-transform: translate(54.3501vw, 100vh) scale(0.5429);
                transform: translate(54.3501vw, 100vh) scale(0.5429);
    }
}

.snow:nth-child(165) {
    opacity: 0.3038;
    -webkit-transform: translate(28.0848vw, -10px) scale(0.4918);
            transform: translate(28.0848vw, -10px) scale(0.4918);
    -webkit-animation: fall-165 27s -20s linear infinite;
            animation: fall-165 27s -20s linear infinite;
}

@-webkit-keyframes fall-165 {
    61.982% {
        -webkit-transform: translate(20.0683vw, 61.982vh) scale(0.4918);
                transform: translate(20.0683vw, 61.982vh) scale(0.4918);
    }

    to {
        -webkit-transform: translate(24.07655vw, 100vh) scale(0.4918);
                transform: translate(24.07655vw, 100vh) scale(0.4918);
    }
}

@keyframes fall-165 {
    61.982% {
        -webkit-transform: translate(20.0683vw, 61.982vh) scale(0.4918);
                transform: translate(20.0683vw, 61.982vh) scale(0.4918);
    }

    to {
        -webkit-transform: translate(24.07655vw, 100vh) scale(0.4918);
                transform: translate(24.07655vw, 100vh) scale(0.4918);
    }
}

.snow:nth-child(166) {
    opacity: 0.2947;
    -webkit-transform: translate(49.5065vw, -10px) scale(0.7356);
            transform: translate(49.5065vw, -10px) scale(0.7356);
    -webkit-animation: fall-166 23s -1s linear infinite;
            animation: fall-166 23s -1s linear infinite;
}

@-webkit-keyframes fall-166 {
    47.47% {
        -webkit-transform: translate(46.3475vw, 47.47vh) scale(0.7356);
                transform: translate(46.3475vw, 47.47vh) scale(0.7356);
    }

    to {
        -webkit-transform: translate(47.927vw, 100vh) scale(0.7356);
                transform: translate(47.927vw, 100vh) scale(0.7356);
    }
}

@keyframes fall-166 {
    47.47% {
        -webkit-transform: translate(46.3475vw, 47.47vh) scale(0.7356);
                transform: translate(46.3475vw, 47.47vh) scale(0.7356);
    }

    to {
        -webkit-transform: translate(47.927vw, 100vh) scale(0.7356);
                transform: translate(47.927vw, 100vh) scale(0.7356);
    }
}

.snow:nth-child(167) {
    opacity: 0.166;
    -webkit-transform: translate(84.8481vw, -10px) scale(0.0833);
            transform: translate(84.8481vw, -10px) scale(0.0833);
    -webkit-animation: fall-167 27s -2s linear infinite;
            animation: fall-167 27s -2s linear infinite;
}

@-webkit-keyframes fall-167 {
    64.606% {
        -webkit-transform: translate(89.4556vw, 64.606vh) scale(0.0833);
                transform: translate(89.4556vw, 64.606vh) scale(0.0833);
    }

    to {
        -webkit-transform: translate(87.15185vw, 100vh) scale(0.0833);
                transform: translate(87.15185vw, 100vh) scale(0.0833);
    }
}

@keyframes fall-167 {
    64.606% {
        -webkit-transform: translate(89.4556vw, 64.606vh) scale(0.0833);
                transform: translate(89.4556vw, 64.606vh) scale(0.0833);
    }

    to {
        -webkit-transform: translate(87.15185vw, 100vh) scale(0.0833);
                transform: translate(87.15185vw, 100vh) scale(0.0833);
    }
}

.snow:nth-child(168) {
    opacity: 0.9829;
    -webkit-transform: translate(49.8265vw, -10px) scale(0.7377);
            transform: translate(49.8265vw, -10px) scale(0.7377);
    -webkit-animation: fall-168 24s -13s linear infinite;
            animation: fall-168 24s -13s linear infinite;
}

@-webkit-keyframes fall-168 {
    65.974% {
        -webkit-transform: translate(51.4067vw, 65.974vh) scale(0.7377);
                transform: translate(51.4067vw, 65.974vh) scale(0.7377);
    }

    to {
        -webkit-transform: translate(50.6166vw, 100vh) scale(0.7377);
                transform: translate(50.6166vw, 100vh) scale(0.7377);
    }
}

@keyframes fall-168 {
    65.974% {
        -webkit-transform: translate(51.4067vw, 65.974vh) scale(0.7377);
                transform: translate(51.4067vw, 65.974vh) scale(0.7377);
    }

    to {
        -webkit-transform: translate(50.6166vw, 100vh) scale(0.7377);
                transform: translate(50.6166vw, 100vh) scale(0.7377);
    }
}

.snow:nth-child(169) {
    opacity: 0.8154;
    -webkit-transform: translate(83.0049vw, -10px) scale(0.4222);
            transform: translate(83.0049vw, -10px) scale(0.4222);
    -webkit-animation: fall-169 11s -30s linear infinite;
            animation: fall-169 11s -30s linear infinite;
}

@-webkit-keyframes fall-169 {
    78.383% {
        -webkit-transform: translate(86.5442vw, 78.383vh) scale(0.4222);
                transform: translate(86.5442vw, 78.383vh) scale(0.4222);
    }

    to {
        -webkit-transform: translate(84.77455vw, 100vh) scale(0.4222);
                transform: translate(84.77455vw, 100vh) scale(0.4222);
    }
}

@keyframes fall-169 {
    78.383% {
        -webkit-transform: translate(86.5442vw, 78.383vh) scale(0.4222);
                transform: translate(86.5442vw, 78.383vh) scale(0.4222);
    }

    to {
        -webkit-transform: translate(84.77455vw, 100vh) scale(0.4222);
                transform: translate(84.77455vw, 100vh) scale(0.4222);
    }
}

.snow:nth-child(170) {
    opacity: 0.7296;
    -webkit-transform: translate(12.1873vw, -10px) scale(0.2809);
            transform: translate(12.1873vw, -10px) scale(0.2809);
    -webkit-animation: fall-170 19s -25s linear infinite;
            animation: fall-170 19s -25s linear infinite;
}

@-webkit-keyframes fall-170 {
    34.771% {
        -webkit-transform: translate(17.3533vw, 34.771vh) scale(0.2809);
                transform: translate(17.3533vw, 34.771vh) scale(0.2809);
    }

    to {
        -webkit-transform: translate(14.7703vw, 100vh) scale(0.2809);
                transform: translate(14.7703vw, 100vh) scale(0.2809);
    }
}

@keyframes fall-170 {
    34.771% {
        -webkit-transform: translate(17.3533vw, 34.771vh) scale(0.2809);
                transform: translate(17.3533vw, 34.771vh) scale(0.2809);
    }

    to {
        -webkit-transform: translate(14.7703vw, 100vh) scale(0.2809);
                transform: translate(14.7703vw, 100vh) scale(0.2809);
    }
}

.snow:nth-child(171) {
    opacity: 0.7181;
    -webkit-transform: translate(82.8452vw, -10px) scale(0.7894);
            transform: translate(82.8452vw, -10px) scale(0.7894);
    -webkit-animation: fall-171 22s -10s linear infinite;
            animation: fall-171 22s -10s linear infinite;
}

@-webkit-keyframes fall-171 {
    30.6% {
        -webkit-transform: translate(86.4055vw, 30.6vh) scale(0.7894);
                transform: translate(86.4055vw, 30.6vh) scale(0.7894);
    }

    to {
        -webkit-transform: translate(84.62535vw, 100vh) scale(0.7894);
                transform: translate(84.62535vw, 100vh) scale(0.7894);
    }
}

@keyframes fall-171 {
    30.6% {
        -webkit-transform: translate(86.4055vw, 30.6vh) scale(0.7894);
                transform: translate(86.4055vw, 30.6vh) scale(0.7894);
    }

    to {
        -webkit-transform: translate(84.62535vw, 100vh) scale(0.7894);
                transform: translate(84.62535vw, 100vh) scale(0.7894);
    }
}

.snow:nth-child(172) {
    opacity: 0.6484;
    -webkit-transform: translate(57.6296vw, -10px) scale(0.1092);
            transform: translate(57.6296vw, -10px) scale(0.1092);
    -webkit-animation: fall-172 27s -22s linear infinite;
            animation: fall-172 27s -22s linear infinite;
}

@-webkit-keyframes fall-172 {
    33.847% {
        -webkit-transform: translate(55.7862vw, 33.847vh) scale(0.1092);
                transform: translate(55.7862vw, 33.847vh) scale(0.1092);
    }

    to {
        -webkit-transform: translate(56.7079vw, 100vh) scale(0.1092);
                transform: translate(56.7079vw, 100vh) scale(0.1092);
    }
}

@keyframes fall-172 {
    33.847% {
        -webkit-transform: translate(55.7862vw, 33.847vh) scale(0.1092);
                transform: translate(55.7862vw, 33.847vh) scale(0.1092);
    }

    to {
        -webkit-transform: translate(56.7079vw, 100vh) scale(0.1092);
                transform: translate(56.7079vw, 100vh) scale(0.1092);
    }
}

.snow:nth-child(173) {
    opacity: 0.5522;
    -webkit-transform: translate(79.8818vw, -10px) scale(0.3795);
            transform: translate(79.8818vw, -10px) scale(0.3795);
    -webkit-animation: fall-173 26s -1s linear infinite;
            animation: fall-173 26s -1s linear infinite;
}

@-webkit-keyframes fall-173 {
    37.63% {
        -webkit-transform: translate(79.5203vw, 37.63vh) scale(0.3795);
                transform: translate(79.5203vw, 37.63vh) scale(0.3795);
    }

    to {
        -webkit-transform: translate(79.70105vw, 100vh) scale(0.3795);
                transform: translate(79.70105vw, 100vh) scale(0.3795);
    }
}

@keyframes fall-173 {
    37.63% {
        -webkit-transform: translate(79.5203vw, 37.63vh) scale(0.3795);
                transform: translate(79.5203vw, 37.63vh) scale(0.3795);
    }

    to {
        -webkit-transform: translate(79.70105vw, 100vh) scale(0.3795);
                transform: translate(79.70105vw, 100vh) scale(0.3795);
    }
}

.snow:nth-child(174) {
    opacity: 0.7678;
    -webkit-transform: translate(92.8475vw, -10px) scale(0.97);
            transform: translate(92.8475vw, -10px) scale(0.97);
    -webkit-animation: fall-174 24s -6s linear infinite;
            animation: fall-174 24s -6s linear infinite;
}

@-webkit-keyframes fall-174 {
    63.366% {
        -webkit-transform: translate(99.4641vw, 63.366vh) scale(0.97);
                transform: translate(99.4641vw, 63.366vh) scale(0.97);
    }

    to {
        -webkit-transform: translate(96.1558vw, 100vh) scale(0.97);
                transform: translate(96.1558vw, 100vh) scale(0.97);
    }
}

@keyframes fall-174 {
    63.366% {
        -webkit-transform: translate(99.4641vw, 63.366vh) scale(0.97);
                transform: translate(99.4641vw, 63.366vh) scale(0.97);
    }

    to {
        -webkit-transform: translate(96.1558vw, 100vh) scale(0.97);
                transform: translate(96.1558vw, 100vh) scale(0.97);
    }
}

.snow:nth-child(175) {
    opacity: 0.3489;
    -webkit-transform: translate(80.0739vw, -10px) scale(0.9125);
            transform: translate(80.0739vw, -10px) scale(0.9125);
    -webkit-animation: fall-175 11s -26s linear infinite;
            animation: fall-175 11s -26s linear infinite;
}

@-webkit-keyframes fall-175 {
    69.717% {
        -webkit-transform: translate(85.415vw, 69.717vh) scale(0.9125);
                transform: translate(85.415vw, 69.717vh) scale(0.9125);
    }

    to {
        -webkit-transform: translate(82.74445vw, 100vh) scale(0.9125);
                transform: translate(82.74445vw, 100vh) scale(0.9125);
    }
}

@keyframes fall-175 {
    69.717% {
        -webkit-transform: translate(85.415vw, 69.717vh) scale(0.9125);
                transform: translate(85.415vw, 69.717vh) scale(0.9125);
    }

    to {
        -webkit-transform: translate(82.74445vw, 100vh) scale(0.9125);
                transform: translate(82.74445vw, 100vh) scale(0.9125);
    }
}

.snow:nth-child(176) {
    opacity: 0.8112;
    -webkit-transform: translate(56.5936vw, -10px) scale(0.3455);
            transform: translate(56.5936vw, -10px) scale(0.3455);
    -webkit-animation: fall-176 10s -20s linear infinite;
            animation: fall-176 10s -20s linear infinite;
}

@-webkit-keyframes fall-176 {
    73.753% {
        -webkit-transform: translate(46.679vw, 73.753vh) scale(0.3455);
                transform: translate(46.679vw, 73.753vh) scale(0.3455);
    }

    to {
        -webkit-transform: translate(51.6363vw, 100vh) scale(0.3455);
                transform: translate(51.6363vw, 100vh) scale(0.3455);
    }
}

@keyframes fall-176 {
    73.753% {
        -webkit-transform: translate(46.679vw, 73.753vh) scale(0.3455);
                transform: translate(46.679vw, 73.753vh) scale(0.3455);
    }

    to {
        -webkit-transform: translate(51.6363vw, 100vh) scale(0.3455);
                transform: translate(51.6363vw, 100vh) scale(0.3455);
    }
}

.snow:nth-child(177) {
    opacity: 0.5739;
    -webkit-transform: translate(13.3476vw, -10px) scale(0.8235);
            transform: translate(13.3476vw, -10px) scale(0.8235);
    -webkit-animation: fall-177 28s -8s linear infinite;
            animation: fall-177 28s -8s linear infinite;
}

@-webkit-keyframes fall-177 {
    47.374% {
        -webkit-transform: translate(7.2125vw, 47.374vh) scale(0.8235);
                transform: translate(7.2125vw, 47.374vh) scale(0.8235);
    }

    to {
        -webkit-transform: translate(10.28005vw, 100vh) scale(0.8235);
                transform: translate(10.28005vw, 100vh) scale(0.8235);
    }
}

@keyframes fall-177 {
    47.374% {
        -webkit-transform: translate(7.2125vw, 47.374vh) scale(0.8235);
                transform: translate(7.2125vw, 47.374vh) scale(0.8235);
    }

    to {
        -webkit-transform: translate(10.28005vw, 100vh) scale(0.8235);
                transform: translate(10.28005vw, 100vh) scale(0.8235);
    }
}

.snow:nth-child(178) {
    opacity: 0.9738;
    -webkit-transform: translate(59.4327vw, -10px) scale(0.4163);
            transform: translate(59.4327vw, -10px) scale(0.4163);
    -webkit-animation: fall-178 11s -29s linear infinite;
            animation: fall-178 11s -29s linear infinite;
}

@-webkit-keyframes fall-178 {
    70.999% {
        -webkit-transform: translate(60.6368vw, 70.999vh) scale(0.4163);
                transform: translate(60.6368vw, 70.999vh) scale(0.4163);
    }

    to {
        -webkit-transform: translate(60.03475vw, 100vh) scale(0.4163);
                transform: translate(60.03475vw, 100vh) scale(0.4163);
    }
}

@keyframes fall-178 {
    70.999% {
        -webkit-transform: translate(60.6368vw, 70.999vh) scale(0.4163);
                transform: translate(60.6368vw, 70.999vh) scale(0.4163);
    }

    to {
        -webkit-transform: translate(60.03475vw, 100vh) scale(0.4163);
                transform: translate(60.03475vw, 100vh) scale(0.4163);
    }
}

.snow:nth-child(179) {
    opacity: 0.6886;
    -webkit-transform: translate(87.1021vw, -10px) scale(0.3789);
            transform: translate(87.1021vw, -10px) scale(0.3789);
    -webkit-animation: fall-179 11s -6s linear infinite;
            animation: fall-179 11s -6s linear infinite;
}

@-webkit-keyframes fall-179 {
    42.497% {
        -webkit-transform: translate(90.6156vw, 42.497vh) scale(0.3789);
                transform: translate(90.6156vw, 42.497vh) scale(0.3789);
    }

    to {
        -webkit-transform: translate(88.85885vw, 100vh) scale(0.3789);
                transform: translate(88.85885vw, 100vh) scale(0.3789);
    }
}

@keyframes fall-179 {
    42.497% {
        -webkit-transform: translate(90.6156vw, 42.497vh) scale(0.3789);
                transform: translate(90.6156vw, 42.497vh) scale(0.3789);
    }

    to {
        -webkit-transform: translate(88.85885vw, 100vh) scale(0.3789);
                transform: translate(88.85885vw, 100vh) scale(0.3789);
    }
}

.snow:nth-child(180) {
    opacity: 0.6182;
    -webkit-transform: translate(52.6092vw, -10px) scale(0.1615);
            transform: translate(52.6092vw, -10px) scale(0.1615);
    -webkit-animation: fall-180 26s -23s linear infinite;
            animation: fall-180 26s -23s linear infinite;
}

@-webkit-keyframes fall-180 {
    36.792% {
        -webkit-transform: translate(60.2254vw, 36.792vh) scale(0.1615);
                transform: translate(60.2254vw, 36.792vh) scale(0.1615);
    }

    to {
        -webkit-transform: translate(56.4173vw, 100vh) scale(0.1615);
                transform: translate(56.4173vw, 100vh) scale(0.1615);
    }
}

@keyframes fall-180 {
    36.792% {
        -webkit-transform: translate(60.2254vw, 36.792vh) scale(0.1615);
                transform: translate(60.2254vw, 36.792vh) scale(0.1615);
    }

    to {
        -webkit-transform: translate(56.4173vw, 100vh) scale(0.1615);
                transform: translate(56.4173vw, 100vh) scale(0.1615);
    }
}

.snow:nth-child(181) {
    opacity: 0.817;
    -webkit-transform: translate(3.371vw, -10px) scale(0.4952);
            transform: translate(3.371vw, -10px) scale(0.4952);
    -webkit-animation: fall-181 19s -21s linear infinite;
            animation: fall-181 19s -21s linear infinite;
}

@-webkit-keyframes fall-181 {
    36.813% {
        -webkit-transform: translate(0.9275vw, 36.813vh) scale(0.4952);
                transform: translate(0.9275vw, 36.813vh) scale(0.4952);
    }

    to {
        -webkit-transform: translate(2.14925vw, 100vh) scale(0.4952);
                transform: translate(2.14925vw, 100vh) scale(0.4952);
    }
}

@keyframes fall-181 {
    36.813% {
        -webkit-transform: translate(0.9275vw, 36.813vh) scale(0.4952);
                transform: translate(0.9275vw, 36.813vh) scale(0.4952);
    }

    to {
        -webkit-transform: translate(2.14925vw, 100vh) scale(0.4952);
                transform: translate(2.14925vw, 100vh) scale(0.4952);
    }
}

.snow:nth-child(182) {
    opacity: 0.3612;
    -webkit-transform: translate(16.6664vw, -10px) scale(0.3231);
            transform: translate(16.6664vw, -10px) scale(0.3231);
    -webkit-animation: fall-182 18s -14s linear infinite;
            animation: fall-182 18s -14s linear infinite;
}

@-webkit-keyframes fall-182 {
    31.934% {
        -webkit-transform: translate(19.2322vw, 31.934vh) scale(0.3231);
                transform: translate(19.2322vw, 31.934vh) scale(0.3231);
    }

    to {
        -webkit-transform: translate(17.9493vw, 100vh) scale(0.3231);
                transform: translate(17.9493vw, 100vh) scale(0.3231);
    }
}

@keyframes fall-182 {
    31.934% {
        -webkit-transform: translate(19.2322vw, 31.934vh) scale(0.3231);
                transform: translate(19.2322vw, 31.934vh) scale(0.3231);
    }

    to {
        -webkit-transform: translate(17.9493vw, 100vh) scale(0.3231);
                transform: translate(17.9493vw, 100vh) scale(0.3231);
    }
}

.snow:nth-child(183) {
    opacity: 0.6605;
    -webkit-transform: translate(46.5074vw, -10px) scale(0.7031);
            transform: translate(46.5074vw, -10px) scale(0.7031);
    -webkit-animation: fall-183 20s -9s linear infinite;
            animation: fall-183 20s -9s linear infinite;
}

@-webkit-keyframes fall-183 {
    30.58% {
        -webkit-transform: translate(48.1199vw, 30.58vh) scale(0.7031);
                transform: translate(48.1199vw, 30.58vh) scale(0.7031);
    }

    to {
        -webkit-transform: translate(47.31365vw, 100vh) scale(0.7031);
                transform: translate(47.31365vw, 100vh) scale(0.7031);
    }
}

@keyframes fall-183 {
    30.58% {
        -webkit-transform: translate(48.1199vw, 30.58vh) scale(0.7031);
                transform: translate(48.1199vw, 30.58vh) scale(0.7031);
    }

    to {
        -webkit-transform: translate(47.31365vw, 100vh) scale(0.7031);
                transform: translate(47.31365vw, 100vh) scale(0.7031);
    }
}

.snow:nth-child(184) {
    opacity: 0.5108;
    -webkit-transform: translate(33.1607vw, -10px) scale(0.7991);
            transform: translate(33.1607vw, -10px) scale(0.7991);
    -webkit-animation: fall-184 17s -11s linear infinite;
            animation: fall-184 17s -11s linear infinite;
}

@-webkit-keyframes fall-184 {
    55.565% {
        -webkit-transform: translate(33.4137vw, 55.565vh) scale(0.7991);
                transform: translate(33.4137vw, 55.565vh) scale(0.7991);
    }

    to {
        -webkit-transform: translate(33.2872vw, 100vh) scale(0.7991);
                transform: translate(33.2872vw, 100vh) scale(0.7991);
    }
}

@keyframes fall-184 {
    55.565% {
        -webkit-transform: translate(33.4137vw, 55.565vh) scale(0.7991);
                transform: translate(33.4137vw, 55.565vh) scale(0.7991);
    }

    to {
        -webkit-transform: translate(33.2872vw, 100vh) scale(0.7991);
                transform: translate(33.2872vw, 100vh) scale(0.7991);
    }
}

.snow:nth-child(185) {
    opacity: 0.9618;
    -webkit-transform: translate(40.8748vw, -10px) scale(0.5152);
            transform: translate(40.8748vw, -10px) scale(0.5152);
    -webkit-animation: fall-185 15s -18s linear infinite;
            animation: fall-185 15s -18s linear infinite;
}

@-webkit-keyframes fall-185 {
    62.5% {
        -webkit-transform: translate(44.1512vw, 62.5vh) scale(0.5152);
                transform: translate(44.1512vw, 62.5vh) scale(0.5152);
    }

    to {
        -webkit-transform: translate(42.513vw, 100vh) scale(0.5152);
                transform: translate(42.513vw, 100vh) scale(0.5152);
    }
}

@keyframes fall-185 {
    62.5% {
        -webkit-transform: translate(44.1512vw, 62.5vh) scale(0.5152);
                transform: translate(44.1512vw, 62.5vh) scale(0.5152);
    }

    to {
        -webkit-transform: translate(42.513vw, 100vh) scale(0.5152);
                transform: translate(42.513vw, 100vh) scale(0.5152);
    }
}

.snow:nth-child(186) {
    opacity: 0.0678;
    -webkit-transform: translate(4.8576vw, -10px) scale(0.3613);
            transform: translate(4.8576vw, -10px) scale(0.3613);
    -webkit-animation: fall-186 21s -5s linear infinite;
            animation: fall-186 21s -5s linear infinite;
}

@-webkit-keyframes fall-186 {
    31.052% {
        -webkit-transform: translate(3.6758vw, 31.052vh) scale(0.3613);
                transform: translate(3.6758vw, 31.052vh) scale(0.3613);
    }

    to {
        -webkit-transform: translate(4.2667vw, 100vh) scale(0.3613);
                transform: translate(4.2667vw, 100vh) scale(0.3613);
    }
}

@keyframes fall-186 {
    31.052% {
        -webkit-transform: translate(3.6758vw, 31.052vh) scale(0.3613);
                transform: translate(3.6758vw, 31.052vh) scale(0.3613);
    }

    to {
        -webkit-transform: translate(4.2667vw, 100vh) scale(0.3613);
                transform: translate(4.2667vw, 100vh) scale(0.3613);
    }
}

.snow:nth-child(187) {
    opacity: 0.0205;
    -webkit-transform: translate(13.1758vw, -10px) scale(0.1553);
            transform: translate(13.1758vw, -10px) scale(0.1553);
    -webkit-animation: fall-187 11s -18s linear infinite;
            animation: fall-187 11s -18s linear infinite;
}

@-webkit-keyframes fall-187 {
    59.15% {
        -webkit-transform: translate(18.4007vw, 59.15vh) scale(0.1553);
                transform: translate(18.4007vw, 59.15vh) scale(0.1553);
    }

    to {
        -webkit-transform: translate(15.78825vw, 100vh) scale(0.1553);
                transform: translate(15.78825vw, 100vh) scale(0.1553);
    }
}

@keyframes fall-187 {
    59.15% {
        -webkit-transform: translate(18.4007vw, 59.15vh) scale(0.1553);
                transform: translate(18.4007vw, 59.15vh) scale(0.1553);
    }

    to {
        -webkit-transform: translate(15.78825vw, 100vh) scale(0.1553);
                transform: translate(15.78825vw, 100vh) scale(0.1553);
    }
}

.snow:nth-child(188) {
    opacity: 0.8722;
    -webkit-transform: translate(10.2541vw, -10px) scale(0.0357);
            transform: translate(10.2541vw, -10px) scale(0.0357);
    -webkit-animation: fall-188 20s -6s linear infinite;
            animation: fall-188 20s -6s linear infinite;
}

@-webkit-keyframes fall-188 {
    56.758% {
        -webkit-transform: translate(10.9632vw, 56.758vh) scale(0.0357);
                transform: translate(10.9632vw, 56.758vh) scale(0.0357);
    }

    to {
        -webkit-transform: translate(10.60865vw, 100vh) scale(0.0357);
                transform: translate(10.60865vw, 100vh) scale(0.0357);
    }
}

@keyframes fall-188 {
    56.758% {
        -webkit-transform: translate(10.9632vw, 56.758vh) scale(0.0357);
                transform: translate(10.9632vw, 56.758vh) scale(0.0357);
    }

    to {
        -webkit-transform: translate(10.60865vw, 100vh) scale(0.0357);
                transform: translate(10.60865vw, 100vh) scale(0.0357);
    }
}

.snow:nth-child(189) {
    opacity: 0.1886;
    -webkit-transform: translate(94.259vw, -10px) scale(0.7371);
            transform: translate(94.259vw, -10px) scale(0.7371);
    -webkit-animation: fall-189 24s -5s linear infinite;
            animation: fall-189 24s -5s linear infinite;
}

@-webkit-keyframes fall-189 {
    38.92% {
        -webkit-transform: translate(99.515vw, 38.92vh) scale(0.7371);
                transform: translate(99.515vw, 38.92vh) scale(0.7371);
    }

    to {
        -webkit-transform: translate(96.887vw, 100vh) scale(0.7371);
                transform: translate(96.887vw, 100vh) scale(0.7371);
    }
}

@keyframes fall-189 {
    38.92% {
        -webkit-transform: translate(99.515vw, 38.92vh) scale(0.7371);
                transform: translate(99.515vw, 38.92vh) scale(0.7371);
    }

    to {
        -webkit-transform: translate(96.887vw, 100vh) scale(0.7371);
                transform: translate(96.887vw, 100vh) scale(0.7371);
    }
}

.snow:nth-child(190) {
    opacity: 0.6618;
    -webkit-transform: translate(36.1128vw, -10px) scale(0.3274);
            transform: translate(36.1128vw, -10px) scale(0.3274);
    -webkit-animation: fall-190 15s -15s linear infinite;
            animation: fall-190 15s -15s linear infinite;
}

@-webkit-keyframes fall-190 {
    68.256% {
        -webkit-transform: translate(34.1053vw, 68.256vh) scale(0.3274);
                transform: translate(34.1053vw, 68.256vh) scale(0.3274);
    }

    to {
        -webkit-transform: translate(35.10905vw, 100vh) scale(0.3274);
                transform: translate(35.10905vw, 100vh) scale(0.3274);
    }
}

@keyframes fall-190 {
    68.256% {
        -webkit-transform: translate(34.1053vw, 68.256vh) scale(0.3274);
                transform: translate(34.1053vw, 68.256vh) scale(0.3274);
    }

    to {
        -webkit-transform: translate(35.10905vw, 100vh) scale(0.3274);
                transform: translate(35.10905vw, 100vh) scale(0.3274);
    }
}

.snow:nth-child(191) {
    opacity: 0.7093;
    -webkit-transform: translate(41.0594vw, -10px) scale(0.0204);
            transform: translate(41.0594vw, -10px) scale(0.0204);
    -webkit-animation: fall-191 27s -13s linear infinite;
            animation: fall-191 27s -13s linear infinite;
}

@-webkit-keyframes fall-191 {
    46.523% {
        -webkit-transform: translate(44.212vw, 46.523vh) scale(0.0204);
                transform: translate(44.212vw, 46.523vh) scale(0.0204);
    }

    to {
        -webkit-transform: translate(42.6357vw, 100vh) scale(0.0204);
                transform: translate(42.6357vw, 100vh) scale(0.0204);
    }
}

@keyframes fall-191 {
    46.523% {
        -webkit-transform: translate(44.212vw, 46.523vh) scale(0.0204);
                transform: translate(44.212vw, 46.523vh) scale(0.0204);
    }

    to {
        -webkit-transform: translate(42.6357vw, 100vh) scale(0.0204);
                transform: translate(42.6357vw, 100vh) scale(0.0204);
    }
}

.snow:nth-child(192) {
    opacity: 0.814;
    -webkit-transform: translate(80.1987vw, -10px) scale(0.3127);
            transform: translate(80.1987vw, -10px) scale(0.3127);
    -webkit-animation: fall-192 24s -18s linear infinite;
            animation: fall-192 24s -18s linear infinite;
}

@-webkit-keyframes fall-192 {
    58.128% {
        -webkit-transform: translate(81.5993vw, 58.128vh) scale(0.3127);
                transform: translate(81.5993vw, 58.128vh) scale(0.3127);
    }

    to {
        -webkit-transform: translate(80.899vw, 100vh) scale(0.3127);
                transform: translate(80.899vw, 100vh) scale(0.3127);
    }
}

@keyframes fall-192 {
    58.128% {
        -webkit-transform: translate(81.5993vw, 58.128vh) scale(0.3127);
                transform: translate(81.5993vw, 58.128vh) scale(0.3127);
    }

    to {
        -webkit-transform: translate(80.899vw, 100vh) scale(0.3127);
                transform: translate(80.899vw, 100vh) scale(0.3127);
    }
}

.snow:nth-child(193) {
    opacity: 0.3537;
    -webkit-transform: translate(0.5089vw, -10px) scale(0.5332);
            transform: translate(0.5089vw, -10px) scale(0.5332);
    -webkit-animation: fall-193 24s -10s linear infinite;
            animation: fall-193 24s -10s linear infinite;
}

@-webkit-keyframes fall-193 {
    48.697% {
        -webkit-transform: translate(-1.0692vw, 48.697vh) scale(0.5332);
                transform: translate(-1.0692vw, 48.697vh) scale(0.5332);
    }

    to {
        -webkit-transform: translate(-0.28015vw, 100vh) scale(0.5332);
                transform: translate(-0.28015vw, 100vh) scale(0.5332);
    }
}

@keyframes fall-193 {
    48.697% {
        -webkit-transform: translate(-1.0692vw, 48.697vh) scale(0.5332);
                transform: translate(-1.0692vw, 48.697vh) scale(0.5332);
    }

    to {
        -webkit-transform: translate(-0.28015vw, 100vh) scale(0.5332);
                transform: translate(-0.28015vw, 100vh) scale(0.5332);
    }
}

.snow:nth-child(194) {
    opacity: 0.6072;
    -webkit-transform: translate(68.4408vw, -10px) scale(0.7422);
            transform: translate(68.4408vw, -10px) scale(0.7422);
    -webkit-animation: fall-194 20s -23s linear infinite;
            animation: fall-194 20s -23s linear infinite;
}

@-webkit-keyframes fall-194 {
    37.373% {
        -webkit-transform: translate(70.8903vw, 37.373vh) scale(0.7422);
                transform: translate(70.8903vw, 37.373vh) scale(0.7422);
    }

    to {
        -webkit-transform: translate(69.66555vw, 100vh) scale(0.7422);
                transform: translate(69.66555vw, 100vh) scale(0.7422);
    }
}

@keyframes fall-194 {
    37.373% {
        -webkit-transform: translate(70.8903vw, 37.373vh) scale(0.7422);
                transform: translate(70.8903vw, 37.373vh) scale(0.7422);
    }

    to {
        -webkit-transform: translate(69.66555vw, 100vh) scale(0.7422);
                transform: translate(69.66555vw, 100vh) scale(0.7422);
    }
}

.snow:nth-child(195) {
    opacity: 0.6152;
    -webkit-transform: translate(30.3096vw, -10px) scale(0.9012);
            transform: translate(30.3096vw, -10px) scale(0.9012);
    -webkit-animation: fall-195 16s -19s linear infinite;
            animation: fall-195 16s -19s linear infinite;
}

@-webkit-keyframes fall-195 {
    40.307% {
        -webkit-transform: translate(26.3775vw, 40.307vh) scale(0.9012);
                transform: translate(26.3775vw, 40.307vh) scale(0.9012);
    }

    to {
        -webkit-transform: translate(28.34355vw, 100vh) scale(0.9012);
                transform: translate(28.34355vw, 100vh) scale(0.9012);
    }
}

@keyframes fall-195 {
    40.307% {
        -webkit-transform: translate(26.3775vw, 40.307vh) scale(0.9012);
                transform: translate(26.3775vw, 40.307vh) scale(0.9012);
    }

    to {
        -webkit-transform: translate(28.34355vw, 100vh) scale(0.9012);
                transform: translate(28.34355vw, 100vh) scale(0.9012);
    }
}

.snow:nth-child(196) {
    opacity: 0.7251;
    -webkit-transform: translate(82.2924vw, -10px) scale(0.4157);
            transform: translate(82.2924vw, -10px) scale(0.4157);
    -webkit-animation: fall-196 21s -29s linear infinite;
            animation: fall-196 21s -29s linear infinite;
}

@-webkit-keyframes fall-196 {
    67.956% {
        -webkit-transform: translate(90.5317vw, 67.956vh) scale(0.4157);
                transform: translate(90.5317vw, 67.956vh) scale(0.4157);
    }

    to {
        -webkit-transform: translate(86.41205vw, 100vh) scale(0.4157);
                transform: translate(86.41205vw, 100vh) scale(0.4157);
    }
}

@keyframes fall-196 {
    67.956% {
        -webkit-transform: translate(90.5317vw, 67.956vh) scale(0.4157);
                transform: translate(90.5317vw, 67.956vh) scale(0.4157);
    }

    to {
        -webkit-transform: translate(86.41205vw, 100vh) scale(0.4157);
                transform: translate(86.41205vw, 100vh) scale(0.4157);
    }
}

.snow:nth-child(197) {
    opacity: 0.8691;
    -webkit-transform: translate(86.4182vw, -10px) scale(0.9237);
            transform: translate(86.4182vw, -10px) scale(0.9237);
    -webkit-animation: fall-197 24s -9s linear infinite;
            animation: fall-197 24s -9s linear infinite;
}

@-webkit-keyframes fall-197 {
    39.553% {
        -webkit-transform: translate(76.4746vw, 39.553vh) scale(0.9237);
                transform: translate(76.4746vw, 39.553vh) scale(0.9237);
    }

    to {
        -webkit-transform: translate(81.4464vw, 100vh) scale(0.9237);
                transform: translate(81.4464vw, 100vh) scale(0.9237);
    }
}

@keyframes fall-197 {
    39.553% {
        -webkit-transform: translate(76.4746vw, 39.553vh) scale(0.9237);
                transform: translate(76.4746vw, 39.553vh) scale(0.9237);
    }

    to {
        -webkit-transform: translate(81.4464vw, 100vh) scale(0.9237);
                transform: translate(81.4464vw, 100vh) scale(0.9237);
    }
}

.snow:nth-child(198) {
    opacity: 0.017;
    -webkit-transform: translate(67.8143vw, -10px) scale(0.0102);
            transform: translate(67.8143vw, -10px) scale(0.0102);
    -webkit-animation: fall-198 16s -4s linear infinite;
            animation: fall-198 16s -4s linear infinite;
}

@-webkit-keyframes fall-198 {
    73.165% {
        -webkit-transform: translate(63.8113vw, 73.165vh) scale(0.0102);
                transform: translate(63.8113vw, 73.165vh) scale(0.0102);
    }

    to {
        -webkit-transform: translate(65.8128vw, 100vh) scale(0.0102);
                transform: translate(65.8128vw, 100vh) scale(0.0102);
    }
}

@keyframes fall-198 {
    73.165% {
        -webkit-transform: translate(63.8113vw, 73.165vh) scale(0.0102);
                transform: translate(63.8113vw, 73.165vh) scale(0.0102);
    }

    to {
        -webkit-transform: translate(65.8128vw, 100vh) scale(0.0102);
                transform: translate(65.8128vw, 100vh) scale(0.0102);
    }
}

.snow:nth-child(199) {
    opacity: 0.5823;
    -webkit-transform: translate(85.5654vw, -10px) scale(0.836);
            transform: translate(85.5654vw, -10px) scale(0.836);
    -webkit-animation: fall-199 19s -20s linear infinite;
            animation: fall-199 19s -20s linear infinite;
}

@-webkit-keyframes fall-199 {
    42.625% {
        -webkit-transform: translate(78.6773vw, 42.625vh) scale(0.836);
                transform: translate(78.6773vw, 42.625vh) scale(0.836);
    }

    to {
        -webkit-transform: translate(82.12135vw, 100vh) scale(0.836);
                transform: translate(82.12135vw, 100vh) scale(0.836);
    }
}

@keyframes fall-199 {
    42.625% {
        -webkit-transform: translate(78.6773vw, 42.625vh) scale(0.836);
                transform: translate(78.6773vw, 42.625vh) scale(0.836);
    }

    to {
        -webkit-transform: translate(82.12135vw, 100vh) scale(0.836);
                transform: translate(82.12135vw, 100vh) scale(0.836);
    }
}

.snow:nth-child(200) {
    opacity: 0.3485;
    -webkit-transform: translate(24.6593vw, -10px) scale(0.0599);
            transform: translate(24.6593vw, -10px) scale(0.0599);
    -webkit-animation: fall-200 23s -25s linear infinite;
            animation: fall-200 23s -25s linear infinite;
}

@-webkit-keyframes fall-200 {
    51.564% {
        -webkit-transform: translate(16.2651vw, 51.564vh) scale(0.0599);
                transform: translate(16.2651vw, 51.564vh) scale(0.0599);
    }

    to {
        -webkit-transform: translate(20.4622vw, 100vh) scale(0.0599);
                transform: translate(20.4622vw, 100vh) scale(0.0599);
    }
}

@keyframes fall-200 {
    51.564% {
        -webkit-transform: translate(16.2651vw, 51.564vh) scale(0.0599);
                transform: translate(16.2651vw, 51.564vh) scale(0.0599);
    }

    to {
        -webkit-transform: translate(20.4622vw, 100vh) scale(0.0599);
                transform: translate(20.4622vw, 100vh) scale(0.0599);
    }
}
.c-showcase{
   margin: 40px 0;
}
.c-showcase-title{
   padding-top: 50px;
   margin-bottom: 24px;
   display: -webkit-flex;
   display: flex;
   -webkit-justify-content: space-between;
           justify-content: space-between;
}
.h1s{
   color: black;
   font-size: 55px;
   font-style: 'Montserrat'; 
   padding-left: 20px;
   width:100%;
}

.project{
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;
   -webkit-justify-content:right;
           justify-content:right;
   text-align: right;
   width: 300px;
}

.subtitle{
   text-align: right;
   color:#101BF5;
}

.h1s, .subtitle{
    display: inline-block;
    width: 100%;
}
@media screen and (max-width: 575px) {
   .c-showcase{
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin: 20px 0;
   }
   
   .c-showcase-title{
      -webkit-flex-direction: column;
              flex-direction: column;
      padding: 0;
      margin: 0;

   }
   .h1s{
      font-size: 40px;
      margin-top: 10px;
      margin-bottom: 5px;
      padding-left: 0;
      padding-left: initial;
   }
   .subtitle{
      font-size: 20px;
      text-align: left;
      width: auto;
      width: initial;
   }
   .project{
      -webkit-justify-content: start;
              justify-content: start;
      width: auto;
   }

}

/* Montserrat */
@font-face {
    font-family:'Montserrat';
    src: url(/static/media/Montserrat-Regular.3cd78665.ttf);
}
@font-face {
    font-family:'Montserrat-Italic';
    src: url(/static/media/Montserrat-Italic.e7728c27.ttf);
}
@font-face {
    font-family:'Montserrat-Bold';
    src: url(/static/media/Montserrat-Bold.079ca05d.ttf);
}
@font-face {
    font-family:'Montserrat-SemiBold';
    src: url(/static/media/Montserrat-SemiBold.fa8441f3.ttf);
}
@font-face {
    font-family:'Montserrat-ExtraBold';
    src: url(/static/media/Montserrat-ExtraBold.1944c8d1.ttf);
}
@font-face {
    font-family:'Montserrat-SemiBoldItalic';
    src: url(/static/media/Montserrat-SemiBoldItalic.b0400063.ttf);
}

/* Blinker */
@font-face {
    font-family:'Blinker';
    src: url(/static/media/Blinker-Regular.546d036c.ttf);
}
@font-face {
    font-family:'Blinker-SemiBold';
    src: url(/static/media/Blinker-SemiBold.8b82fab5.ttf);
}
@font-face {
    font-family:'Blinker-Bold';
    src: url(/static/media/Blinker-Bold.fb76e54b.ttf);
}
@font-face {
    font-family:'Blinker-Black';
    src: url(/static/media/Blinker-Black.8ce6e283.ttf);
}
@font-face {
    font-family:'Blinker-ExtraLight';
    src: url(/static/media/Blinker-ExtraLight.d2b5e791.ttf);
    font-style: normal;
}

*{
    font-family: 'Montserrat';
}

.Montserrat,.Montserrat *{ font-family: 'Montserrat' !important; }
.Montserrat-Italic,.Montserrat-Italic *{ font-family: 'Montserrat-Italic' !important; }
.Montserrat-SemiBold,.Montserrat-SemiBold *{ font-family: 'Montserrat-SemiBold' !important; }
.Montserrat-Bold,.Montserrat-Bold *{ font-family: 'Montserrat-Bold' !important; }
.Montserrat-SemiBoldItalic,.Montserrat-SemiBoldItalic *{ font-family: 'Montserrat-SemiBoldItalic' !important; }
.Montserrat-ExtraBold,.Montserrat-ExtraBold * { font-family: 'Montserrat-ExtraBold' !important;}
.Blinker,.Blinker *{ font-family: 'Blinker' !important; }
.Blinker-SemiBold,.Blinker-SemiBold *{ font-family: 'Blinker-SemiBold' !important; }
.Blinker-Bold,.Blinker-Bold *{ font-family: 'Blinker-Bold' !important; }
.Blinker-Black,.Blinker-Black *{ font-family: 'Blinker-Black' !important; }
.Blinker-ExtraLight,.Blinker-ExtraLight *{ font-family: 'Blinker-ExtraLight' !important; }

.custom-container{
    margin: 0 20px;
    box-sizing: border-box;
}
@media only screen and (min-width: 576px) {
    .custom-container{
        margin: 0 70px;
    }
}
@media only screen and (min-width: 768px) {
    .custom-container{
        margin: 0 70px;
    }
}
@media only screen and (min-width: 992px) {
    .custom-container{
        margin: 0 70px;
    }
}
@media only screen and (min-width: 1200px) {
    .custom-container{
        margin: 0 70px;
    }
}
@media only screen and (min-width: 1400px) {
    .custom-container{
        margin: 0 70px;
    }
}

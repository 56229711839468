.c-nav{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.c-contact-button{
    border-radius: 10px !important;
    font-size: 14px !important;
}
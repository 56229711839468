/* Montserrat */
@font-face {
    font-family:'Montserrat';
    src: url('/src/font/Montserrat/Montserrat-Regular.ttf');
}
@font-face {
    font-family:'Montserrat-Italic';
    src: url('/src/font/Montserrat/Montserrat-Italic.ttf');
}
@font-face {
    font-family:'Montserrat-Bold';
    src: url('/src/font/Montserrat/Montserrat-Bold.ttf');
}
@font-face {
    font-family:'Montserrat-SemiBold';
    src: url('/src/font/Montserrat/Montserrat-SemiBold.ttf');
}
@font-face {
    font-family:'Montserrat-ExtraBold';
    src: url('/src/font/Montserrat/Montserrat-ExtraBold.ttf');
}
@font-face {
    font-family:'Montserrat-SemiBoldItalic';
    src: url('/src/font/Montserrat/Montserrat-SemiBoldItalic.ttf');
}

/* Blinker */
@font-face {
    font-family:'Blinker';
    src: url('/src/font/Blinker/Blinker-Regular.ttf');
}
@font-face {
    font-family:'Blinker-SemiBold';
    src: url('/src/font/Blinker/Blinker-SemiBold.ttf');
}
@font-face {
    font-family:'Blinker-Bold';
    src: url('/src/font/Blinker/Blinker-Bold.ttf');
}
@font-face {
    font-family:'Blinker-Black';
    src: url('/src/font/Blinker/Blinker-Black.ttf');
}
@font-face {
    font-family:'Blinker-ExtraLight';
    src: url('/src/font/Blinker/Blinker-ExtraLight.ttf');
    font-style: normal;
}

*{
    font-family: 'Montserrat';
}

.Montserrat,.Montserrat *{ font-family: 'Montserrat' !important; }
.Montserrat-Italic,.Montserrat-Italic *{ font-family: 'Montserrat-Italic' !important; }
.Montserrat-SemiBold,.Montserrat-SemiBold *{ font-family: 'Montserrat-SemiBold' !important; }
.Montserrat-Bold,.Montserrat-Bold *{ font-family: 'Montserrat-Bold' !important; }
.Montserrat-SemiBoldItalic,.Montserrat-SemiBoldItalic *{ font-family: 'Montserrat-SemiBoldItalic' !important; }
.Montserrat-ExtraBold,.Montserrat-ExtraBold * { font-family: 'Montserrat-ExtraBold' !important;}
.Blinker,.Blinker *{ font-family: 'Blinker' !important; }
.Blinker-SemiBold,.Blinker-SemiBold *{ font-family: 'Blinker-SemiBold' !important; }
.Blinker-Bold,.Blinker-Bold *{ font-family: 'Blinker-Bold' !important; }
.Blinker-Black,.Blinker-Black *{ font-family: 'Blinker-Black' !important; }
.Blinker-ExtraLight,.Blinker-ExtraLight *{ font-family: 'Blinker-ExtraLight' !important; }

.custom-container{
    margin: 0 20px;
    box-sizing: border-box;
}
@media only screen and (min-width: 576px) {
    .custom-container{
        margin: 0 70px;
    }
}
@media only screen and (min-width: 768px) {
    .custom-container{
        margin: 0 70px;
    }
}
@media only screen and (min-width: 992px) {
    .custom-container{
        margin: 0 70px;
    }
}
@media only screen and (min-width: 1200px) {
    .custom-container{
        margin: 0 70px;
    }
}
@media only screen and (min-width: 1400px) {
    .custom-container{
        margin: 0 70px;
    }
}
.startblue{
    color: #101BF5;
}
.pheading{
    color: black;
    font-size: 48px;
    margin-bottom: 24px;
    text-align:left;
    align-items:left;
    font-style: 'Montserrat'; 
    width:100%;
    font-weight: bold;
}
.pheading2{
    color: #939393;
    font-size: 20px;
    text-align:left;
    align-items:left;
    font-style: 'Montserrat'; 
    font-family: 'Montserrat-Italic';
    width:100%;
}
.itext{
    padding-top: 10px;
    margin-top: 20px !important;
    margin-bottom: 16px;
    font-size: 36px !important;
    font-style: 'Montserrat';
    text-align: left;
}
.project{
    display: flex;
    align-items: center;
    justify-content:right;
    text-align: right;
    width: 300px;
 }
.sub{
    text-align: right;
    color:#101BF5;
 }
.itext, .sub {
     display: inline-block;
     width: 100%;
     
 }

 @media screen and (max-width: 575px) {
    .sub{
        font-size: 20px;
        text-align: left;
        width: initial;
     }
     .project{
        justify-content: start;
        width: auto;
     }
}

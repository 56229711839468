.c-home{
  background-image: url('../../images/Mockup.png');
  height: 100vh;
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: center bottom;
  margin-bottom: 30px !important;
}

.textwrapper *{
  /* position: absolute; */
  /* top: 50%; */
  /* left: 18%; */
  /* transform: translate(-20%, -50%); */
  font-size: 40px;
  line-height: 40px;
  max-width: 100%;
}

@media only screen and (min-width: 576px) {
  .c-home{
    height: 100vh;
    background-size: 70%;
    background-position: calc(100% + 100px);
    margin-bottom:50px !important;
  }
  .textwrapper *{
    font-size: 70px;
    line-height: 70px;
    max-width: 62vw;
  }
}

.title-top{
  color: #101BF5;
}
.title-bottom{
  color: black;
}
@media only screen and (min-width:768px){
  .textwrapper *{
    font-size: 80px;
    line-height: 80px;
  }
  .c-home{
    background-size: 61%;
  }
}

@media only screen and (min-width:992px){
  .textwrapper *{
    font-size: 90px;
    line-height: 90px;
  }
  .c-home{
    background-size: 62%;
  }
}
@media only screen and (min-width: 1200px) {
  .textwrapper *{
    font-size: 110px;
    line-height: 110px;
  }
  .c-home{
    background-size: 55%;
  }
}

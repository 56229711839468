
.c-footer{
    background: #101BF5;
}
.footer-top, .footer-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
}
.footer-top{
    padding-top:50px;
    padding-bottom:25px;
}
.footer-bottom{
    padding-top: 25px;
    padding-bottom: 30px;
}
.footer-bottom *{
    font-size: 14px !important;
}

.c-social p{
    font-size: 14px !important;
}
.c-contact{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 30vw;
}
.c-contact h2{
    font-size: 16px !important;
    margin-bottom: 5px !important;
}
.c-contact a{
    font-size: 14px !important;
    margin-bottom: 0 !important;
}
.c-policy{
    display: flex;
    flex-direction: row;    
    width: 100%;
    max-width: 30vw;
}

.policy{
    margin-left: 50px;
}

@media screen and (max-width: 575px) {
    .footer-top{
        flex-direction: column;
    }
    .footer-bottom{
        flex-direction: column-reverse;
    }
    .c-social{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .c-contact{
        margin: 50px 0 0;
        max-width: initial;
    }
    .c-policy{
        flex-direction: column;    
        width: 100%;
        max-width: initial;
        margin-bottom: 30px;
    }    
    .policy{
        margin-left: initial;
    }
}

@media screen and (max-width: 767px) {
    
}

@media screen and (max-width: 991px) {
    
}

@media screen and (max-width: 1999px) {
    
}


/* Explanation in JS tab */

/* Cool font from Google Fonts! */
@import url('https://fonts.googleapis.com/css?family=Raleway:900&display=swap');

body {
    margin: 0px;

}

#container {
    /* Center the text in the viewport. */
    position: absolute;
    margin: auto;
    width: 100%;
    height: 80pt;
    ;
    top: 0;
    bottom: 0;

    /* This filter is a lot of the magic, try commenting it out to see how the morphing works! */
    filter: url(#threshold) blur(0.6px);
}

/* Your average text styling */
#text1,
#text2 {
    position: absolute;
    width: 100%;
    display: inline-block;

    font-family: 'Raleway', sans-serif;
    font-size: 80pt;
    text-align: center;
    color: white;
    user-select: none;
}

@media screen and (max-width: 1024px) {

    #text1,
    #text2 {
        font-size: 40pt;
    }
}

.loader_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    z-index: 9999;
}

.loader_overlay ._bar_container {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}

.loader_overlay ._bar_container ._bar {
    height: 100%;
    flex-grow: 1;
    background-color: transparent;
}

._bar_container.end ._bar:nth-of-type(5) {
    background-color: white;
    transition: all ease-in 0.4s;
}

._bar_container.end ._bar:nth-of-type(4) {
    background-color: white;
    transition: all ease-in 0.4s 0.2s;
}

._bar_container.end ._bar:nth-of-type(6) {
    background-color: white;
    transition: all ease-in 0.4s 0.2s;
}

._bar_container.end ._bar:nth-of-type(3) {
    background-color: white;
    transition: all ease-in 0.4s 0.4s;
}

._bar_container.end ._bar:nth-of-type(7) {
    background-color: white;
    transition: all ease-in 0.4s 0.4s;
}

._bar_container.end ._bar:nth-of-type(2) {
    background-color: white;
    transition: all ease-in 0.4s 0.6s;
}

._bar_container.end ._bar:nth-of-type(8) {
    background-color: white;
    transition: all ease-in 0.4s 0.6s;
}

._bar_container.end ._bar:nth-of-type(1) {
    background-color: white;
    transition: all ease-in 0.4s 0.8s;
}

._bar_container.end ._bar:nth-of-type(9) {
    background-color: white;
    transition: all ease-in 0.4s 0.8s;
}

.home_content {
    opacity: 0;
    transition: all ease-in 0.4s;
    height: 100vh;
    overflow: hidden;
}

.home_content.active {
    opacity: 1;
    height: auto;
    overflow: scroll;
}
.container{
    /* width: 100vw; */
    text-align: center;
    padding-left: 30px;
    padding-right:30px;

}
.d-flex{
    padding: 40px 0 20px ;
    /* width: 100vw; */
}
.c-row{
    align-items: center;
}
.Special-title{
    width:100%;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-left: 20px;
}

.c-team{
    display: flex;
    /* max-width: 400px; */
    justify-content: center;
    align-items: center;
}
.c-team-detail{
    text-align: start;
    margin: 20px;
}
.team-number{
    color: #101BF5;
    font-size: 50px;
    font-weight: bold;
    margin: 0;
}
.team-title{
    font-size: 20px;
    color: #939393;
    font-style: italic;
    font-weight: 600;
}

.list-body{
    width: 100%;
    margin-top: 50px;
}
.list-title{
    font-size: 45px;	
    font-weight: 650;
}
p{
    margin-bottom: 0 !important;
}
.list{
    color: #939393;
    font-size: 16px;
    font-weight: 400;
}

.list-text{
    width:100%;
    max-width:400px;
    font-size: 16px;
    text-align: start;
    line-height: 26px;
}
@media screen and (max-width: 575px) {
    .c-row{
        align-items: center;
        /* flex-direction: column; */
    }
    .Special-title{
        margin: 20px 0;
    }
    .team-number{
        font-size: 40px;
    }
    .team-title{
        font-size: 14px;
    }
}
@media screen and (max-width:767px){
    .team-number{
        text-align: center;
    }
    .list-text{
        text-align: center;
        margin:auto;
    }
}
@media screen and (max-width: 1199px){
    .Special-title{
        margin: 20px 0;
    }
}